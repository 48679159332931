import { RefreshTool, RemoveTool, Toolbar, useConfirm, useSpinner } from 'components';
import { Breadcrumb, BreadcrumbList } from '@premcloud/ui';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { LicenseViewer } from './LicenseViewer';
import { LicenseValue } from './Models';
import { LicenseService } from './Services';

export const LicenseDetails = () => {
  const { licenseId } = useParams();
  const [license, setLicense] = useState<LicenseValue>();
  const showSpinner = useSpinner();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const load = async () => {
    try {
      showSpinner(true);
      setLicense(await LicenseService.get(licenseId));
    } catch (err) {
      // TODO
    } finally {
      showSpinner(false);
    }
  };

  const remove = async () => {
    const result = await confirm(
      "Remove License",
      "Are you sure you want to remove this license?");

    if (result === 'yes') {
      try {
        await LicenseService.remove(licenseId);
        navigate('../licenses');
      } catch (err) {
        // TODO:
      }
    }
  }

  const handleToolClick = (value: string) => {
    switch (value) {
      case 'refresh': return load();
      case 'remove': return remove();
    }
  }

  useEffect(() => {
    load();
  }, [licenseId]);

  return (
    <div className="page license">

      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <NavLink to="/license/overview">License</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <NavLink to="/license/licenses">Licenses</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <span>{licenseId}</span>
        </Breadcrumb>
      </BreadcrumbList>

      <h1>License {licenseId}</h1>

      {license &&
        <>
          <Toolbar tools={[RefreshTool(), RemoveTool()]} onToolClick={handleToolClick} />
          <LicenseViewer license={license} />
        </>
      }
    </div>
  );
}
