import { Chip, Row, Tab, TabGroup, Table } from "@premcloud/ui";
import { useEffect, useMemo, useState } from "react";
import { CheckCircle as CheckIcon, XCircle as CrossIcon, Info as InfoIcon } from "react-feather";
import { ChannelService } from "../Services/ChannelService";
import "./Modal.css";
import "./Runs.css";
import { useNavigate } from "react-router-dom";
import { Confirm, ConfirmResult, Pager, Timer, Toolbar } from "components";
import { dayjs, formatDate, formatDuration, titleCase } from "utils";
import { Link } from "data";
import { RunsPieChart } from "features/pipelines/RunsPieChart";
import { ConnectorCombination } from "features/pipelines/ConnectorIcon";
import {
  PipelineRun,
  PipelineRunLog,
  PipelineRunLogPage,
  PipelineRunPage,
} from "features/pipelines/Models";
import { PipelineRunStateIcon } from "features/pipelines/PipelineRunStateIcon";
import { historyRunInputConnectors, useConfirmRun } from "features/pipelines/ConfirmRunContext";
import { PipelineService } from "features/pipelines/Service";

const buildRunRow = (run: PipelineRun, onClick: (log: PipelineRun) => void): Row => {
  return {
    name: run.name,
    state: {
      value: run.state,
      display: <PipelineRunStateIcon state={run.state} />,
    },
    startTime:
      run.startTime &&
      (() => {
        const time = dayjs.utc(run.startTime);
        return {
          value: time?.valueOf() || 0,
          display: time?.local().format("L LT") || " ",
        } as any;
      })(),
    stopTime: (() => {
      const time = run.stopTime && dayjs.utc(run.stopTime);
      return {
        value: time?.valueOf() || 0,
        display: time?.local().format("L LT") || " ",
      } as any;
    })(),
    runTime: (() => {
      const time = run.runTime && dayjs.duration(run.runTime);
      return {
        value: time?.asMilliseconds() || 0,
        display: (() => {
          switch (run.state) {
            case "running":
              return <Timer start={dayjs.utc(run.startTime)} />;
            default:
              return formatDuration(time);
          }
        })(),
      } as any;
    })(),
    outcome: {
      value: run.outcome || "",
      display: (() => {
        switch (run.outcome) {
          case undefined:
            return <></>;
          case "succeeded":
            return <CheckIcon color="var(--success)" />;
          case "failed":
            return <CrossIcon color="var(--danger)" />;
          default:
            return (
              <Chip color="var(--info)" outline>
                {titleCase(run.outcome)}
              </Chip>
            );
        }
      })(),
    },
    actions: {
      value: "",
      display: (
        <a href="#" onClick={() => onClick(run)} title="Display Logs">
          <InfoIcon />
        </a>
      ),
    },
  };
};

const buildRunLogRow = (log: PipelineRunLog): Row => {
  return {
    level: log.level && {
      value: log.level,
      display: (() => {
        switch (log.level) {
          case "Information":
            return <Chip color="var(--info)">Information</Chip>;
          case "Warning":
            return <Chip color="var(--warning)">Warning</Chip>;
          case "Error":
            return <Chip color="var(--danger)">Error</Chip>;
          default:
            return <Chip>{log.level}</Chip>;
        }
      })(),
    },
    message: log.message,
  };
};

export const PendingModal = (props) => {
  const [item, setItem] = useState<any>();
  const [runHistory, setRunHistory] = useState<PipelineRunPage>();
  const [selectedRun, setSelectedRun] = useState<PipelineRun>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [confObject, setConfObject] = useState<any>();
  const [logData, setLogData] = useState<PipelineRunLogPage>();
  const navigate = useNavigate();
  const [runSortField, setRunSortField] = useState("startTime");
  const [runSortOrder, setRunSortOrder] = useState("asc");
  const [logSortField, setLogSortField] = useState("");
  const [logSortOrder, setLogSortOrder] = useState("asc");
  const runRows = useMemo<Row[]>(
    () => runHistory?.value.map((run) => buildRunRow(run, displayLogs)),
    [runHistory]
  );
  const logRows = useMemo<Row[]>(() => logData?.value.map(buildRunLogRow), [logData]);
  const confirmRun = useConfirmRun();

  useEffect(() => {
    removeConfigProp(); // Temp addition until the functionality is added
    setItem(props.props);
    if (props.props.runshref) {
      getRuns(props.props.runshref);
    }
  }, []);

  function removeConfigProp() {
    props.props.options.forEach((item, index) => {
      if (item.name === "Configuration") props.props.options.splice(index, 1);
    });
  }

  async function getRuns(href) {
    var runs = await ChannelService.getDetails(href);
    setRunHistory(runs);
  }

  async function onMenuClick(tool) {
    if (tool === "fullscreen") {
      onFullScreenClick();
    } else if (tool === "run" && historyRunInputConnectors.includes(props.props.input.id)) {
      const { result, runId } = await confirmRun(item.runs);
      if (result === "yes") {
        ChannelService.post(item.run.href + `/${runId}`, "");
      }
    } else if (tool === "setup") {
      const overview = await PipelineService.editPipeline(item.setup);
      return navigate(`/pipelines/${item.id}/setup/${overview.value.id}`, { state: `/home` });
    } else {
      setConfObject(tool);
      setShowConfirmationModal(true);
    }
  }

  async function closeModal(confirmed: ConfirmResult) {
    setShowConfirmationModal(false);
    if (confirmed === "yes") {
      await callhref();
      props.setPresentPendingDialog(false);
      props.setShowModal(false);
    }
  }

  function callhref() {
    const tool = confObject;
    const link: Link = (() => {
      switch (tool) {
        case "remove":
          return item.delete;
        case "enable":
          return item.enable;
        case "disable":
          return item.disable;
        case "run":
          return item.run;
      }
    })();

    if (link) {
      switch (link.method || "GET") {
        case "GET":
          return ChannelService.get(link.href);
        case "POST":
          return ChannelService.post(link.href, "");
        case "DELETE":
          return ChannelService.delete(link.href);
      }
    }
  }

  async function displayLogs(run: PipelineRun) {
    setSelectedRun(run);
    loadLogs(run.logs.href);
    setShowLogs(true);
  }

  async function loadLogs(href: string) {
    var response = await ChannelService.get(href);
    setLogData(response);
  }

  function onFullScreenClick() {
    navigate(`/pipelines/${item.id}`);
  }

  return (
    <>
      <Toolbar tools={props.props.options} onToolClick={onMenuClick} />
      <TabGroup>
        <Tab label="Details">
          <div className="modalContainer">
            <p></p>
            <div className="col1">
              <label>Name</label>
            </div>
            <div className="col2">
              <label>{props.props.name}</label>
            </div>
            <p></p>
            <div className="col1">
              <ConnectorCombination
                input={props.props.input}
                output={props.props.output}
                size="large"
              />
            </div>
            <div className="col1">Run Schedule</div>
            <div className="col2">{props.props.runSchedule}</div>
          </div>
          <p></p>
          {props.props.metadata.map((details, i) => {
            return (
              <div className="modalContainer" key={i}>
                <div className="col1">{details.label}</div>
                <div className="col2">
                  <input readOnly type="text" value={details.value} />
                </div>
              </div>
            );
          })}

          <RunsPieChart runs={props.props.runs} />
        </Tab>
        <Tab label="Runs">
          {runHistory && (
            <Pager
              page={runHistory}
              onSelect={async (nav) =>
                setRunHistory(await ChannelService.getDetails(nav.link.href))
              }
              style={{ flex: "1 1" }}
            >
              <Table
                sortField={runSortField}
                sortOrder={runSortOrder}
                setSortField={setRunSortField}
                setSortOrder={setRunSortOrder}
                columns={[
                  { id: "name" },
                  { id: "state" },
                  { id: "startTime", display: "Start Time" },
                  { id: "stopTime", display: "Stop Time" },
                  { id: "runTime", display: "Run Time" },
                  { id: "outcome" },
                  { id: "logs", sort: false },
                ]}
                rows={runRows}
              />
            </Pager>
          )}
        </Tab>
        {showLogs && logData && (
          <Tab label={`${selectedRun.name} Logs`}>
            <Pager
              page={logData}
              onSelect={async (nav) => setLogData(await ChannelService.getDetails(nav.link.href))}
              style={{ flex: "1 1" }}
            >
              <Table
                sortField={logSortField}
                sortOrder={logSortOrder}
                setSortField={setLogSortField}
                setSortOrder={setLogSortOrder}
                columns={[
                  { id: "level", size: "small" },
                  { id: "message", size: "large" },
                ]}
                rows={logRows}
              />
            </Pager>
          </Tab>
        )}
      </TabGroup>

      <Confirm
        title={titleCase(confObject) + " Pipeline"}
        body={<>Are you sure you want to {confObject} the selected pipeline?</>}
        visible={showConfirmationModal}
        onClose={closeModal}
      />
    </>
  );
};
