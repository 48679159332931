import { Tab, TabGroup } from "@premcloud/ui"
import { useEffect, useState } from "react";
import { GetIcon } from "../svgLogos/GetIcon";
import { Chart as ChartJS, ArcElement} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import './Modal.css';
ChartJS.register(ArcElement);

export const CompleteModal = (props) => {

    const [item, setItem] = useState(props);

    useEffect(() => {
        setItem(props);
    }, []);

    return(
        <div id='pending-modal' className="modal">
            Complete
            <TabGroup>
                <Tab label="Details">
                    ID # {item.props.id}<br/>
                    <GetIcon icon={item.props.inputIcon} /> <GetIcon icon="Pipeline" /> <GetIcon icon={item.props.outputIcon} /> <br/>
                    Title {item.props.projectName} <br/>
                </Tab>
                <Tab label="User Details">
                </Tab>
                <Tab label="Statistics">
                    {item.props.options ?
                        <div className='Chart'>
                            {/* <Pie data={item.options} width={100} height={100} /> */}
                        </div>
                    : ''}

                </Tab>
                <Tab label="Error Report">
                </Tab>
            </TabGroup>
        </div>
    )
}
