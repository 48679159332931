type GeneralProblemProps = {
  errors: string[];
};

export const GeneralProblem = (props: GeneralProblemProps) => {
  return (
    <div className="problem-general">
      {props.errors.map(error => (<p>{error}</p>))}
    </div>
  );
}
