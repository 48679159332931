import { Breadcrumb, BreadcrumbList } from '@premcloud/ui';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Setup } from './setup';

export const PipelineNew = () => {
  const { setupId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="page pipeline-setup">
      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <NavLink to="/pipelines">Pipelines</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <span>Setup</span>
        </Breadcrumb>
      </BreadcrumbList>

      <h1>Pipeline Setup</h1>

      <Setup setupId={setupId} onExit={() => navigate(state ?? '../')} />
    </div>
  );
}
