import {
  AddTool,
  RefreshTool,
  Table,
  Toolbar,
  ExpandableTableRow,
  useConfirm,
  useSpinner,
} from 'components';
import { Breadcrumb, BreadcrumbList, Row } from '@premcloud/ui';
import { useEffect, useMemo, useState } from 'react';
import { CheckCircle as CheckIcon, Slash as SlashIcon } from 'react-feather';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { titleCase } from 'utils';
import { ConnectorCombination } from './ConnectorIcon';
import { PipelineCollection, PipelineCollectionItem, PipelineValue } from './Models';
import { PipelineProperties } from './PipelineProperties';
import { PipelineService } from './Service';
import { describeCronExpression, getPipelineTools } from './Utils';
import { historyRunInputConnectors, useConfirmRun } from './ConfirmRunContext';

export const PipelineList = () => {
  const [pipelines, setPipelines] = useState<PipelineCollection>();
  const tools = useMemo(() => [RefreshTool(), AddTool()], []);
  const rows = useMemo(() => pipelines?.value.map(buildRow) || [], [pipelines]);
  const showSpinner = useSpinner();
  const confirm = useConfirm();
  const confirmRun = useConfirmRun();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const load = async () => {
    try {
      showSpinner(true);
      setPipelines(await PipelineService.list());
    } finally {
      showSpinner(false);
    }
  };

  const handleToolClick = async (tool: string) => {
    switch (tool) {
      case 'refresh':
        return await load();
      case 'add':
        navigate('/pipelines/add');
        break;
      default:
        throw new Error(`${tool} not handled`);
    }
  };

  const handlePipelineToolClick = async (tool: string, pipeline: PipelineValue) => {
    if (tool === 'run' && historyRunInputConnectors.includes(pipeline.value.input.id)) {
      const { result, runId } = await confirmRun(pipeline.value);
      if (result === 'yes') {
        await PipelineService.createRun(pipeline.value, runId);
      }
    } else {
      const result = tool === 'setup'
        ? 'yes'
        : await confirm(titleCase(tool) + ' Pipeline', <>Are you sure you want to {tool} this pipeline?</>);
      if (result === 'yes') {
        switch (tool) {
          case 'setup':
            try {
              showSpinner(true);
              const overview = await PipelineService.editPipeline(pipeline.value);
              return navigate(`./${pipeline.value.id}/setup/${overview.value.id}`, { state: pathname })
            } finally {
              showSpinner(false);
            }
          case 'remove':
            await PipelineService.removePipeline(pipeline.value);
            return await load();

          case 'enable':
            await PipelineService.enablePipeline(pipeline.value);
            return await load();

          case 'disable':
            await PipelineService.disablePipeline(pipeline.value);
            return await load();

          case 'run':
            return await PipelineService.createRun(pipeline.value);

          default:
            throw new Error(`${tool} not handled`);
        }
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="page pipelines pipeline-list">
      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <span>Pipelines</span>
        </Breadcrumb>
      </BreadcrumbList>

      <h1>Pipelines</h1>

      <Toolbar tools={tools} onToolClick={handleToolClick} />

      <Table
        columns={[
          { id: "name", sort: false },
          { id: "connectors", sort: false },
          { id: "schedule", size: "large", sort: false },
          { id: "enabled", size: "small", sort: false },
          { id: "createdBy", display: "Created By", sort: false },
        ]}
        rows={rows}
        rowTemplate={props => <ExpandableTableRow
          {...props}
          element={async row => {
            const pipeline = await PipelineService.getPipeline(row._id as string)
            return (
              <div className="details">
                <PipelineProperties pipeline={pipeline} />
                <Toolbar tools={getPipelineTools(pipeline, false)} onToolClick={tool => handlePipelineToolClick(tool, pipeline)} />
              </div>
            );
          }} />
        }
      />
    </div>
  );
};

const buildRow = (pipeline: PipelineCollectionItem): Row => {
  return {
    _id: pipeline.id,
    name: {
      value: pipeline.name,
      display: <NavLink to={`./${pipeline.id}`}>{pipeline.name}</NavLink>,
    },
    connectors: {
      value: pipeline.id,
      display: (
        <ConnectorCombination input={pipeline.input} output={pipeline.output} size="medium" />
      ),
    },
    schedule: describeCronExpression(pipeline.schedule.expression),
    enabled: {
      value: pipeline.enabled,
      display: pipeline.enabled ? (
        <CheckIcon color="var(--success)" size={20} />
      ) : (
        <SlashIcon color="var(--danger)" size={20} />
      ),
    } as any,
    createdBy: pipeline.metadata.filter((m) => m.name === 'CreatedBy')[0].value,
  };
};
