import { http, Link, ValueObject }  from 'data';
import configData from '../appSettings.json'

const API_Url = configData.API_SERVER_URL;

export const ChannelSetUpService = {
  getConnectors: async () => {
    const url = API_Url + '/api/connectors';
    const { data } = await http.get<ValueObject<Connector[]>>(url);
    return data;
  },
  createSetup: async (body: any) => {
    const url = API_Url + '/api/channels/setup';
    const response = await (await http.post(url, body)).data;
    return response;
  },
  getSetup: async (id: string) => {
    const url = API_Url + '/api/channels/setup/' + id;
    const response = await (await http.get(url)).data;
    return response;
  },
  cancelSetup: async (urlrequest: string) => {
    const url = API_Url + urlrequest;
    return (await (await http.delete(url)).status);
  },
  getNextStepPostAsync: async (urlrequest: string, body: string) => {
    const url = API_Url + urlrequest;
    return (await (await http.post<any>(url, body)).data);
  },
  getNextStepGetAsync: async (urlrequest: string) => {
    const url = API_Url + urlrequest;
    return (await (await http.get<any>(url)).data);
  },
  followIonLinkGet: async (urlrequest: string) => {
    const url = API_Url + urlrequest;
    return await (await http.get(url)).data;
  },
  followIonLinkPost: async (urlrequest: string, body: string) => {
    const url = API_Url + urlrequest;
    return await (await http.post(url, body)).data;
  },
  finish: async (payloadUrl: string) => {
    const url = API_Url + payloadUrl;
    const response = (await http.post(url, [])).data;
    return response;
  },
  pipeline: async (payload: any) => {
    const url = API_Url + '/channels/setup';
    const response = await (await http.post(url, payload)).data;
    return response;
  },
  getForm: async (payload: string) => {
    const url = API_Url + payload;
    const response = await (await http.get<any>(url)).data;
    return response;
  },
  submitData: async (payload: any, payloadUrl: string) => {
    const url = API_Url + payloadUrl;
    const response = (await http.post(url, payload)).data;
    return response;
  },

  getTeamsCreds: () => {
    const response = [
      {
        name: 'Teams 1', date: '01/01/2022', fields: [
          { field: 'ApplicationId', value: '01e8cbe8-0035-4611-a7ea-1a2cb8826efe' },
          { field: 'ApplicationSecret', value: 'AU-8Q~b7GSi7LmDj1gV_vNcZ7NBkcTMCP-LlmcE~' },
          { field: 'TenantId', value: '84dcdc33-7ac5-4226-bb87-d20f5f1960a4' },
        ]
      },
      {
        name: 'Teams 2', date: '10/02/2022', fields: [
          { field: 'ApplicationId', value: 'Application Id 2' },
          { field: 'ApplicationSecret', value: 'secret2' },
          { field: 'TenantId', value: 'Tenant2' },
        ]
      },
      {
        name: 'Teams 3', date: '06/10/2022', fields: [
          { field: 'ApplicationId', value: 'Application Id 3' },
          { field: 'ApplicationSecret', value: 'secret3' },
          { field: 'TenantId', value: 'Tenant3' },
        ]
      },
    ]
    return response;
  }
};

export type Connector = {
  id: string;
  displayName: string;
  icon: Link;
  type: 'input' | 'output';
  licensed: boolean;
  groups: ConnectorGroup[];
};

export type ConnectorGroup = {
  id: string;
  displayName: string;
};
