import './Layout.css';
import { Outlet } from 'react-router-dom';
import { Navbar } from './Navbar';
import { TopNavBar } from './TopNavBar';
import { useEffect, useState } from 'react';
import configData from './appSettings.json'

export const Layout = () => {

  const [appName, setAppName] = useState('');

  // useEffect(() => {
  //   try{
  //       if(configData.CONNECTLICENSE === "1") {
  //         setAppName('Connect');
  //     }
  //     if (configData.DETECTLICENSE === "1") {
  //         if (appName === '') setAppName('Detect');
  //     }
  //     if (configData.ADVANCEDLICENSE === "1") {
  //         if (appName === '') setAppName('Advanced');
  //     }
  //   }
  //   catch{
  //     setAppName('Connect');
  //   }
  // }, []);

  return (
    <div id="layout">
      {/* <div className='topNav'><TopNavBar setAppName={setAppName}/></div> */}
      <Navbar appName={"Connect"}/>
      <Outlet />
    </div>
  );
};
