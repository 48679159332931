import { ReactElement } from "react";

export type DescriptionDetailsProps = {
  term?: string;
  value: string | ReactElement;
  wrap?: boolean;
  title?: string;
};

export const DescriptionDetails = (props: DescriptionDetailsProps) => {
  const { term, value, wrap = false, title } = props;
  return (
    <>
      {term && <dt>{term}</dt>}
      <dd className={wrap ? 'wrap' : ''} title={title}>{value}</dd>
    </>
  );
};
