import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { isProblem } from './Models';

const addInterceptors = (target: AxiosInstance) => {
  target.interceptors.response.use(
    response => response,
    error => {
      if (axios.isAxiosError(error)) {
        const { status, data } = error.response!;
        if (status >= 500 || error.code === 'ERR_NETWORK') {
          const message = (data && (data['detail'] || data['title'])) || error.message || 'An unknown server error has occurred';
          toast.error(message, { autoClose: false });
        }
        if (isProblem(data)) {
          return Promise.reject(data);
        }
      }
      return Promise.reject(error);
    }
  );
  return target;
};

export const formDataHeaders = { headers: { 'Content-Type': 'multipart/form-data' } };
export const data = <T>(response: AxiosResponse<T>) => response.data;
export const nothing = () => { };
export const http = addInterceptors(axios.create());
export const isAxiosError = axios.isAxiosError;
