//import { AccountTree, Archive, ArrowBackIos, ArrowForwardIos, CalendarToday, Cancel, Cloud, DeleteForever, Done, DoubleArrow, Edit, ExitToApp, ExpandLess, FileCopy, FlipCameraAndroid, FolderShared, FolderSpecial, Gavel, Input, Language, Lens, LockSharp, Mail, Memory, MoreHorizSharp, PanTool, Person, QueryBuilder, QuestionAnswer, Replay, Report, Slideshow, Stop, Storage, ToggleOff, ToggleOn, WatchLater } from "@material-ui/icons";
import { AccountTree, Archive, ArrowBackIos, ArrowForwardIos, Bolt, Bookmark, CalendarToday, Cancel, Check, Cloud, Dashboard, DeleteForever, DirectionsRun, Done, DoubleArrow, Edit, ElectricBolt, Email, Error, Logout, ExpandLess, ExpandMore, FileCopy, Fingerprint, FlipCameraAndroid, FolderShared, FolderSpecial, Gavel, GppGood, Input, Language, Lens, LocalOffer, LockSharp, Mail, Memory, MemorySharp, MoreHorizSharp, PanTool, Person, QueryBuilder, QuestionAnswer, Replay, Report, SettingsInputComponent, Slideshow, SlowMotionVideo, Stop, Storage, ToggleOff, ToggleOn, WatchLater } from "@mui/icons-material";
import { ChevronRight, Home, Key, Monitor, ThumbsDown, ThumbsUp, Upload } from "react-feather";
import { AmazonS3Icon } from "./amazons3Icon"
import { AzureIcon } from "./azureIcon";
import { AzureStorageIcon } from "./azureStorageIcon";
import { BloombergIcon } from "./bloombergIcon";
import { BoxIcon } from "./boxIcon";
import { DatabaseIcon } from "./databaseIcon";
import { DatalakeIcon } from "./datalakeIcon";
import { DellIcon } from "./dellIcon";
import { DigitalsafeIcon } from "./digitalsafeIcon";
import { DropboxIcon } from "./dropboxIcon";
import Duplicates from "./duplicated";
import { EASIcon } from "./easIcon";
import { EVIcon } from "./evIcon";
import { ExchangeIcon } from "./exchangeIcon";
import { ExchangeOnlineIcon } from "./exchangeonlineIcon";
import { FacebookIcon } from "./facebookIcon";
import { GoogleIcon } from "./googleIcon";
import { IBMIcon } from "./ibmIcon";
import { IManageIcon } from "./imanageIcon";
import { LinkedinIcon } from "./linkedinIcon";
import { LuminosoIcon as Luminoso } from "./Luminoso";
import { MariaDBIcon } from "./mariadbIcon";
import { MicrosoftIcon } from "./micosoftIcon";
import { MimecastIcon } from "./mimecastIcon";
import { NearpointIcon } from "./nearpointIcon";
import { OneDriveIcon } from "./onedriveIcon";
import Rolodex from "./Rolodex";
import { SalesforceIcon } from "./salesforceIcon";
import { SharepointIcon } from "./sharepointIcon";
import { SimpanaIcon } from "./simpanaIcon";
import { SkypeIcon } from "./skypeIcon";
import { SkypeOutlineIcon } from "./skypeoutlineIcon";
import { SlackIcon } from "./slackIcon";
import { SMTPMailIcon } from "./smtpmailIcon";
import { SqlDatabaseIcon } from "./sqldatabaseIcon";
import { TeamsIcon } from "./teamsIcon";
import { TwitterIcon } from "./twitterIcon";
import { WhatsAppIcon } from "./whatsappIcon";
import { YammerIcon } from "./yammerIcon";
import { ZoomIcon } from "./zoomIcon";
import { ZovyIcon } from "./zovyIcon";


export const GetIcon = (props: any) => {
    var icon = props.icon;
    //Detect
    if (icon === 'Dashboard') {return(<Dashboard />)}
    if (icon === 'Duplicates') {return(<Duplicates />)}
    if (icon === 'Scans') {return(<ElectricBolt />)}
    if (icon === 'Analyses') {return(<MemorySharp />)};
    if (icon === 'Actions') {return(<SlowMotionVideo />)}
    if (icon === 'Collections') {return(<Bookmark />)}
    if (icon === 'Tags') {return(<LocalOffer />)}
    if (icon === 'Connectors') {return(<SettingsInputComponent />)}
    if (icon === 'Rolodex') {return(<Rolodex />)}
    if (icon === 'Admin') {return(<GppGood />)}


    // Material UI
    if (icon === 'expand') {return(<ChevronRight />)}
    if (icon === 'fullscreen') {return(<Monitor />)}
    if (icon === 'hide') {return(<ExpandLess />)}
    if (icon === "Input") {return(<Input />)}
    if (icon === "Output") {return(<Logout />)}
    if (icon === "SocialMedia") {return(<QuestionAnswer />)}
    if (icon === "Cloud") {return(<Cloud />)}
    if (icon === "Storage") {return(<Storage />)}
    if (
        icon === "Servers" ||
        icon === "inputs.filesystem" ||
        icon === "file-server" ||
        icon === "outputs.filesystem" ||
        icon === "outputs.native" ||
        icon === 'filesystem' ||
        icon === "connect.filesystem" ||
        icon === "connect.inputs.filesystem" ||
        icon === "connect.outputs.filesystem"
        ) {return(<Storage />)}
    if (icon === "FTP" || icon === "ftp-server-input" || icon === "ftp-server-output") {return(<FolderSpecial />)}
    if (icon === "Archive") {return(<Archive />)}
    if (icon === "Files") {return(<FolderShared />)}
    if (icon === "Custom") {return(<Gavel />)}
    if (icon === "WebContent") {return(<Language />)}
    if (icon === "Generic") {return(<AccountTree />)}
    if (
        icon === "SMTP" ||
        icon === "smtp" ||
        icon === "connect.smtp" ||
        icon === "connect.outputs.smtp"
        ) { return (<Mail />) }
    if (icon === "PremCloud") {return(<Cloud />)}
    if (icon === "Pipeline") {return(<DoubleArrow />)}
    if (icon === "Pen") {return(<Edit />)}
    if (icon === "MoreHorz") {return(<MoreHorizSharp />)}
    if (icon === "Password") {return(<LockSharp />)}
    if (icon === "Delete") {return(<DeleteForever />)}
    if (icon === "Copy") {return(<FileCopy />)}
    if (icon === "Review") {return(<Slideshow />)}
    if (icon === "License") {return(<Key />)}
    if (icon === "UploadFile") {return(<Upload />)}
    if (icon === "Circle") {return(<Lens />)}
    if (icon === "Home") {return(<Home />)}
    if (icon === "RunningTime") {return(<WatchLater />)}
    if (icon === "StartTime") {return(<QueryBuilder />)}
    if (icon === "User") {return(<Person />)}
    if (icon === "Calendar") {return(<CalendarToday />)}
    if (icon === "Configuration") {return(<Memory />)}
    if (icon === "Runs") {return(<FlipCameraAndroid />)}
    if (icon === "ToggleOn") { return (<ToggleOn />)}
    if (icon === "ToggleOff") { return (<ToggleOff />)}
    if (icon === "Run") {return(<Replay />)}
    if (icon === "Confirm") {return(<Done />)}
    if (icon === "Cancel") {return(<Cancel />)}
    if (icon === "stopped") {return(<PanTool />)}
    if (icon === "Report") {return(<Report />)}
    if (icon === "Previous") {return(<ArrowBackIos />)}
    if (icon === "Next") {return(<ArrowForwardIos />)}
    if (icon === "Email") {return(<Email />)}
    if (icon === "Confirmed") {return(<ThumbsUp />)}
    if (icon === "NotConfirmed") {return(<ThumbsDown />)}
    if (icon === "Complete") {return(<Check />)}
    if (icon === "Running") {return(<DirectionsRun />)}
    if (icon === "Failed") {return(<Error />)}
    if (icon === "Create") {return(<Bolt />)}

    // SVG
    if (icon === "AmazonS3" || icon === "amazon-s3") {return (<AmazonS3Icon />)}
    if (icon === "Azure") {return (<AzureIcon />)}
    if (icon === "AzureStorage" || icon === "azure-blob-storage" || icon==="connect.azureblobstorage"  ||  icon === "connect.outputs.azureblobstorage") {return (<AzureStorageIcon />)}
    if (icon === "Bloomberg") {return (<BloombergIcon />)}
    if (icon === "Box") {return(<BoxIcon />)}
    if (icon === "Database") {return(<DatabaseIcon />)}
    if (icon === "Datalake" || icon === "azure-data-lake-gen-2") {return(<DatalakeIcon />)}
    if (icon === "Dell") {return(<DellIcon />)}
    if (icon === "DigitalSafe") {return(<DigitalsafeIcon />)}
    if (icon === "Dropbox") {return(<DropboxIcon />)}
    if (icon === "EAS" || icon === "eas") {return(<EASIcon />)}
    if (
        icon === "EV" ||
        icon === "inputs.enterprise-vault" ||
        icon === "connect.enterprisevault" ||
        icon === "connect.inputs.enterprisevault"
        ) { return (<EVIcon />) }
    if (icon === "Exchange") {return(<ExchangeIcon />)}
    if (icon === "ExchangeOnline" || icon === "outputs.exchange-online") {return(<ExchangeOnlineIcon />)}
    if (icon === "Facebook") {return(<FacebookIcon />)}
    if (icon === "Google") {return(<GoogleIcon />)}
    if (icon === "IBM") {return(<IBMIcon />)}
    if (icon === "IManage") {return(<IManageIcon />)}
    if (icon === "Linkedin") {return(<LinkedinIcon />)}
    if (icon === "MariaDB") {return(<MariaDBIcon />)}
    if (icon === "Microsoft") {return(<MicrosoftIcon />)}
    if (icon === "Mimecast") {return(<MimecastIcon />)}
    if (icon === "Nearpoint") {return(<NearpointIcon />)}
    if (icon === "OneDrive") {return(<OneDriveIcon />)}
    if (icon === "SalesForce") {return(<SalesforceIcon />)}
    if (
        icon === "SharePoint" ||
        icon === "outputs.sharepoint" ||
        icon === "connect.sharepoint" ||
        icon === "connect.outputs.sharepoint"
        ) { return (<SharepointIcon />) }
    if (icon === "Simpana") {return(<SimpanaIcon />)}
    if (icon === "Skype" || icon === "skype-for-business" || icon === "connect.skypeforbusiness" || icon === "connect.inputs.skypeforbusiness") {return(<SkypeIcon />)}
    if (icon === "SkypeOnlone") {return(<SkypeOutlineIcon />)}
    if (icon === "Slack" || icon === "slack") {return(<SlackIcon />)}
    if (icon === "SQL") {return(<SqlDatabaseIcon />)}
    if (
        icon === "Teams" ||
        icon === 'teams' ||
        icon === "inputs.teams-chat" ||
        icon === "teams-classification" ||
        icon === "teams-expiration" ||
        icon === "outputs.teams" ||
        icon === "connect.teams" ||
        icon === "connect.teams.chat" ||
        icon === "connect.teams.classification" ||
        icon === "connect.teams.expiration" ||
        icon === "connect.inputs.teams" ||
        icon === "connect.inputs.teams.chat" ||
        icon === "connect.inputs.teams.classification" ||
        icon === "connect.inputs.teams.expiration" ||
        icon === "connect.outputs.teams"
        ) {return(<TeamsIcon />)}

    if (
        icon === "inputs.luminoso" ||
        icon === "outputs.luminoso" ||
        icon === "connect.luminoso" ||
        icon === "connect.inputs.luminoso" ||
        icon === "connect.outputs.luminoso"
    ) { return (<Luminoso />) }
    if (icon === "Twitter") {return(<TwitterIcon />)}
    if (icon === "WhatsApp") {return(<WhatsAppIcon />)}
    if (icon === "Yammer") {return(<YammerIcon />)}
    if (
        icon === "Zoom" ||
        icon === "zoom" ||
        icon === "connect.zoom" ||
        icon === "connect.inputs.zoom"
        ) { return (<ZoomIcon />) }
    if (icon === "Zovy") {return(<ZovyIcon />)}

    return null;
}
