import React, { createContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { NewChannel } from './Channel/NewChannel';
import { Dashboard } from './Dashboard/Dashboard';
import { Layout } from './Layout';
import * as AuthFeature from 'features/auth';
import * as LicenseFeature from 'features/license';
import * as PipelinesFeature from 'features/pipelines';
import * as UsersFeature from 'features/users';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmProvider } from 'components';

export type Theme = 'light' | 'dark';

export type AppContextValue = {
  theme: string;
  setTheme: (value: Theme) => void;
}

export const AppContext = createContext<AppContextValue>(null);

export const App = () => {
  const [theme, setTheme] = useState<Theme>(localStorage.getItem('theme') as Theme || 'dark');

  document.body.dataset.theme = theme;

  return (
    <div id="app">
      <AppContext.Provider value={{ theme, setTheme }}>
        <AuthFeature.AuthProvider>
          <ConfirmProvider>
            <Routes>
              {AuthFeature.routes}
              <Route path="/" element={<Layout />}>
                <Route path="" element={<Navigate to="/home" />} />
                <Route path="home" element={<Dashboard />} />
                <Route path="pipelines/add" element={<NewChannel />} />
                {LicenseFeature.routes}
                {PipelinesFeature.routes}
                {UsersFeature.routes}
              </Route>
            </Routes>
          </ConfirmProvider>
        </AuthFeature.AuthProvider>
      </AppContext.Provider>
      <ToastContainer position="top-center" hideProgressBar={true} theme={theme} />
    </div>
  );
}
