import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from '@premcloud/ui';
import { FormField, Input, Option, Select, required, validate } from '@premcloud/ui';
import { feedbackProblem, Problem } from 'data';
import { mustMatch } from './Validators'
import { createField } from 'utils';

type CreateUserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  passwordConfirm: string;
  roles: string[];
}

type UpdateUserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
}

type ResetUserFormData = {
  password: string;
  passwordConfirm: string;
}

export type UserFormData = CreateUserFormData | UpdateUserFormData | ResetUserFormData;

type UserFormProps = {
  type: 'create' | 'update' | 'reset';
  availableRoles: string[],
  data?: UserFormData;
  problem: Problem;
  onChange: (data: UserFormData) => void;
}

export const UserForm = (props: UserFormProps) => {
  const { type, availableRoles, data, problem, onChange } = props;
  const [roleOptions, setRoleOptions] = useState<Option[]>([]);
  const [firstName, setFirstName] = useState(createField('firstName', ''));
  const [lastName, setLastName] = useState(createField('lastName', ''));
  const [email, setEmail] = useState(createField('email', ''));
  const [username, setUsername] = useState(createField('username', ''));
  const [password, setPassword] = useState(createField('password', ''));
  const [passwordConfirm, setPasswordConfirm] = useState(createField('passwordConfirm', ''));
  const [roles, setRoles] = useState(createField<string[]>('roles', []));

  useEffect(() => {
    if (availableRoles) {
      setRoleOptions(availableRoles.map(role => ({ value: role, default: (data as any)?.roles?.includes(role) })));
    }
    setFirstName(current => ({
      ...current,
      value: (data as any)?.firstName || '',
      valid: !!(data as any)?.firstName,
    }));
    setLastName(current => ({
      ...current,
      value: (data as any)?.lastName || '',
      valid: !!(data as any)?.lastName,
    }));
    setEmail(current => ({
      ...current,
      value: (data as any)?.email || '',
      valid: !!(data as any)?.email,
    }));
    setRoles(current => ({
      ...current,
      value: (data as any)?.roles,
      valid: !!(data as any)?.roles?.length
    }));

    if (!availableRoles) {
      setRoleOptions(current => current.map(option => ({ ...option, default: (data as any)?.roles?.includes(option.value) })));
    }
  }, [data, availableRoles]);

  // Update passwordConfirm validator when password field changes.
  useEffect(() => {
    setPasswordConfirm({
      ...passwordConfirm,
      validators: [required(), mustMatch(password.value)]
    });
  }, [password]);

  // Trigger onChange when form fields change.
  useEffect(() => {
    const data = ((): UserFormData => {
      switch (type) {
        case 'update':
          // TODO: Only send change fields.
          return firstName.valid && lastName.valid && email.valid && roles.valid && (firstName.dirty || lastName.dirty || email.dirty || roles.dirty) && {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            roles: roles.value
          };
        case 'reset':
          return password.valid && passwordConfirm.valid && (password.dirty || passwordConfirm.dirty) && {
            password: password.value,
            passwordConfirm: passwordConfirm.value
          };
        default:
          const valid = firstName.valid && lastName.valid && email.valid && username.valid && password.valid && passwordConfirm.valid && roles.valid;
          const dirty = firstName.dirty || lastName.dirty || email.dirty || username.dirty || password.dirty || passwordConfirm.dirty || roles.dirty;
          return valid && dirty && {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            username: username.value,
            password: password.value,
            passwordConfirm: passwordConfirm.value,
            roles: roles.value
          };
      }
    })();
    onChange(data);
  }, [firstName, lastName, email, username, password, passwordConfirm, roles]);

  // Feedback problems from API calls.
  useEffect(() => {
    if (problem?.errors) {
      feedbackProblem({
        firstname: setFirstName,
        lastname: setLastName,
        email: setEmail,
        username: setUsername,
        password: setPassword,
        passwordconfirm: setPasswordConfirm,
        roles: setRoles
      }, problem);
    }
  }, [problem]);

  return (
    <Form>
      {(type === 'create' || type === 'update') &&
        <>
          <FormField
            label="First Name"
            errors={firstName.errors}
            dirty={firstName.dirty}
            input={
              <Input
                value={firstName.value}
                placeholder="First Name"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  validate({
                    ...firstName,
                    value: event.target.value,
                  }, setFirstName)
                }
                onBlur={() => validate(firstName, setFirstName)}
                hasError={firstName.errors.length > 0}
              />
            }
          />
          <FormField
            label="Last Name"
            errors={lastName.errors}
            dirty={lastName.dirty}
            input={
              <Input
                value={lastName.value}
                placeholder="Last Name"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  validate({
                    ...lastName,
                    value: event.target.value,
                  }, setLastName)
                }
                onBlur={() => validate(lastName, setLastName)}
                hasError={lastName.errors.length > 0}
              />
            }
          />
        </>
      }
      {(type === 'create' || type === 'update') &&
        <FormField
          label="Email"
          errors={email.errors}
          dirty={email.dirty}
          hint="Must be a valid format. E.g. jdoe@premcloud.com"
          input={
            <Input
              value={email.value}
              placeholder="Email"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                validate({
                  ...email,
                  value: event.target.value,
                }, setEmail)
              }
              onBlur={() => validate(email, setEmail)}
              hasError={email.errors.length > 0}
            />
          }
        />
      }
      {type === 'create' &&
        <FormField
          label="Username"
          errors={username.errors}
          dirty={username.dirty}
          hint="Must be at least 3 characters. E.g. jdoe"
          input={
            <Input
              placeholder="Username"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                validate({
                  ...username,
                  value: event.target.value,
                }, setUsername)
              }
              onBlur={() => validate(username, setUsername)}
              hasError={username.errors.length > 0}
            />
          }
        />
      }
      {(type === 'create' || type === 'reset') &&
        <>
          <FormField
            label="Password"
            errors={password.errors}
            dirty={password.dirty}
            hint="Must be at least 10 characters and contains at least one uppercase and lowercase characters, one digit and one symbol."
            input={
              <Input
                type="password"
                placeholder="Password"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  validate({
                    ...password,
                    value: event.target.value
                  }, setPassword)
                }
                onBlur={() => {
                  validate(password, setPassword);
                  if (passwordConfirm.value) {
                    validate(passwordConfirm, setPasswordConfirm);
                  }
                }}
                hasError={password.errors.length > 0}
              />
            }
          />
          <FormField
            label="Confirm Password"
            errors={passwordConfirm.errors}
            dirty={passwordConfirm.dirty}
            hint="Retype password."
            customErrorMessages={{ mismatch: `Passwords don't match.` }}
            input={
              <Input
                type="password"
                placeholder="Confirm Password"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  validate({
                    ...passwordConfirm,
                    value: event.target.value
                  }, setPasswordConfirm)
                }
                onBlur={() => {
                  validate(passwordConfirm, setPasswordConfirm);
                  if (password.value) {
                    validate(password, setPassword);
                  }
                }}
                hasError={passwordConfirm.errors.length > 0}
              />
            }
          />
        </>
      }
      {(type === 'create' || type === 'update') &&
        <FormField
          label="Roles"
          errors={roles.errors}
          dirty={roles.dirty}
          hint="Select at least one role."
          input={
            <Select
              multi
              placeholder="Select roles"
              options={roleOptions}
              onChange={selection =>
                validate({
                  ...roles,
                  value: selection.map(s => s.value)
                }, setRoles)
              }
              onBlur={() => validate(roles, setRoles)}
              hasError={roles.errors.length > 0}
            />
          }
        />
      }
    </Form>
  )
}
