import { Card } from "@premcloud/ui";
import { CopyText, DescriptionDetails, DescriptionList } from "components";
import { SystemInfoValue } from "./Models";

export const SystemInfoViewer = (props: { systemInfo: SystemInfoValue }) => {
  const { systemInfo } = props;
  return systemInfo && (
    <Card header="System Information" content={
      <div className="pui-card-content-insert">
        <DescriptionList>
          <DescriptionDetails term="Product ID" value={<CopyText text={systemInfo.value.productId} />} />
          <DescriptionDetails term="Fingerprint" value={<CopyText text={systemInfo.value.fingerprint} />} />
          <DescriptionDetails term="Installation ID" value={<CopyText text={systemInfo.value.installationId} />} />
        </DescriptionList>
      </div>
    } />
  );
}
