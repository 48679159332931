import { FormField, Input, validate } from '@premcloud/ui';
import { Switch } from 'components';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { createField, dayjs } from 'utils';
import { FieldContext } from '../FieldContext';

// TODO: Remove ability to 'ignore' date range and use field.required

const DISABLED_START = '1970-01-01T00:00:00Z';
const DISABLED_END = '2150-12-31T00:00:00Z';

const toLocal = (value: string, disabledValue: string) => {
  if (value === disabledValue) {
    return '';
  }
  return value
    ? dayjs.utc(value).local().format('YYYY-MM-DD')
    : value;
};

const toUtc = (value: string, disabledValue: string) => {
  if (!value) {
    return disabledValue;
  }
  // .format() returns string with offset for UTC dates - force correct format.
  return dayjs(value).utc().toISOString();
};

export type DateTimeRangeValue = {
  Start: string;
  End: string;
};

export const DateTimeRange = () => {
  const { value, validators, onChange } = useContext(FieldContext);
  // const value = field.value as DateTimeRangeValue;
  const [start, setStart] = useState(createField(
    'start',
    toLocal(value?.Start || '', DISABLED_START),
    value?.Start === DISABLED_START ? [] : validators));
  const [end, setEnd] = useState(createField(
    'end',
    toLocal(value?.End || '', DISABLED_END),
    value?.End === DISABLED_END ? [] : validators));
  const [disabled, setDisabled] = useState(
    value?.Start === DISABLED_START && value?.End === DISABLED_END);

  const handleIgnoreChecked = (checked: boolean) => {
    const localValidators = checked ? [] : validators;
    setDisabled(checked);
    validate({
      ...start,
      value: checked ? '' : start.value,
      validators: localValidators
    }, setStart);
    validate({
      ...end,
      value: checked ? '' : end.value,
      validators: localValidators
    }, setEnd);
  };

  useEffect(() => {
    if (start.dirty || end.dirty) {
      const value: DateTimeRangeValue = start.valid && end.valid ? {
        Start: toUtc(start.value, DISABLED_START),
        End: toUtc(end.value, DISABLED_END)
      } : undefined;
      onChange(value);
    }
  }, [start, end]);

  return (
    <div className="pui-form compound-form-field">
      <FormField
        label="From Date"
        errors={start.errors}
        dirty={start.dirty}
        input={
          <Input
            type="date"
            placeholder="From Date"
            name="start"
            value={start.value}
            disabled={disabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              validate({
                ...start,
                value: event.target.value,
              }, setStart)
            }
            onBlur={() => validate(start, setStart)}
            hasError={start.errors.length > 0}
          />
        }
      />
      <FormField
        label="To Date"
        errors={end.errors}
        dirty={end.dirty}
        input={
          <Input
            type="date"
            placeholder="To Date"
            name="end"
            value={end.value}
            disabled={disabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              validate({
                ...end,
                value: event.target.value,
              }, setEnd)
            }
            onBlur={() => validate(end, setEnd)}
            hasError={end.errors.length > 0}
          />
        }
      />
      <div className="pui-form-field boolean">
        <div>Ignore Date Range
          <Switch checked={disabled} onChange={handleIgnoreChecked} />
        </div>
      </div>
    </div>
  );
};
