import { Checkbox } from '@premcloud/ui';
import { CSSProperties, createContext, useContext, useMemo, useState } from 'react';
import { SetupFieldOption } from '../../Models';
import { FieldContext } from '../FieldContext';

const flattenValues = (option: SetupFieldOption) => {
  return option.children
    ? [option.value, ...option.children.flatMap(flattenValues)]
    : [option.value];
};

type TreeItemProps = {
  item: SetupFieldOption;
  ancestors: SetupFieldOption[];
};

type TreeContextValue = {
  selectedItems: string[];
  setSelectedItems: (values: string[]) => void;
}
const TreeContext = createContext<TreeContextValue>(null);

export const TreeItem = (props: TreeItemProps) => {
  const { item, ancestors } = props;
  const [expanded, setExpanded] = useState(false);
  const { selectedItems, setSelectedItems } = useContext(TreeContext);

  const handleSelect = (checked: boolean) => {
    const set = new Set(selectedItems);
    const optionValues = [item].flatMap(flattenValues);

    if (checked) {
      for (const value of optionValues) {
        set.add(value);
      }
      for (const parent of props.ancestors) {
        if (parent?.children.flatMap(flattenValues).every(x => set.has(x))) {
          set.add(parent.value);
        }
      }
    } else {
      for (const parent of props.ancestors) {
        optionValues.push(parent.value);
      }

      for (const value of optionValues) {
        set.delete(value);
      }
    }

    setSelectedItems(Array.from(set));
  };

  const style = {
    '--left-padding': item.children
      ? `${ancestors.length + 1}rem`
      : `${ancestors.length + 2}rem`,
    '--tree-color': 'var(--fg)'
  } as CSSProperties;
  return (
    <div className="pui-tree-item" style={style}>
      <div
        className={selectedItems.includes(item.value) ? 'label selected' : 'label'}
        onClick={() => setExpanded(current => !current)}>
        {item.children && (
          expanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-down">
              <polyline points="6 9 12 15 18 9" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right">
              <polyline points="9 18 15 12 9 6" />
            </svg>
          )
        )}
        <Checkbox
          labelText={item.label}
          checked={selectedItems.includes(item.value) || false}
          onChange={handleSelect}
        />
      </div>

      <div className="children">
        {item.children && expanded
          ? item.children.map((child, i) => (
            <TreeItem
              key={i}
              item={child}
              ancestors={[item, ...ancestors]}
            />
          ))
          : null}
      </div>
    </div>
  );
};

export const Tree = () => {
  const { value, options, onChange } = useContext(FieldContext);
  const selectAllChecked = useMemo(() => value?.length === options.flatMap(flattenValues).length, [value, options]);

  const handleSelectAll = (checked: boolean) => {
    onChange(checked
      ? options.flatMap(flattenValues)
      : []);
  };

  return (
    <TreeContext.Provider
      value={{
        selectedItems: value ?? [],
        setSelectedItems: onChange
      }}>
      <div className="tree">
        <Checkbox
          labelText="Select All"
          checked={selectAllChecked}
          onChange={handleSelectAll}
        />
        <div className="pui-tree treeListContainter">
          {options.map((item, i) => (
            <TreeItem
              key={i}
              item={item}
              ancestors={[]}
            />
          ))}
        </div>
      </div>
    </TreeContext.Provider>
  );
};
