import { AzureStorageIcon } from 'app/svgLogos/azureStorageIcon';
import { EVIcon } from 'app/svgLogos/evIcon';
import { LuminosoIcon } from 'app/svgLogos/Luminoso';
import { SharepointIcon } from 'app/svgLogos/sharepointIcon';
import { SkypeIcon } from 'app/svgLogos/skypeIcon';
import { TeamsIcon } from 'app/svgLogos/teamsIcon';
import { ZoomIcon } from 'app/svgLogos/zoomIcon';
import { YammerIcon } from 'app/svgLogos/yammerIcon';
import { ArrowRight as ArrowRightIcon, Mail as MailIcon, Server as ServerIcon } from 'react-feather';
import './ConnectorIcon.css';

type ConnectorIconSize = 'small' | 'medium' | 'large';
const SizeToNumber = {
  'small': 16,
  'medium': 20,
  'large': 24
};

type Connector = {
  id: string;
  displayName?: string;
}

type ConnectorIconProps = {
  connector: string | Connector;
  showName?: boolean;
  size?: ConnectorIconSize;
};

export const ConnectorIcon = (props: ConnectorIconProps) => {
  const { connector, size = 'small', showName = false } = props;
  const connectorId = typeof (connector) === 'string' ? connector : connector['id'];
  const connectorDisplayName = showName ? connector['displayName'] : '';

  const Icon = ({ id, size }) => {
    const iconSize = SizeToNumber[size];
    switch (id) {
      case 'connect.inputs.enterprisevault': return <EVIcon viewBox="2 2 212 212" size={iconSize} />;
      case 'connect.inputs.filesystem':
      case 'connect.outputs.filesystem': return <ServerIcon size={iconSize} color="var(--accent)" />;
      case 'connect.inputs.skypeforbusiness': return <SkypeIcon viewBox="2 2 44 44" size={iconSize} />;
      case 'connect.inputs.teams.chat':
      case 'connect.inputs.teams.classification':
      case 'connect.inputs.teams.expiration':
      case 'connect.inputs.teams':
      case 'connect.outputs.teams': return <TeamsIcon viewBox="6 4 39 39" size={iconSize} />;
      case 'connect.inputs.zoom': return <ZoomIcon viewBox="2 2 44 44" size={iconSize} />;
      case 'connect.inputs.yammer': return <YammerIcon viewBox="2 2 44 44" size={iconSize} />;
      case 'connect.outputs.azureblobstorage': return <AzureStorageIcon viewBox="4 4 40 40" size={iconSize} />;
      case 'connect.inputs.luminoso':
      case 'connect.outputs.luminoso': return <LuminosoIcon viewBox="0 -1 19 19" size={iconSize} />;
      case 'connect.outputs.sharepoint': return <SharepointIcon viewBox="3 3 42 42" size={iconSize} />;
      case 'connect.outputs.smtp': return <MailIcon size={iconSize} color="var(--accent)" />;
      default: return <DefaultIcon size={iconSize} />;
    };
  };

  return (
    <span
      className={connectorDisplayName ? `connector-icon with-name ${size}` : `connector-icon ${size}`}
      title={showName ? '' : connector['displayName'] ?? connector}>
      <Icon id={connectorId} size={size} /> {connectorDisplayName}
    </span>
  );
};

const DefaultIcon = ({ size }) => <svg
  height={`${size}px`}
  viewBox="0 0 63 63"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_22_11)">
    <path d="M23.24 21.6H32.776C35.848 21.6 38.248 22.2613 39.976 23.584C41.704 24.9067 42.568 26.8587 42.568 29.44C42.568 32.0213 41.704 33.9307 39.976 35.168C38.2693 36.4053 35.8693 37.024 32.776 37.024H30.696V43.136C30.696 43.3707 30.6106 43.5733 30.44 43.744C30.2693 43.9147 30.0666 44 29.832 44H23.24C23.0053 44 22.8026 43.9147 22.632 43.744C22.4613 43.5733 22.376 43.3707 22.376 43.136V22.464C22.376 22.2293 22.4613 22.0267 22.632 21.856C22.8026 21.6853 23.0053 21.6 23.24 21.6ZM30.536 27.552V31.104H32.776C33.16 31.104 33.5013 30.9653 33.8 30.688C34.0986 30.3893 34.248 29.9733 34.248 29.44C34.248 28.9067 34.1306 28.4587 33.896 28.096C33.6613 27.7333 33.288 27.552 32.776 27.552H30.536Z" fill="#676B79" />
  </g>
  <path d="M7.48407 28.8296L7.54369 28.8296C9.541 28.8296 11.2104 27.4881 11.8066 25.6101C14.4598 17.114 22.3596 10.9432 31.7202 10.9432C33.2703 10.9432 34.731 11.1221 36.1619 11.45C38.0698 11.8673 40.0373 11.0923 41.0509 9.39305L41.0807 9.36324C42.5712 6.79952 41.2298 3.46073 38.3679 2.77508C36.2216 2.2683 33.9858 2 31.7202 2C18.3053 2 6.91766 10.8836 3.19133 23.106C2.32682 25.938 4.503 28.8296 7.48407 28.8296Z" fill="#FFB86C" />
  <path d="M34.7988 7.48407V7.54369C34.7988 9.54101 36.1403 11.2104 38.0183 11.8066C46.5144 14.4598 52.6852 22.3596 52.6852 31.7202C52.6852 33.2703 52.5063 34.731 52.1784 36.1619C51.761 38.0698 52.5361 40.0373 54.2353 41.0509L54.2651 41.0807C56.8289 42.5712 60.1677 41.2298 60.8533 38.3679C61.3601 36.2216 61.6284 33.9858 61.6284 31.7202C61.6284 18.3053 52.7448 6.91767 40.5224 3.19133C37.6904 2.32682 34.7988 4.503 34.7988 7.48407Z" fill="#FF75B5" />
  <path d="M56.0259 34.7988H55.9663C53.969 34.7988 52.2996 36.1403 51.7034 38.0183C49.0502 46.5144 41.1504 52.6852 31.7899 52.6852C30.2397 52.6852 28.779 52.5063 27.3481 52.1784C25.4402 51.761 23.4727 52.5361 22.4591 54.2353L22.4293 54.2651C20.9388 56.8289 22.2803 60.1677 25.1421 60.8533C27.2884 61.3601 29.5242 61.6284 31.7899 61.6284C45.2047 61.6284 56.5923 52.7448 60.3187 40.5224C61.1832 37.6904 59.007 34.7988 56.0259 34.7988Z" fill="#45A9F9" />
  <path d="M28.8296 56.026L28.8296 55.9663C28.8296 53.969 27.4882 52.2996 25.6101 51.7034C17.114 49.0503 10.9432 41.1504 10.9432 31.7899C10.9432 30.2397 11.1221 28.779 11.45 27.3481C11.8674 25.4402 11.0923 23.4727 9.39307 22.4591L9.36326 22.4293C6.79954 20.9388 3.46075 22.2803 2.7751 25.1421C2.26832 27.2885 2.00002 29.5243 2.00002 31.7899C2.00002 45.2047 10.8836 56.5924 23.106 60.3187C25.938 61.1832 28.8296 59.007 28.8296 56.026Z" fill="#19F9D8" />
  <defs>
    <filter id="filter0_d_22_11" x="18.376" y="21.6" width="28.192" height="30.4" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22_11" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22_11" result="shape" />
    </filter>
  </defs>
</svg>;

// type ConnectorNameProps = {
//   connector: Connector;
//   size?: number;
// };

// export const ConnectorName = (props: ConnectorNameProps) => {
//   const { connector, size = 16 } = props;
//   return (
//     <span className="connector-icon-with-name">
//       <ConnectorIcon id={connector.id} size={size} /> {connector.displayName}
//     </span>
//   );
// };

type ConnectorCombinationProps = {
  input: Connector;
  output?: Connector;
  size?: ConnectorIconSize;
  showName?: boolean;
};

export const ConnectorCombination = (props: ConnectorCombinationProps) => {
  const { input, output, size = 'small', showName = false } = props;
  return (
    <div className={`connector-combination ${size}`}>
      <ConnectorIcon connector={input} size={size} showName={showName} />
      {output &&
        <>
          <ArrowRightIcon size={SizeToNumber[size]} />
          <ConnectorIcon connector={output} size={size} showName={showName} />
        </>
      }
    </div>
  );
}
