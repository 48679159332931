import { Switch } from 'components';
import { useContext } from 'react';
import { FieldContext } from '../FieldContext';

export const Boolean = () => {
  const { value, onChange } = useContext(FieldContext);
  return (
    <Switch checked={!!value || false} onChange={onChange}
    />
  );
}
