export const DellIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 48 48" 
            width="32px" 
            height="32px">
            <path fill="#0288d1" d="M24,4C12.956,4,4,12.956,4,24s8.956,20,20,20s20-8.956,20-20S35.044,4,24,4z M24,41c-9.391,0-17-7.609-17-17S14.609,7,24,7s17,7.609,17,17S33.391,41,24,41z"/><path fill="#0288d1" d="M35.641,25.563l2.84,0.004l-0.004,2.395l-5.691-0.012l0.012-8.172l2.855,0.004L35.641,25.563z M26.34,25.102l-4.699,3.684l-4.285-3.379c-0.621,1.484-2.109,2.52-3.836,2.516l-3.664-0.004l0.008-8.172l3.668,0.004c1.926,0.004,3.309,1.223,3.828,2.531l4.297-3.367l1.586,1.25l-3.934,3.086l0.754,0.594l3.934-3.086l1.59,1.254l-3.934,3.082l0.75,0.594l3.941-3.082l0.004-2.836l2.852,0.004l-0.008,5.781l2.84,0.004l-0.004,2.391l-5.691-0.008L26.34,25.102z M15.012,23.84c0-1.113-0.73-1.742-1.77-1.742h-0.637l-0.004,3.477h0.621C14.184,25.574,15.012,25.051,15.012,23.84"/></svg>
    )
}

