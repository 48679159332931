import { Card } from '@premcloud/ui';
import './LicenseMessage.css';

type LicenseMessageProps = {
  children: any
}

export const LicenseMessage = (props: LicenseMessageProps) => {
  return (
    <div className="license-message">
      <Card content={props.children} />
    </div>
  );
}
