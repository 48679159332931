import { useConfirm, useSpinner } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { SetupOverviewValue, SetupStepValue } from './Models';
import { SetupService } from './Service';
import { SetupContext } from './SetupContext';
import { SetupNav, SetupNavType } from './SetupNav';
import { StepCounter } from './StepCounter';
import { StepForm, StepFormSubmission } from './StepForm';
import { Stepper } from './Stepper';
import './Setup.css'
import { Problem, isProblem } from 'data';

type ExitReason = 'Cancelled' | 'Finished';

type SetupProps = {
  setupId: string;
  onExit: (reason: ExitReason) => void;
};

export const Setup = (props: SetupProps) => {
  const { setupId, onExit } = props;
  const confirm = useConfirm();
  const [overview, setOverview] = useState<SetupOverviewValue>();
  const [currentStep, setCurrentStep] = useState<SetupStepValue>();
  const [loading, setLoading] = useState(false);
  const [problem, setProblem] = useState<Problem>();
  const [formSubmission, setFormSubmission] = useState<StepFormSubmission>();
  const interactiveSteps = useMemo(() => overview?.value.steps.filter(step => step.interactive) || [], [overview]);
  const valid = useMemo(() => formSubmission?.data !== undefined, [formSubmission]);
  const spinner = useSpinner();

  const handleNav = async (type: SetupNavType) => {
    let stopLoading = true;

    try {
      if (type === 'next') {
        setProblem(undefined);
        if (currentStep) {
          setLoading(true);
          const result = await SetupService.submitStep(currentStep, formSubmission?.data || {});
          if (result.finish) {
            await SetupService.finish(result.finish);
            onExit('Finished');
          } else if (result.next) {
            const step = await SetupService.getStep(result.next);
            if (step.rel.includes('auth.redirect')) {
              // Keep spinner visible while redirecting the user.
              stopLoading = false;
              window.location = step.value;
            } else {
              setLoading(false);
              setCurrentStep(step);
            }
          }
        }
      } else if (type === 'back') {
        setProblem(undefined);
        if (currentStep?.back) {
          setLoading(true);
          const step = await SetupService.getStep(currentStep?.back);
          setLoading(false);
          setCurrentStep(step);

        }
      } else if (type === 'cancel') {
        const result = await confirm('Cancel Pipeline Setup', 'Are you sure you want to cancel this pipeline setup?');
        if (result === 'yes') {
          if (overview?.cancel) {
            await SetupService.cancel(overview.cancel);
            onExit('Cancelled');
          }
        }
      }
    } catch (err) {
      if (isProblem(err)) {
        setProblem(err);
      }
    } finally {
      if (stopLoading) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function load() {
      try {
        spinner(true);
        setLoading(true);
        const overview = await SetupService.getOverview(setupId);
        if (overview.next) {
          setCurrentStep(await SetupService.getStep(overview.next));
        }
        setOverview(overview);
      } finally {
        setLoading(false);
        spinner(false);
      }
    }
    load();
  }, [setupId]);

  useEffect(() => {
    if (formSubmission?.autoSubmit) {
      handleNav('next');
    }
  }, [formSubmission])

  return (
    <SetupContext.Provider value={{
      overview,
      interactiveSteps,
      currentStep,
      loading,
      problem,
      valid
    }}>
      <div className="pipeline-setup">
        <StepCounter />
        <Stepper />

        <h2>{currentStep?.desc}</h2>

        {currentStep?.rel?.includes('form') &&
          <StepForm onChange={setFormSubmission} />
        }

        <SetupNav onNavigate={handleNav} />
      </div>
    </SetupContext.Provider>
  );
}
