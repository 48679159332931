import { SpinnerProvider } from 'components';
import { Route } from 'react-router-dom';
import { UserList } from './UserList';

export const routes =
  <Route path="/users" element={
    <SpinnerProvider>
      <UserList />
    </SpinnerProvider>
  } />;
