import { FormField, Option, Select } from "@premcloud/ui";
import { useContext, useEffect, useState } from "react";
import { createField } from "utils";
import { FieldContext } from "../FieldContext";
import { makeTrackable, validate } from '../../validate';

const frequencyOptions: Option[] = [
  { value: 'minute', display: 'Minute' },
  { value: 'hour', display: 'Hour' },
  { value: 'day', display: 'Day' },
  { value: 'week', display: 'Week' },
  { value: 'month', display: 'Month' },
  { value: 'year', display: 'Year' }
];
const minuteOptions: Option[] = Array(12).fill(0).map((_, i) => ({ value: i * 5 }));
const hourOptions: Option[] = Array(24).fill(0).map((_, i) => ({ value: i, display: i < 12 ? `${i} AM` : `${(i % 12) || 12} PM` }));
const dayOfWeekOptions: Option[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  .map((day, i) => ({ value: i + 1, display: day }));
const dayOfMonthOptions: Option[] = Array(31).fill(0).map((_, i) => {
  const s = ["th", "st", "nd", "rd"];
  const v = (i + 1) % 100;
  return {
    value: i + 1,
    display: (i + 1) + (s[(v - 20) % 10] || s[v] || s[0])
  };
});
const monthOptions: Option[] = [
  { value: 1, display: 'January' },
  { value: 2, display: 'February' },
  { value: 3, display: 'March' },
  { value: 4, display: 'April' },
  { value: 5, display: 'May' },
  { value: 6, display: 'June' },
  { value: 7, display: 'July' },
  { value: 8, display: 'August' },
  { value: 9, display: 'September' },
  { value: 10, display: 'October' },
  { value: 11, display: 'November' },
  { value: 12, display: 'December' }
];

export type ScheduleValue = {
  Frequency: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
  Hours: Array<number>;
  Minutes: Array<number>;
  Months: Array<number>;
  DaysOfWeek: Array<number>
  DaysOfMonth: Array<number>;
};

export const Schedule = () => {
  const { value, onChange }: { value: ScheduleValue, onChange: (value: ScheduleValue) => void } = useContext(FieldContext);
  const [frequency, setFrequency] = useState(makeTrackable(createField('frequency', value?.Frequency || '')));
  const [minutes, setMinutes] = useState(makeTrackable(createField<Array<number>>('minutes', value?.Minutes || [])));
  const [hours, setHours] = useState(makeTrackable(createField<number>('hours', value?.Hours[0])));
  const [months, setMonths] = useState(makeTrackable(createField<Array<number>>('months', value?.Months || [])));
  const [daysOfWeek, setDaysOfWeek] = useState(makeTrackable(createField<Array<number>>('daysOfWeek', value?.DaysOfWeek || [])));
  const [daysOfMonth, setDaysOfMonth] = useState(makeTrackable(createField<Array<number>>('daysOfMonth', value?.DaysOfMonth || [])));

  // Trigger onChange when form fields change.
  useEffect(() => {
    const dirty = frequency.dirty || hours.dirty || minutes.dirty || months.dirty || daysOfWeek.dirty || daysOfMonth.dirty;
    if (dirty) {
      const valid = frequency.valid && (() => {
        switch (frequency.value) {
          case 'hour': return minutes.valid;
          case 'day': return hours.valid && minutes.valid;
          case 'week': return hours.valid && minutes.valid && daysOfWeek.valid;
          case 'month': return hours.valid && minutes.valid && daysOfMonth.valid;
          case 'year': return hours.valid && minutes.valid && daysOfMonth.valid && months.valid;
          default: return true;
        }
      })();
      const value: ScheduleValue = valid ? {
        Frequency: frequency.value as any,
        Hours: hours.value !== undefined ? [hours.value] : [],
        Minutes: minutes.value,
        Months: months.value,
        DaysOfWeek: daysOfWeek.value,
        DaysOfMonth: daysOfMonth.value
      } : undefined;
      onChange(value);
    }
  }, [frequency, hours, minutes, months, daysOfWeek, daysOfMonth]);

  return (
    <div className="pui-form compound-form-field">
      <FormField
        label="Every"
        dirty={frequency.dirty}
        input={
          <Select
            placeholder="Frequency"
            value={frequency.value}
            options={frequencyOptions}
            onChange={value =>
              validate({
                ...frequency,
                value
              }, setFrequency)
            }
            onBlur={() => validate(frequency, setFrequency)}
          />
        }
      />

      {frequency.value === 'week' &&
        <FormField
          label="On"
          errors={daysOfWeek.errors}
          dirty={daysOfWeek.dirty}
          input={
            <Select
              multi
              value={daysOfWeek.value}
              options={dayOfWeekOptions}
              onChange={value =>
                validate({
                  ...daysOfWeek,
                  value
                }, setDaysOfWeek)
              }
              onBlur={() => validate(daysOfWeek, setDaysOfWeek)}
              hasError={daysOfWeek.errors.length > 0}
            />
          }
        />
      }

      {(frequency.value === 'month' || frequency.value === 'year') &&
        <FormField
          label="On the"
          errors={daysOfMonth.errors}
          dirty={daysOfMonth.dirty}
          input={
            <Select
              multi
              value={daysOfMonth.value}
              options={dayOfMonthOptions}
              onChange={value =>
                validate({
                  ...daysOfMonth,
                  value
                }, setDaysOfMonth)
              }
              onBlur={() => validate(daysOfMonth, setDaysOfMonth)}
              hasError={daysOfMonth.errors.length > 0}
            />
          }
        />
      }

      {frequency.value === 'year' &&
        <FormField
          label="Of"
          errors={months.errors}
          dirty={months.dirty}
          input={
            <Select
              multi
              value={months.value}
              options={monthOptions}
              onChange={value =>
                validate({
                  ...months,
                  value
                }, setMonths)
              }
              onBlur={() => validate(months, setMonths)}
              hasError={months.errors.length > 0}
            />
          }
        />
      }

      {(frequency.value && frequency.value !== 'minute') &&
        <div className="inline-form-field">
          {(frequency.value === 'day' || frequency.value === 'week' || frequency.value === 'month' || frequency.value === 'year') &&
            <FormField
              label="At (hour)"
              errors={hours.errors}
              dirty={hours.dirty}
              input={
                <Select
                  value={hours.value}
                  options={hourOptions}
                  onChange={value =>
                    validate({
                      ...hours,
                      value
                    }, setHours)
                  }
                  onBlur={() => validate(hours, setHours)}
                  hasError={hours.errors.length > 0}
                />
              }
            />
          }

          {(frequency.value === 'hour' || frequency.value === 'day' || frequency.value === 'week' || frequency.value === 'month' || frequency.value === 'year') &&
            <FormField
              label="At (minutes)"
              errors={minutes.errors}
              dirty={minutes.dirty}
              input={
                <Select
                  multi
                  value={minutes.value}
                  options={minuteOptions}
                  onChange={value =>
                    validate({
                      ...minutes,
                      value
                    }, setMinutes)
                  }
                  onBlur={() => validate(minutes, setMinutes)}
                  hasError={minutes.errors.length > 0}
                />
              }
            />
          }
        </div>
      }
    </div>
  );
};
