import { Form, required } from '@premcloud/ui';
import { Spinner } from 'components';
import { useContext, useEffect, useState } from 'react';
import { Field } from './field';
import { SetupField, SetupStepFormValue } from './Models';
import { SetupContext } from './SetupContext';
import { validate } from './validate';
import { Problem } from 'data';
import { ValidationErrors } from './ValidationErrors';

const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
}

export type StepFormSubmission = {
  autoSubmit: boolean;
  data: {
    [key: string]: any;
  }
};

type StepFormProps = {
  onChange?: (submission: StepFormSubmission) => void;
};

export const StepForm = (props: StepFormProps) => {
  const { onChange } = props;
  const { currentStep, loading, problem }: { currentStep: SetupStepFormValue, loading: boolean, problem: Problem } = useContext(SetupContext);
  const [fields, setFields] = useState<SetupField[]>();
  const [errors, setErrors] = useState<string[]>([]);

  const createField = (field: SetupField): SetupField => {
    const validators = [];
    let value = field.value;
    let dirty = false;

    if (field.required) {
      validators.push(required());
    }

    if (currentStep.rel.includes('auth')) {
      if (field.name === '__RedirectUri') {
        value = window.location.href;
        dirty = true;
      }
    }

    if (isNullOrUndefined(value) && !isNullOrUndefined(field.defaultValue)) {
      value = field.defaultValue;
      dirty = true;
    }

    return {
      ...field,
      initialValue: field.value,
      validators,
      errors: [],
      dirty,
      value,
      valid: !field.required || !(isNullOrUndefined(value) || value === '')
    };
  };

  useEffect(() => {
    if (currentStep) {
      setFields(currentStep.value.map(createField));
    }
  }, [currentStep]);

  useEffect(() => {
    if (fields) {
      setErrors(problem?.errors
        ? Object.keys(problem.errors)
            .filter(key => !fields.some(field => field.name === key))
            .map<string[]>(key => problem.errors[key])
            .flat()
        : []);
      setFields(current => current.map(field => ({
        ...field,
        initialValue: field.value,
        dirty: false,
        errors: problem?.errors?.[field.name] ?? []
      })));
    }
  }, [problem]);

  useEffect(() => {
    if (fields && onChange) {
      const valid = !fields.some(field => !field.valid);
      const data: StepFormSubmission = valid ? {
        autoSubmit: currentStep.rel.includes('auth'),
        data: fields.reduce((obj, field) => {
          if (field.dirty) {
            obj[field.name] = field.value;
          }
          return obj;
        }, {})
      } : undefined;
      onChange(data);
    }
  }, [fields]);

  const createFieldSetter = (index: number) => {
    return (value: any) => {
      const field = {
        ...fields[index],
        value
      };
      validate(field, (updated: SetupField) => {
        setFields(current => {
          current[index] = updated;
          return [...current];
        });
      });
    };
  };

  return (
    <Form>
      <>
        <Spinner visible={loading} debounce={333} />
        <ValidationErrors errors={errors} />
        {fields?.filter(field => field.visible ?? true).map((field, i) => (
          <Field
            {...field}
            key={i}
            onChange={createFieldSetter(i)}
          />))}
      </>
    </Form>
  );
};
