import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app/App';
import './index.css';
import '@premcloud/ui/dist/palette.css';
import configData from '../src/app/appSettings.json';

const AppName = configData.APP_NAME;
const container = document.getElementById('root');
const root = createRoot(container);
window.document.title = AppName;

root.render(

  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
