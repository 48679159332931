import { useContext, useMemo } from 'react';
import { SetupContext } from './SetupContext';

export const StepCounter = () => {
  const { interactiveSteps, currentStep } = useContext(SetupContext);
  const currentIndex = useMemo(() => interactiveSteps?.findIndex(step => step.number === currentStep?.number) + 1, [interactiveSteps, currentStep]);

  return (
    <div>Step {currentIndex} of {interactiveSteps.length}</div>
  );
};
