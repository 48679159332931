import { CopyText, DescriptionDetails, DescriptionList, DescriptionListGroup } from "components";
import { formatDate, formatDuration } from "utils";
import { PipelineProperty, PipelineValue } from "./Models";

const NotSet = <span className="null">Not Set</span>;

const DatabaseProperties = ({ property }: { property: PipelineProperty }) => (
  <DescriptionList>
    <DescriptionDetails term="Provider" value={<CopyText text={(() => {
      switch (property.value.ServerProvider) {
        case 'sqlServer': return 'Microsoft SQL Server';
        case 'mariaDb': return 'MariaDB';
        case 'mySql': return 'MySQL';
        case 'oracle': return 'Oracle';
        default: return property.value.ServerProvider;
      }
    })()} />} />
    <DescriptionDetails term="Server" value={<CopyText text={property.value.ServerName} />} />
    <DescriptionDetails term="Port" value={<CopyText text={property.value.ServerPort} />} />
    {property.value.Credential &&
      <DescriptionDetails term="Credential" value={
        <DescriptionList>
          <DescriptionDetails term="Username" value={<CopyText text={property.value.Credential.Username} />} />
          <DescriptionDetails term="Password" value={property.value.Credential.Password} />
        </DescriptionList>
      } />
    }
    <DescriptionDetails term="Database" value={<CopyText text={property.value.DatabaseName} />} />
  </DescriptionList>
);

type PipelinePropertiesProps = {
  pipeline: PipelineValue;
}

export const PipelineProperties = (props: PipelinePropertiesProps) => {
  const { pipeline } = props;
  return pipeline && (
    <DescriptionListGroup>
      <PipelinePropertyList term={pipeline.value.input.displayName} properties={pipeline.value.input.properties} />
      {pipeline.value.input.filters && pipeline.value.input.filters.length > 0 &&
        <PipelinePropertyList term="Filters" properties={pipeline.value.input.filters} />
      }
      <PipelinePropertyList term={pipeline.value.output.displayName} properties={pipeline.value.output.properties} />
      <PipelinePropertyList term="Metadata" properties={pipeline.value.metadata} />
    </DescriptionListGroup>
  );
};

type PipelinePropertyListProps = {
  term?: string;
  properties: PipelineProperty[];
};

const PipelinePropertyList = (props: PipelinePropertyListProps) => {
  const { term, properties } = props;
  const value = (property: PipelineProperty) => {
    switch (property.type) {
      case 'string':
        return property.value ?
          <CopyText text={property.value} /> :
          NotSet;

      case 'boolean':
        if (property.value === true) {
          return 'Yes';
        } else if (property.value === false) {
          return 'No';
        }
        return NotSet;

      case 'database':
        return <DatabaseProperties property={property} />;

      case 'dateTime':
        return <CopyText text={formatDate(property.value as string)} />;

      case 'object':
        if (property.value instanceof Array) {
          return <PipelinePropertyList properties={property.value} />
        }
        break;

      case 'time':
        return formatDuration(property.value)|| NotSet;
    }
    return property.value?.toString() || NotSet;
  };
  return (
    <DescriptionList title={term}>
      {properties.map((property, i) => (<DescriptionDetails key={i} term={property.label || property.name} value={value(property)} />))}
    </DescriptionList>
  );
};
