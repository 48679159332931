import { CopyText, DescriptionDetails, DescriptionList, DescriptionListGroup, RefreshTool, Timer, Toolbar } from "components";
import { Card } from "@premcloud/ui";
import { useEffect } from "react";
import { dayjs, formatDate, formatDuration } from "utils";
import { usePipeline } from "./PipelineContext";
import { PipelineRunOutcomeIcon } from "./PipelineRunOutcomeIcon";
import { PipelineRunStateIcon } from "./PipelineRunStateIcon";

export const PipelineRunDetails = () => {
  const { pipelineRun, loadPipeline, loadPipelineRun } = usePipeline();

  const handleToolClick = async (tool: string) => {
    if (tool === 'refresh') {
      return await loadPipelineRun(true);
    }
    throw new Error(`${tool} not handled`);
  };

  useEffect(() => {
    loadPipeline();
    loadPipelineRun(true);
  }, []);

  return pipelineRun &&
    <>
      <Toolbar tools={[RefreshTool()]} onToolClick={handleToolClick} />

      <Card header="Basic" content={
        <div className="pui-card-content-insert">
          <DescriptionListGroup>
            <DescriptionList>
              <DescriptionDetails term="Name" value={<CopyText text={pipelineRun.value.name} />} />
              <DescriptionDetails term="Id" value={<CopyText text={pipelineRun.value.id} />} />
              <DescriptionDetails term="Pipeline Id" value={<CopyText text={pipelineRun.value.channelId} />} />
            </DescriptionList>
            <DescriptionList>
            {(() => {
              return pipelineRun.value.state === 'ready' || pipelineRun.value.state === 'pending'
                ? <DescriptionDetails term="Queued Time" value={<CopyText text={formatDate(pipelineRun.value.dateCreated)} />} />
                : <DescriptionDetails term="Start Time" value={<CopyText text={formatDate(pipelineRun.value.startTime)} />} />;
            })()}
            {pipelineRun.value.stopTime &&
              <DescriptionDetails term="Stop Time" value={<CopyText text={formatDate(pipelineRun.value.stopTime)} />} />
            }
            {(() => {
              switch (pipelineRun.value.state) {
                case 'ready':
                case 'pending':
                  return <DescriptionDetails term="Wait Time" value={<Timer start={dayjs.utc(pipelineRun.value.dateCreated)} />} />;
                case 'running':
                  return <DescriptionDetails term="Run Time" value={<Timer start={dayjs.utc(pipelineRun.value.startTime)} />} />;
                default:
                  return <DescriptionDetails term="Stop Time" value={<CopyText text={formatDuration(pipelineRun.value.runTime)} />} />;
              }
            })()}
            </DescriptionList>
            <DescriptionList>
              <DescriptionDetails term="Trigger" value={<CopyText text={(() => {
                switch (pipelineRun.value.triggerSource) {
                  case 'manual':
                    return `Triggered manually ${pipelineRun.value.createdBy ? `by ${pipelineRun.value.createdBy}` : ''}`;
                  case 'scheduler':
                    return 'Scheduled';
                }
              })()} />
              } />
              <DescriptionDetails term="State" value={<PipelineRunStateIcon state={pipelineRun.value.state} size={16} showDescription />} />
              {pipelineRun.value.outcome &&
                <DescriptionDetails term="Outcome" value={
                  <PipelineRunOutcomeIcon outcome={pipelineRun.value.outcome} size={16} showDescription />
                } />
              }
            </DescriptionList>
          </DescriptionListGroup>
        </div>
      } />
    </>
};
