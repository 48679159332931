
export const DatabaseIcon = () => {
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24">
        <title>database1</title>
        <path d="M10.286 10.286c4.031 0 8.076-0.723 10.286-2.277v2.277c0 1.888-4.607 3.429-10.286 3.429s-10.286-1.54-10.286-3.429v-2.277c2.21 1.554 6.254 2.277 10.286 2.277zM10.286 20.571c4.031 0 8.076-0.723 10.286-2.277v2.277c0 1.888-4.607 3.429-10.286 3.429s-10.286-1.54-10.286-3.429v-2.277c2.21 1.554 6.254 2.277 10.286 2.277zM10.286 15.429c4.031 0 8.076-0.723 10.286-2.277v2.277c0 1.888-4.607 3.429-10.286 3.429s-10.286-1.54-10.286-3.429v-2.277c2.21 1.554 6.254 2.277 10.286 2.277zM10.286 0c5.679 0 10.286 1.54 10.286 3.429v1.714c0 1.888-4.607 3.429-10.286 3.429s-10.286-1.54-10.286-3.429v-1.714c0-1.888 4.607-3.429 10.286-3.429z"></path>
        </svg>

    )
}


