export const MariaDBIcon = () => {
   return (
<svg
   width="210mm"
   height="297mm"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg8">
   <g
     id="layer1">
    <image
       y="0.012087182"
       x="0"
       id="image136"
       xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAYAAAA+s9J6AAAABHNCSVQICAgIfAhkiAAAIABJREFU
         eJzt3XlcVPX+x/EX+y6C4hIoKiqJhuaWtqlhXuTaoqlpqdertph2yzLLumVqpaVmi+YttVtqaqnl
         LUMyMZcUA5efqJQiIYJhgsDoDLOx/P44yEiiDMjMGeDzfDx6PBJm5vsd5rznnO9yvl+n0tLSUoQQ
         qnFWuwJCNHQSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFU
         JiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEU
         QmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUS
         QiFUJiEUQmUSQiFUJiEUQmUSQiFUJiEUQmUSQiFU5qp2BYRQQ2JyCnkFGgD639YDTw931eoiIRQN
         hkar4+DRFGYuXEbir2mgNYCpiDGjoln93puq1cuptLS0VLXShbADjVbHj/sOsGjFGvbvOwKNvMDd
         zfKA1D8p1R1VrX5yJhT1gsFo4lKhnou6QnR6A7m5uZzOyCLhUDIr9h6CjGwlfE0bXf1kDxf7V/gK
         EkJRZ+Tkazh4NIX/+/UkaWmnycq/yJk//iTl7Dk4lw+XTJZAubuAuyt4e4Cba+XhcxASQuHQ0rKy
         2XfgMGu/jyfup/1QXKL8wqPsctLNFVycoUUAtKhhIf5etVLXmpIQCoeUk69h2dpNvL/xe/LSssDX
         E3xtFJYm/rZ5XStJCIXDWb05lnGvvwsanRK8QD/bFWYy06djW9u9vhUkhMJh5ORrGP3MK8RvS1Au
         L/19bF9ooZExMVG2L+c6ZMaMcAgbt+2i2S33Ep+YrATQXlycuXfAnfYrrxISQqG61ZtjGTHhBaUH
         09OOM1cMJnp3u5mOoSH2K7MSEkKhqtWbYxk39XUI8LV/4SUlzJs+2f7l/oWEUKhmfex2xk2bq5wB
         Xex8KOZdYtq4YdzTt5d9y62ETFsTqtiRkETUP6aBh7v9A6jRET3wdrYuX2Tfcq9BQijsLidfQ7Ne
         MeDloUoAo+7qwaaP3sbf1w69r1aQIQphVwajifufmK5MKbN3ALV6hzoDXiZtQmFXK9d9zf7dhyre
         xWBrxSWg1TNt/EMOF0CQy1FhR4nJKdzW/xFo3dR+hWr1UFpK/Jr3HaITpjJyJhR2odHq+OfMt6CF
         neZpFpfAuXwmDRtEwZEfHTaAIG1CYSez3/uYlJQ0+0xFM5jA15tV/5nL2AdjbF/eDZLLUWFzyanp
         dH1gPLi42L4zRqOjT68ufPzmy0R2UHditrUkhMLmWg96mMysc7bvjMm7xJhhA1nyxssOM/xgDWkT
         CpuK251AZvIp2wcw9yLTp45l9Xtv1qkAgpwJhQ3l5Gtodsf9djkDzn7xCV6bMsG25diInAmFzSxb
         uwkKjbYt5FIhk0bF1NkAgpwJhY0kp6bTddhE5R+26owpLiGwaQC/ff0pQQHqLlFxI+RMKGziw+Wr
         QG+0bW+oVs+cf4yo0wEEORMKG9i4bZflJl1bOpOLPjtR1SXsa4OcCUWte2LeB7a/SddgYtKkoXU+
         gCAhFLVs/sefk3f6D9sPyhtM3H27405Fqw4Joag1OfkaZs5cbJ+paa4uhLdrY/ty7EBCKGrNc7Pf
         gaZ2WiumsR+9IyPsU5aNSQhFrYjbncCaVd+Dt6ftCzOYGD2gr+3LsRMJobhhaVnZPDprIQQH2qfA
         QiPD7u1vn7LsQEIobtj8Dz4h70y2/Zar8PehY9vW9inLDiSE4oZ8uv4bVqyPBT9v+xRYNkvGx8sO
         l712IiEUNZaWlc3E0c/ZdsOWvyou5tZWLQkLaWm/Mm1MQihqJCdfQ/uHn4Qudr5x1mjm7tu627dM
         G5MQimrTaHWMmzEH/sy1/7KFOiN3dr/FvmXamIRQVIvBaGLmomXE/fCz7TbtvJ6QIG65uaP9y7Uh
         WehJVMvTr85jxdrv1dkDvmxDT3e3+nXY1q93I2xGo9Xx0FMvEr/noDoBBCgppUubkDq3fEVV5HJU
         VOlkRhaD/vE08XsP2Wde6LUYTHSLCFevfBuRM6G4rtWbYxn3xgegLbTfWOC1XDRw1x23qVsHG5AQ
         ikrl5Gt4bvY7rFkfB0H+9t1B91paN6Vj62C1a1HrJISigpx8DSu/2qzcktTU1777x1+PwcSk+6Pq
         xU28fyUhFIDS8fLt9l08+/5KZR6oPTdtsYbRTFhYG7VrYRMSwgbucvjmL19LytFUpeNF7bZfZfQm
         unWqX+ODl8lCTw3U5cvO1d/+SMqRk8qaMPae/VIdqX+izzsol6Oi7tuRkMSW+D0sfncV+Hsps17U
         GvezlslM7+he9TKAICFsEE5mZPHjTz/z9tpvyDyZAa4ujtfmu55CI0+MuE/tWtiMhLCeysnXEJ+Q
         xOffbCUu6QgUaJW2XmM7rQFTm1xdaBMaonYtbEZCWI9otDq27k7g67h44o+eJC8tC7w9lDG+uhg+
         UG7ibdWi3k3avpKEsI673Lu5e18SK7btgdyL4OsJbq72vdnWVsxFRN3Ssc4vdX89EsI6KDk1nZTU
         NOVSc+02aOJtOeM5eidLdWkNTBh+v9q1sCkJYR2g0er480I+Pyck8fGG70g8eRq0evByhw7N1a6e
         bbUIpEM9WeT3WiSEDspgNHHm3HnWb9nGT7v2svNkBuRdUi413d3UvZvBXkxmoiJvJjioido1sSkJ
         oYNJTk0ndsdutu9NIn5HIni4gocbuLjUjzZedWgNDL3nzno7PniZzJhxAGlZ2ez6eT/Lv45l//Yk
         ZRDdy93220w7Oq2e80mx9bpTBuRMqJqTGVn8npHJ+6s3EvfTfiguUYJXlwbRbclgInpAH/y8VVjH
         xs4khHaUk6/h4NEUPt34LRv2HYbcAqVXU40Fkxyd1sAjf6+fty79lVyO2kFyajofLl/Fir2HICNb
         6VxxhJtkHZlGh/63XQ0ihHImtKEdCUks+HQ9cd/thEZe4FEPx/FsQatn8viHGkQAQUJoE2lZ2Uyd
         vZC4TfHQrJHj3J1eV5iKGD80Ru1a2I2EsJat3hzLuBlvgbOzdLLUhMlMnz6R9WYDUGtICGtJTr6G
         l+e9p+xQ1NDG82qT1sCsqRPUroVdSQhrQXJqOgMen07e6T8kgDfCZKZn1w70v62H2jWxKwdez6Bu
         WB+7na4dY8jLzW8YU8ls6ZyGuS9MaTAdMpdJCG/A/I8/Z/SEF6FrsGOvz1IXmMz0H9ijwZ0FQS5H
         a+y1RR8xd8lq6fmsLXlaXp32ZIM7C4KEsEZeeGcJCz9aU3fvVnc0BhMjRg7inr691K6JKmTGTDXN
         //hzZs5dWn8H3Q0m5a58e15eHzlLwaUD9W63JWtJQ6Ya1sduZ+aT8+tvALV6Jo++n94d2ygTyu0h
         9yKrvlnUYAMIEkKrJSanMPrZ16FLS7WrYhvFJUSEhfD8xEdr5/VMZtDorv+YAi2THxvJ2AcbzuyY
         ykgIraDR6njmjcXKep31tRdUq+ep0UNp5ONNYsYfN/Y+TWaiet7Cynkzrh1Ek5nePSKY/a/Hal5O
         PVFPj6jaNfu9j9m/P7l+32R7XsuY4fdxUVcIFzQ39lqFRobec6eyVqi5+OrfF5eAqYjl81+t9zfs
         WkNCWIWN23axeMVX9XsmjMHE6KeG4+/rQ0ZmFuhNN/Z6Tk6MfGAw5/M14OxU8XfFJXAsmxPxXxLZ
         oe2NlVNPSAivIydfw4hRz9T/oYhCI4PvUIYHEpNTlDv8a0qr59WpYwkK8OfC+dyKl7UGJdxHTsbS
         sR6vqF1dEsJr0Gh1DJk0TbkVqT4rLgFfT6L73QHA8V9PKgtL1VT2RYbc2x+APy/kWUJYoKV35/Yc
         +XqlnAH/QkJYCYPRxPQ5C0k8mFL/74DXG5n88H0EBfhjMJr4LS1DGSesibLdky7fhpT9Zw6UloJG
         x6SRg9myYrEEsBJ1dsaMRqvDZC4CqPXG/csLlrBi1bcNY0qawVx+A+2Zc+c5kJ1b855RrYF50ycD
         yueTnJYBTk7Er32/wc6GsUadCWH5Xuqrvoa0LLj0l86DJt4Q6EfvsNb0iIygb9cIwtu1oUlgAGEh
         1o/tjX32FdZs/LF2A1hcAsVlvYQlpVD0lx5DZyflJuDLnRgudhoK0eoZMSyq/MyV9Ue2svhUTdrA
         JjOBYSEVwvbCE/9g+KB+tVXbeqtOTFuL253Ao7MWKvfr+Xpd/wA1mZWD3FSkXEr6ehPRvCmdw1rR
         tEkTWrcIolunjgQ29sfTS1nlzKDXcyzlBGtj44nfd/jGVz8zmZXyTcqZmlYt6BPcjFYtmuLv6Ylf
         Iz98vTwB0BeXUGwykZN7gVydgby8fE7laZRbo3I0ytikhys4OSn/7+xUOyEtmxFzausX5V9Sj7ww
         m3X/+xG8Pav/eoUG4j9fLGe8GnD4EMbtTmDwmGcty7/XRHEJmIugpEQZtzIXg5uL0gHh5qrs61Ba
         qiw/eCNjgVo9uLvSv0t7bo3szK1dbqZr5060bBqIu5trlVOzDEYTRrMZk7mIi7pCLuTl83vWH5zO
         PMsFzSV0egMn006T/WcOKVoD5BeA1qA82d21Ykivx1wE5iLWfTiHUTEDy8v26hlds3AbTETf1Yut
         yxdV/7nCsUOYlpVN+1a3Q7uWygaXjkqjA38fJg+NZvzQGJuvj6LR6sgtuMiFvHy0Oh2nM7L49cxZ
         MrLOknkul4s6PZfMZgB0Ze1mAB83V/zc3Gjk48Wb0x6vcNaK253A4HHTanYpqtXzy9fLG9S6MLXJ
         oduEYSEt0RtO8PKCJSxe8gUE+TvOtLHiEiV8zk6snP8ijwz9u93uhfP39cHf18fS1q2FS8Bvf9qr
         nE2rK/ci816dIgG8AQ59JrzSjoQkop5+FbSF6g8baHTQxJ+V05+wa/hsJS0rm/bhg6q/zZrJTM+w
         VuzZ+Gmd/xuoyUFOK1W7p28vTmxaQZ/I8Kpn59tK2Z0B0yc/yvkfv2TCqKH14uD74n9boVkNLkPP
         aVj57tx68TdQU50JIUDH0BB+Wr2U6IG32z+Ilwrp3SmM+LUfsGDG1Hoz8TgnX8P7G7+vfo+wRseS
         d1+SwfdaUKdCCODp4c7W5YuY99KTyv7sWr1tCzSYlHbPjCf4ZdOn9a4LftnaTeSlZVWvrZ13iTEP
         3MOU8aNsV7EGpM60CSuTk69h3tKVLP5yS+132BSXwNk8oobcycdvvVKtAf+6Ii0rm/YP/hOKiqp+
         8GUFWqLvvUOGI2pRnQ4hKEFs1iumdrcXK+t4WTdnevk4Wn009tlXWBO7y/rBeY2O6IG3s37xnAa9
         HEVtc+ghiqoYjCbGzZijDFLXBpMZjGYmj3+I5yc+Wi/PfpftSEhizZad1n95SQBtpk6H8L3P1hG3
         dc+NL7xUNuYXcUsHFr04hei7+9ZOBR3YiJfnWz8uWKBlxP0DWLVgtvSE2kCdDeHGbbuYOW/ZjQXQ
         ZIY8LYSFsPKVp5kwamjtVdCBzVn6qdIZU9VqASYzXNTz6rTxzHn+KftUrgGqk23CnHwNzQY8pJzB
         atIhUxa+3nd0ZdrER+p1u++vEpNTuK3/I1Vv21agJbBtMBveeqne9Qg7mjp5Jhz9zCvK0EF1Z84Y
         TGAuIvqevsx+eiKR4e0b1OWVRqvj6dkLocV1xjgLlQnh0yaN5K0Xpjaov49a6lwIN27bRfyWn6u3
         AafBBEXFjIi5mydHD2uw3+yz3/uYxKTjlV+GloVvTEw/np7wqMwFtaM6dTlqMJroeN9YMrNzqr4M
         LS5RDiydkREjBzF90tgGfWDNWfopsxZ8UvEuCZNZuRVKa2T0xKE8O3ZEg/4bqaVOnQl3/nKQzAMn
         Kj8LXr5n0GgGjZ5WPcOZ9MDfePSBwfV6qMEa8z/+nFkzFkPLRsoYqN4Evp5E3X4rj8RE8dCQQTLs
         oKI6FUJ3N1cwFsO5fOUHri7Kzbm+XrRqGUS/iPZ07tSRmHvupmPrYGnPAKs3x7L62x+JfmQQbUNa
         0rpFEO3btuHe23tK8BxEnbocBTiZkUWB5iIAnl5e+JQtE9HIx7veTKquTRqtMtHdw81NvpQcVJ0L
         oRD1TZ27i0KI+kZCKITKJIRCqExCKITKJIRCqExCKITKJIR11OXxP1H3OdyMGY1Wx9bdCeX/buzr
         c0M32ebka4jbtRc3d2Wgul3ITXV+fuRriz7ih30HCA1uzrwZ/1JtWt7GbbsoKjJb9VhXVzcC/bwJ
         bRVC08aNajRbZ/Xm2PLP0VqNfX0IbOxP21bB+Hl7OeSEBYcbrI/bncDgfhMguGym/9kCzucl1Xg2
         zGMzZrNi6UZl1yatkaghd7J91ZJarLF9Lf1sPVNfWgCBvqA3ERERxvHv19i9HgajCa+uA6FAp0wf
         zLdy1Ts/d1pFtudv3SLo2z3S6nmrBqMJL8/W0CpU2Wjnz8LqVTg8iKjekdzeNYKXp05yqDA6XAif
         mr2QZV9stiw+pNUz/fHRLJgxtdqvdTIji/Ah45R/uDhDcQmtWgZx8MtP6uwUt74jH2N/8gnLvZRH
         0ii4dMzu80ANRhPB0aPJ+zMXAhozbXC/8p2mKqMvLiEj6yzxR0+StzMFmpftLdIigCXPTqpy+USD
         0YRXYA9oF0Tvjm2YOnY4ZoOxynqe11wk+dRp1n2/Q1lFwdMNmjbmyLpljrNmaqmDIez2UroMKKVr
         lPJflwGldLij9HxeQbVfa/aSlaW062t5ra5RpQT3Ko3fl2iDmtvHhh92ltK8eykd7igluFdp1Ngp
         qtRDbzCWBvYfVkqnu0t7D/tnacElrdXPPXE6U/lsevxN+XzK3sf1PmO9wViKd5dSwu8qHfH0SzWq
         7+wlK0sJ7lVK+F3VrrMtOVTHTE6+BrIvVrxX0MUZDGa++2FHtV9r1n+/uno5P083tsTvqYXaqmP4
         oH6s+s9cxsT0Y/q/xrFl+btqVwmtwVS+a7I1OoaG8NqUCZza/F+i7+kLbi7E7znI6GdeUY4BG/D0
         cOe1KRMYMSwKnJ1I3HukQt+DmhwqhAePpkBAJUvw+fuw6L/rq/UBxe3aC+fzr77518uDH/b8coM1
         VdfYB2NY/d6bLJhRt5efCAtpyfrFc5RtDbzcid+ZxLylK21a5sgh0cqNzL6eHDz2m03LspZD9Y7u
         SzqsXLNfZjIrm3a6OJNy5BQHj6ZY3VM67uW3wd/H8jpgea0LGtKysq3uVTyZkcUvh5NJTTuNVm+g
         WVATgpsHcdutkXQMDSl/XFpWNjq9ofzfPl6elZZxMiMLg8nSq9iyaWCFNmpicgpJh5JJy/qDZkFN
         aN+2DUP69cXTw52cfA3ZuXkVXs+atk1icgrHUk5w+mw2Wr2BsJCbaH5TS/r16lah7KrqVtv8fX1Y
         v3gOjf16QtdgFn/yJaPuj7ZZD3agn7fSsePuWuGzUpPDhNBgNLF0Szx4eSg/cHUluldX4vYkKZ0Q
         gb48//ZSq0K4enMsXNQryyGazET1vIXAxn5s2L5PCWL+RTIys6oM4Y6EJJ5+431SEo6Br4dyA7GT
         U9ke9CVwoZBWd0Twyb+fJfruvry+cAlr3v8aAjwg30j0pJirlovXaHUMfeolUnYeAQ8XyNew5L/z
         mDJ+FMmp6Tzxylvs/36/skuSi7NywAT6cWrLasJCWrLyq83MfPJ1uCIYpXn/d92/xbg3PoDfzynv
         4fJao8Vluxbn64l+ZBBvz5hKZIe2DH38eVJ+TimrWyHT3/5XjTrFqsPf14eV695i4isLwNONhStW
         89UH82xSlslcVL5YtM91OpLsyWEuR8+cO0/ekTTL5aOHB+//exr4eisHjLsbKb/9TpwV1/Frv49X
         ttcGKDTy1vSn6Ne9q7KsA4CLc5Xtwvkff07UiCmkpGcqy2kE+inty8tbUru7QmggmWeyGTxyKks/
         W09Q0yYQHqQ8PjyIpj5Xf8gebm60bNIYQgOVx7VqRpNmTUnLyqZr1Cil57NdEHi5K2W4uTBp0F3l
         Xxh+Hh7QpoXy3OBA6BRaaf0NRhNjn32FcVNfB6PJ8h68PJT6OzspVx2hgcT9tJ+uUaPYkZBEy+ZB
         lrq1aUIT/yrWJq0l/e7sAwGNwMOdDdv22mwywrc/7QVvd9AauK3bLTYpo7oc5kyYfCJN+eMAmMz0
         79iUjqEhrJz+BBNnvqNcWnq4sXDFF9c9GyYmpxC3KV45iIpLiAgPJbxdKL9n/WF5kLcni7/cwrv/
         fq7S11j62XpmzvoAWgRYflhoIKJtMF0jI7i5TSsu6Qo5lppO4qnT5JmLmLroEwKbBtRoA9PGvj5M
         nb1QOdMCETcFKWEADmdm0y0ivNqvOe6FWWz49qeKiyMXGugZ3oZePbvRomkgBfkFHE4+zs6TGVBo
         IGryTPDxUa4W7CwspCURTfxJ+SMHzEUcPJpSq6viGYwmNmzdzrJl68DTjej7+jOkn2OstO44ITx6
         3NIeNBXRs+etANz3t3vg3wuVn7u7Eb9pFyfnZlVoi13pw0+/gGZlB96lQsYOi8Hf14dekZ3LD3IA
         fs/hZMbVr5Ocms7U+R9VDGDZltATRz54VfsoLStbuQz93w7yoPqLEQf68eisheSlny1fbPeWmzuW
         l5OTr8HPu3qb3WzctosNm3dUXNrwUiEbls0rb1teKTk1ncdemkvi0ZPVq3st6xzejpSMbCgu4fy1
         OuGcncg8l0vc7gS0BlOVr6kv1JGadppNO/aRkpIGjX2I6h3Jqndec5hOLYcIoUar44d9ByztFYOZ
         Hl1uBiAowJ9p4x9i8coNytkw2J8FS1ey/J1ZV71OWlY2a+L2KGej4hIIbs7EkQ8CyjdtYHBz8nLL
         ekwDvEg+kXZVCN/48BMoKrb8IPciS9547pqDyWEhLVn93psENX3XUsdqysvOIbBtML9/t+qqQfea
         dIqMeOKlivU4k8uRX765ZgdOZIe27Fr7H/o98iSJx0+pth150yZNoKQEnJy4cD638ge5u7E/+YQy
         q8oa3q7g5w6+nvTu3onZzzzmcHuNOEQIM7LPk3gwxbImpqcb3TvfXP77J8eOZPHmbco+et6erNgQ
         x1szn73qAF208gtLgPRGpj08pMJjJgwewMJP1ik7EXm6kXz0OMMH9avwGhv2HbbsVFRcQquItkwc
         PazK9zBzykT2/HKIA6f/qNHS/Fvfn1srs16SU9NBo7eE0GBi0qShVfagenq48+Gs6dw2avIN18Gm
         ikuICGvNUy89xSVj1TNmANLSTvN1UjKJvxxj8OMv0icynM8XzLrm1ZS9OUQI9+z9BUrKZs+ZzPTp
         HkHzJpbLwY6hIYy4/VY2bNtb/i29bO0mXpti+TZMy8pm2XfbLQEyFTFl/OgK5fTocrPS2wjg5cFn
         P+yqMI8wOTUdLmorTJl78RHr9qUPCvCnV89uHDhx2vr9/i6/38jwWuuSP5l+xtK2BjCaeWjIIKue
         Gxnenv5d2rPz2ClV2oW5Fy6AszOUFtGo8TU2+jEX0TmsVbV3CX4rX8N3P+xg4psfsv9QCuEPTXKY
         qWsO0Tt6PCOrQnvwzp5drzorTJ80VhlkBfD1Ytba/1UYvN/1837ILVD+UWhg0qiYq4Yg2oXcZOk1
         dXEm88AJzuZcKP+9Qa+3fBkAmIsJa1d572NleoaHWUJuLaOZwbXYQaAv1Ck9n5d5uNEutJVVz/X0
         cKdT504V/wZ2dPzE70rdnZwICgyo+gnVEBTgz4RRQ9m69E0wmKHgEi++4xgT+VUPocFo4mByimWj
         T4OZqD49rnpc78gIxgy/V9lXwsUZMrKJT0gqf42J85eCX9mk4Fwto4fGXPUa4e1C6RnWSmkvAjTy
         JG77ruvWz93N+osFN0+P6h/A5mJCmtTuAfdXLi7Wb6Lq6eJcsU1sJ2lZ2aRc0ICLC7g40+MW2wzW
         R9/dF1oEgpcHcUlHlKsflakewuQTp0j8Nc1y+ZOvv+YH8EDMIDivLPxLgC9z31+OwWhic/xuyLtU
         NrhtpveA7pV2b/v7+tCtUwdluXwAd1fSrhi6aBIYUPEs4uzE6Ywsq99Latrpij2w1riyvFoQFBjw
         l7N5ERmZ1r+HXzPO1t7Ox9Ww78BhuKBRds0a0Mems3Qm3zdQOQZyL/JH9jmblWMt1UN4LOUEFJY1
         sItLCOzd8Zpd8kP69aX3gO7KNDQXZ1JOnmHnLwf5/JutFQbnZz/z2DXLi+p/h+Wy1tWFvYePlQ8M
         X+5BLT9T+nqyNjbe6veS9FsaeFS/LeXm6VHt51xLh3ZtKt7b5+TEzn1JVj03LSubxFOnoRpn/9qg
         bHv+ltKeLzTyzNjhNi3P08VZ6YUFChxghQLVQ7g2Nh68yw5CvZF/RN1xzY4QTw93po4dXqFt+Pgb
         7xG3/7ByJi0bnO/bPfKa5XXvfDO4lr1tdzcSD6bw54X88t8/3L9v+TZhuLsRv+Vnq2bpxO1OUKbY
         qdChcaWwkJYE9u5o+SLx8mDusi+smvy+4ftt5GWeq9nGqzfg5QVLlPrqjYx48B6bDyH8kHCofOZT
         Mwe4r1T1EManXNETZzDT+zoBAhgxeCBkll2SujiTef6C5aDJ1/L8P0ddt6s/oJEfgW2DLQcp8ONP
         P5f//7PjR1nalgDBgQx+bjaJySnXfM3k1HQGT3nF7mcQXJyhkm76L2ZPV3ZfuvwYVxf6j5ly3SCu
         3hyrzBLyrd7EgBv1wjtLWLzkC+UKorEfbzw/xabl5eRrlLnALi7QLIDQVuoPU6gawuTUdCi4ZPlB
         vp7BVXwLenq4M+8/L1kOssuKSyC0pTIH8TqCAvwZ1itSmU8J4OnOrkNHyn/fMTSElS9NgdQ/lR+4
         OIPRxG1D/snIf80kbncCyanpJKemE7c7gcdmzKZrt/uVNkY17qmrqSbNmlb4AuGilsGPPc/62O3l
         P4q+uy9jhg1U2sll7zEl7QzNesXw3BvvsiMhieTUdBKTU1gfu52B46aiBCXpAAAD9klEQVQy7slX
         oZFXxde2kq+ne7U6sECZ1dP572NYuGS10pRwceHIumU2HbtLTk2n2b0PK3Nu87VMvm+gQ2ybp+o4
         4YGD/2f50ItLINjfqgHrEX8fxMxlqyv+UKvn1fHDrfqj9u0eyYqvtoI34OZKenomGq2uvOwJo4ai
         NxiZOuVNZTKzuxsE+rFh2142fLfT0vliLlb+v4UfUb0jadY8iHXfxdt0xkm7kJssl+8A3p7E/fAz
         TX08GRUzsPzHy99WZhStWR8HQf7ldVr82SZlZo+Hm9ILWlyi/L+rC5MffZDDx0+w/9e0al2SnsrT
         sGbjd3hdp22rNxi5oCskMyOTFXsPQeY5pVPKVMSkUTGVTr6olJtr+bS1qtpzrq5u6At1pJ89R8L/
         HSduR4JytVKgI/q+/sx73jEmJqgawuVfx1p21DWYif7bnVY9LyykJZMG3cWKtd8rA9MlpeDmwtMT
         HrXq+Xf27QUZeco0uZJSDhw9xYnfMyoMmE8ZP4rmN7Vk1qKPSDmernxbOzspdzdcqdBE9OC7+GbJ
         PJ6b/4HSXi0pgcJrz2vUGYxKZ1RRMVw0WLVWymW9IyOIvqsXcT/8bOmM8vbg0K+nyMnXlB/Inh7u
         LH97Fn263cJrn28gLy1Lebz7FR+5qwuULTE07clRvPvv5xgwciJcKlQepzdhus5wRd4lHRjN5GWe
         UxafMlk5tOHuUr7Y00NDBlnfBizUgtGf/UnHGDz4ceue4+FSXiZe7vTs0JqHhtzL5EeHO8z+jKqG
         sG1oCLd2ttwh8NcpZNfzwpSJuPlY/oidQ0Os7tZu3aIZk1+3fIgFWh15BVe3l4YP6se9t/dk6+4E
         vo6LJ/NcLkV6pefR1cuLtqEhjLvPchD1DA9j8mMjK9SpMoP79a3wvttU8xJs1TuvMStkOUkHlPsI
         nd3dK51U4OnhzpTxoxj5wGC++t9WtuxNIi8vnxKTqfw93No5nPFDY8q/gO66rYcyYF8mokPYNesx
         feSQat0Y6+PlSZtmTenVPZK2rYKrNQzh6eHO6OnjaVyD4Ph4edKpdTBdIsIJbxfqMOG7zOFWWxOi
         oVG9d1SIhk5CKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITK
         JIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRC
         qExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqExC
         KITKJIRCqExCKITKJIRCqExCKITKJIRCqExCKITKJIRCqOz/ASE7VgoKs/IHAAAAAElFTkSuQmCC"
       preserveAspectRatio="none"
       height="13.229167"
       width="13.229167" />
  </g>
</svg>

   )
}

