import {
  CopyText,
  DescriptionDetails,
  DescriptionList,
  DescriptionListGroup,
  Timer,
  Toolbar,
  useConfirm,
  useSpinner,
} from 'components';
import { Card } from '@premcloud/ui';
import { useEffect, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { dayjs, formatDate, titleCase } from 'utils';
import { PipelineService } from './Service';
import { ConnectorIcon } from './ConnectorIcon';
import { PipelineRunOutcomeIcon } from './PipelineRunOutcomeIcon';
import { RunsPieChart } from './RunsPieChart';
import { PipelineProperties } from './PipelineProperties';
import { describeCronExpression, getPipelineTools } from './Utils';
import { PipelineRunStateIcon } from './PipelineRunStateIcon';
import { usePipeline } from './PipelineContext';
import { historyRunInputConnectors, useConfirmRun } from './ConfirmRunContext';

export const PipelineDetails = () => {
  const { pipeline, loadPipeline } = usePipeline();
  const confirm = useConfirm();
  const confirmRun = useConfirmRun();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tools = useMemo(() => getPipelineTools(pipeline), [pipeline]);
  const showSpinner = useSpinner();

  const handleToolClick = async (tool: string) => {
    if (tool === 'refresh') {
      await loadPipeline(true);
    } else {
      if (tool === 'run' && historyRunInputConnectors.includes(pipeline.value.input.id)) {
        const { result, runId } = await confirmRun(pipeline.value);
        if (result === 'yes') {
          await PipelineService.createRun(pipeline.value, runId);
        }
      } else {
        const result = tool === 'setup'
          ? 'yes'
          : await confirm(titleCase(tool) + ' Pipeline', <>Are you sure you want to {tool} this pipeline?</>);
        if (result === 'yes') {
          switch (tool) {
            case 'setup':
              try {
                showSpinner(true);
                const overview = await PipelineService.editPipeline(pipeline.value);
                return navigate(`./setup/${overview.value.id}`, { state: pathname });
              } finally {
                showSpinner(false);
              }

            case 'remove':
              await PipelineService.removePipeline(pipeline.value);
              navigate('..');
              break;

            case 'enable':
              await PipelineService.enablePipeline(pipeline.value);
              return await loadPipeline(true);

            case 'disable':
              await PipelineService.disablePipeline(pipeline.value);
              return await loadPipeline(true);

            case 'run':
              return await PipelineService.createRun(pipeline.value);

            default:
              throw new Error(`${tool} not handled`);
          }
        }
      }
    }
  };

  useEffect(() => {
    loadPipeline(true);
  }, []);

  return (
    pipeline && (
      <>
        <Toolbar tools={tools} onToolClick={handleToolClick} />

        <Card
          header="Basic"
          content={
            <div className="pui-card-content-insert">
              <DescriptionListGroup>
                <DescriptionList>
                  <DescriptionDetails term="Name" value={<CopyText text={pipeline.value.name} />} />
                  <DescriptionDetails term="Id" value={<CopyText text={pipeline.value.id} />} />
                </DescriptionList>

                <DescriptionList>
                  <DescriptionDetails
                    term="Input"
                    value={<ConnectorIcon connector={pipeline.value.input} showName />}
                  />
                  <DescriptionDetails
                    term="Output"
                    value={<ConnectorIcon connector={pipeline.value.output} showName />}
                  />
                  <DescriptionDetails
                    term="Enable Depot Failsafe"
                    value={pipeline.value.continueOnError ? 'Yes' : 'No'}
                  />
                  <DescriptionDetails
                    term="Disable Depot Failsafe Auto Termination"
                    value={pipeline.value.continueOnDiagnosticError ? 'Yes' : 'No'}
                  />
                </DescriptionList>

                <DescriptionList>
                  <DescriptionDetails
                    term="Enabled"
                    value={pipeline.value.enabled ? 'Yes' : 'No'}
                  />
                  <DescriptionDetails
                    term="Schedule"
                    wrap
                    value={
                      <>
                        <CopyText
                          text={describeCronExpression(pipeline.value.schedule.expression)}
                        />
                        <i style={{ color: 'var(--fg-muted)' }}>
                          <small>
                            <CopyText
                              text={'Expression: ' + pipeline.value.schedule.expression.value}
                            />
                          </small>
                        </i>
                      </>
                    }
                  />
                  {pipeline.value.schedule.nextRunTime && (
                    <DescriptionDetails
                      term="Next Run"
                      value={<CopyText text={formatDate(pipeline.value.schedule.nextRunTime)} />}
                    />
                  )}
                </DescriptionList>

                {pipeline.value.latestRun &&
                  (() => {
                    switch (pipeline.value.latestRun.state) {
                      case "stopped":
                        return (
                          <DescriptionList>
                            <DescriptionDetails
                              term="Last Run"
                              value={
                                <NavLink to={`./runs/${pipeline.value.latestRun.id}`}>
                                  {pipeline.value.latestRun.name}
                                </NavLink>
                              }
                            />
                            <DescriptionDetails
                              term="Outcome"
                              value={
                                <PipelineRunOutcomeIcon
                                  outcome={pipeline.value.latestRun.outcome}
                                  size={16}
                                  showDescription
                                />
                              }
                            />
                          </DescriptionList>
                        );
                      case "running":
                        return (
                          <DescriptionList>
                            <DescriptionDetails
                              term="State"
                              value={
                                <NavLink to={`./runs/${pipeline.value.latestRun.id}`}>
                                  <PipelineRunStateIcon
                                    state={pipeline.value.latestRun.state}
                                    size={16}
                                    showDescription
                                  />
                                </NavLink>
                              }
                            />
                            <DescriptionDetails
                              term="Run Time"
                              value={
                                <Timer start={dayjs.utc(pipeline.value.latestRun.startTime)} />
                              }
                            />
                          </DescriptionList>
                        );
                    }
                  })()}
              </DescriptionListGroup>
            </div>
          }
        />

        <Card
          header="Properties"
          content={
            <div className="pui-card-content-insert">
              <PipelineProperties pipeline={pipeline} />
            </div>
          }
        />

        <Card
          header="Stats"
          content={
            <div className="pui-card-content-insert">
              <RunsPieChart runs={pipeline.value.runs} />
            </div>
          }
        />
      </>
    )
  );
};
