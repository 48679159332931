import { ReactElement } from 'react';
import { NavLinkProps, Outlet } from 'react-router-dom';
import './TabOutlet.css';

type TabOutletProps = {
  children: ReactElement<NavLinkProps> | Array<ReactElement<NavLinkProps>>;
}

export const TabOutlet = (props: TabOutletProps) => {
  return (
    <div className="pui-tab-group">
      <div className="pui-tab-group-header">
        {props.children}
      </div>
      <div className="pui-tab-group-content">
        <Outlet />
      </div>
    </div>
  );
};
