import { ReactElement } from "react";
import { DescriptionListProps } from "./DescriptionList";
import './DescriptionListGroup.css';

type DescriptionListGroupProps = {
  children: ReactElement<DescriptionListProps> | ReactElement<DescriptionListProps>[];
};

export const DescriptionListGroup = (props: DescriptionListGroupProps) => {
  const { children } = props;
  return (
    <div className="pui-description-list-group">
      {children}
    </div>
  );
}
