import { Input, Option, Select } from '@premcloud/ui';
import { ChangeEvent, useContext, useMemo } from 'react';
import { FieldContext } from '../FieldContext';

export const String = () => {
  const { name, label, secret, value, options, errors, onChange } = useContext(FieldContext);
  const selections = useMemo(() => options?.map(option => ({
    value: option.value,
    display: option.label,
    hint: option.description,
    disabled: option.enabled === false
  } as Option)), [options]);

  const select = () => {
    return (
      <Select
        value={value || ''}
        options={selections}
        placeholder={label || name}
        onChange={onChange}
        onBlur={() => onChange(value)}
        hasError={errors.length > 0}
      />
    );
  };

  const input = () => {
    return (
      <Input
        type={secret === true ? 'password' : 'text'}
        name={name}
        value={value || ''}
        placeholder={label || name}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value || null)
        }
        onBlur={() => onChange(value)}
        hasError={errors.length > 0}
      />
    );
  };

  return options
    ? select()
    : input();
};
