export const ExchangeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 48 48" 
            width="32" 
            height="32">
        <path fill="#039be5" d="M44,8.5v9.257c0,0.796-0.316,1.559-0.879,2.121L21.879,41.121C21.316,41.684,20.553,42,19.757,42 H10.5C9.119,42,8,40.881,8,39.5v-9.257c0-0.796,0.316-1.559,0.879-2.121L30.121,6.879C30.684,6.316,31.447,6,32.243,6H41.5 C42.881,6,44,7.119,44,8.5z"/>
        <path fill="#1976d2" d="M44,30.24v9.26c0,1.381-1.119,2.5-2.5,2.5h-9.26c-0.794,0-1.555-0.315-2.117-0.877L26,37v-8 c0-2.761,2.239-5,5-5h8l4.123,4.123C43.685,28.685,44,29.446,44,30.24z"/>
        <path fill="#4dd0e1" d="M8,17.76V8.5C8,7.119,9.119,6,10.5,6h9.26c0.794,0,1.555,0.315,2.117,0.877L26,11v8 c0,2.761-2.239,5-5,5h-8l-4.123-4.123C8.315,19.315,8,18.554,8,17.76z"/>
        <path fill="#1976d2" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"/>
        <path fill="#fff" d="M17.276,20.625h-4.317v2.524H17v1.618h-4.041v2.622h4.547V29H11V19h6.276L17.276,20.625z"/>
        </svg>
    )
}

