import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from '@premcloud/ui';
import { Spinner } from 'components';
import { isProblem, Problem } from 'data';
import { NewLicenseForm } from './NewLicenseForm';
import { LicenseService } from './Services';

type NewLicenseModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onAddClick: () => void;
}

export const NewLicenseModal = (props: NewLicenseModalProps) => {
  const { visible, setVisible, onAddClick } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState({});
  const [problem, setProblem] = useState<Problem>()

  const handleClick = async () => {
    try {
      setShowSpinner(true);
      var form = new FormData();
      for (let key in data) {
        form.append(key, data[key]);
      }
      await LicenseService.add(form);
      setVisible(false);
      onAddClick();
    } catch (err) {
      if (isProblem(err)) {
        setProblem(err);
        if (err.status > 499) {
          toast.error('Unable to add the license.');
          setVisible(false);
        }
      }
    } finally {
      setShowSpinner(false);
    }
  }

  return visible &&
    <Modal header="Add License" onClose={() => setVisible(false)}>
      <>
        <Spinner visible={showSpinner} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}>
          <NewLicenseForm problem={problem} onChange={setData} />
          <div style={{ marginLeft: 'auto' }}>
            <Button
              color="var(--primary)"
              disabled={!data}
              onClick={handleClick}>
              Add
            </Button>
          </div>
        </div>
      </>
    </Modal>
}
