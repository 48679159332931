import { Field, required } from '@premcloud/ui';

export const createField = <T>(name: string, value: T = undefined, validators = [required()]): Field<T> => {
  return {
    name,
    value,
    validators,
    errors: [],
    dirty: false,
    valid: !(value === undefined || value === null || value === '' || (value instanceof Array && value.length === 0))
  };
};
