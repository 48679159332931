import { Home, Key } from 'react-feather';
import { People, SettingsInputComponent } from '@mui/icons-material';
import { NavLink } from "react-router-dom"

export const ConnectNavBar = () => {
  const activeStyle = {
    color: 'var(--primary)'
  };
  return (
    <div id="links">
      <div className="link-group">
        <NavLink
          to="/home"
          style={({ isActive }) =>
            isActive ? activeStyle : {}
          }
          title="Dashboard">
          <Home />
        </NavLink>
        <NavLink
          to="/pipelines"
          style={({ isActive }) =>
            isActive ? activeStyle : {}
          }
          title="Pipelines">
          <SettingsInputComponent />
        </NavLink>
        <NavLink
          to="/users"
          style={({ isActive }) =>
            isActive ? activeStyle : {}
          }
          title="Manage users">
          <People />
        </NavLink>
        <NavLink
          to="/license/overview"
          style={({ isActive }) =>
            isActive ? activeStyle : {}
          }
          title="License Details">
          <Key />
        </NavLink>

        {/* <NavLink
              to="/userMap"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="User Map (temp)">
              <User  />
            </NavLink> */}

      </div>
    </div>
  )
};
