import { LoginPage } from "@premcloud/ui";
import { Route } from "react-router-dom";
import { LargeLogo } from "app/LargeLogo";
import { LoginForm } from "./LoginForm";
import { LOGIN_ROUTE_PATH } from "./Consts";

export const routes =
  <Route
    path={LOGIN_ROUTE_PATH}
    element={
      <LoginPage
        logo={<LargeLogo />}
        form={<LoginForm />}
      />
    }
  />;
