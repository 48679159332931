import { TabOutlet } from "components"
import { Breadcrumb, BreadcrumbList } from "@premcloud/ui";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom"
import { usePipeline } from "./PipelineContext";

export const PipelineOutlet = () => {
  const runsMatch = useMatch(useResolvedPath('./runs').pathname);
  const { pipeline } = usePipeline();

  return (
    <div className="page pipelines">
      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <NavLink to="/pipelines">Pipelines</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          {runsMatch
            ? <NavLink to={`/pipelines/${pipeline?.value.id}`}>{pipeline?.value.name}</NavLink>
            : <span>{pipeline?.value.name}</span>}
        </Breadcrumb>
        {runsMatch && <Breadcrumb><span>Runs</span></Breadcrumb>}
      </BreadcrumbList>

      <h1>{pipeline?.value.name}</h1>

      <TabOutlet>
        <NavLink to="" end>Details</NavLink>
        <NavLink to="./runs">Runs</NavLink>
      </TabOutlet>
    </div>
  );
};
