import {
  Pager,
  RefreshTool,
  Table,
  Toolbar,
  ExpandableTableRow,
  useSpinner,
  FilterTool,
  DownloadTool,
  TableRow,
  TableRowProps
} from 'components';
import { Chip, Row, Select, Option, Toolbar as PuiToolbar } from '@premcloud/ui';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PipelineRunLog, PipelineRunLogPage } from './Models';
import { usePipeline } from './PipelineContext';
import { PipelineService } from './Service';
import { formatDate } from 'utils';

export const PipelineRunLogList = () => {
  const { loadPipeline, loadPipelineRun } = usePipeline();
  const { pipelineId, runId } = useParams();
  const [logs, setLogs] = useState<PipelineRunLogPage>();
  const rows = useMemo(() => logs?.value.map(buildRow), [logs]);
  const showSpinner = useSpinner();
  const tools = useMemo(() => [RefreshTool(), FilterTool(), DownloadTool()], []);
  const [isFiltered, setFiltered] = useState(false);
  const [filteredLevels, setFilteredLevels] = useState<string[]>([]);
  const scrollElement = useRef<any>();

  const load = async () => {
    try {
      showSpinner(true);
      setLogs(await PipelineService.getLogs(pipelineId, runId, filteredLevels));
    } finally {
      showSpinner(false);
    }
  };

  const downloadLogs = async () => {
    try {
      showSpinner(true);
      await PipelineService.downloadLogs(pipelineId, runId);
    } finally {
      showSpinner(false);
    }
  };

  useEffect(() => {
    if (!isFiltered)
      setFilteredLevels((filteredLevels) => (filteredLevels.length === 0 ? filteredLevels : []));
  }, [isFiltered]);

  const handleToolClick = async (tool: string) => {
    switch (tool) {
      case 'refresh':
        return await load();
      case 'filter':
        setFiltered((isFiltered) => !isFiltered);
        break;
      case 'download':
        await downloadLogs();
        break;
      default:
        throw new Error(`${tool} not handled`);
    }
  };

  useEffect(() => {
    loadPipeline();
    loadPipelineRun();
    load();
  }, [pipelineId, runId, filteredLevels]);

  return (
    logs && (
      <>
        <Toolbar tools={tools} onToolClick={handleToolClick} />
        {isFiltered && <FilterBar setFilteredLevels={setFilteredLevels} />}
        <Pager
          page={logs}
          onSelect={async (nav) => setLogs(await PipelineService.getLogPage(nav.link))}
          style={{ flex: '1 1' }}
          scrollTarget={scrollElement}
        >
          <Table
            columns={[
              { id: 'time' },
              { id: 'service' },
              { id: 'level', size: 'small' },
              { id: 'message', size: 'large' },
              { id: 'exceptions' },
            ]}
            rows={rows}
            rowTemplate={forwardRef<HTMLTableRowElement, TableRowProps>((props, ref) => {
              if (props.row.exceptions['value'] === 0) {
                return <TableRow {...props} ref={ref} />;
              }
              return (
                <ExpandableTableRow
                  {...props}
                  ref={ref}
                  element={async (row) => {
                    return (
                      <pre>{row.exceptions['content'].map((e) => e['message']).join('\n')}</pre>
                    );
                  }}
                />
              );
            })}
            firstRowRef={scrollElement}
          />
        </Pager>
      </>
    )
  );
};

const buildRow = (log: PipelineRunLog): Row => {
  return {
    time: formatDate(log.timeStamp),
    service: log.service,
    level: log.level && {
      value: log.level,
      display: (() => {
        switch (log.level) {
          case 'Information':
            return <Chip color="var(--info)">Information</Chip>;
          case 'Warning':
            return <Chip color="var(--warning)">Warning</Chip>;
          case 'Error':
            return <Chip color="var(--danger)">Error</Chip>;
          default:
            return <Chip>{log.level}</Chip>;
        }
      })(),
    },
    message: log.message,
    exceptions: {
      value: log.exceptions.length,
      display: log.exceptions.length,
      content: log.exceptions,
    } as any,
  };
};

const logLevels: Option[] = [
  { value: 'Debug' },
  { value: 'Information' },
  { value: 'Warning' },
  { value: 'Error' },
  { value: 'Fatal' },
];

type FilterBarProps = {
  setFilteredLevels: (values: string[]) => void;
};

const FilterBar = (props: FilterBarProps) => {
  const { setFilteredLevels } = props;
  const [selection, setSelection] = useState([]);

  return (
    <PuiToolbar>
      <Select
        value={selection}
        placeholder="Select Log Levels"
        multi
        options={logLevels}
        onChange={setSelection}
        onBlur={() => setFilteredLevels(selection)}
      />
    </PuiToolbar>
  );
};
