import { Form, FormField, Option, Select, validate } from '@premcloud/ui';
import { Confirm, ConfirmResult, Spinner } from 'components';
import { createContext, useContext, useEffect, useState } from 'react';
import { createField, formatDate } from 'utils';
import { Pipeline } from './Models';
import { PipelineService } from './Service';

type ConfirmRunFunction = {
  (pipeline: Pipeline): Promise<ConfirmRunResult>;
};

const ConfirmRunContext = createContext<ConfirmRunFunction>(null);

type ResolveRunFunction = {
  (value: ConfirmRunResult): void;
};

export const historyRunInputConnectors = [
  // 'connect.inputs.teams',
  // 'connect.inputs.zoom',
  // 'connect.inputs.filesystem',
];


export type ConfirmRunResult = {
  result: ConfirmResult;
  runId: string;
};

export const ConfirmRunProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [pipeline, setPipeline] = useState<Pipeline>();
  const [resolve, setResolve] = useState<ResolveRunFunction>();
  const [runOptions, setRunOptions] = useState<Option[]>();
  const [run, setRun] = useState(createField('run', 'new'));
  const [spinner, setSpinner] = useState(false);

  const show = async (pipeline: Pipeline): Promise<ConfirmRunResult> => {
    setPipeline(pipeline);
    setVisible(true);
    return new Promise(resolve => {
      setResolve(() => resolve);
    });
  };

  const handleClose = (result: ConfirmResult) => {
    setVisible(false);
    resolve({ result, runId: run.value === 'new' ? '' : run.value });
  };

  useEffect(() => {
    async function load() {
      try {
        setSpinner(true);
        // TODO: This only going to get the first page.
        const allRuns = await PipelineService.getRuns(pipeline);
        const runOptions = [{ display: 'New Run', value: 'new' }, ...allRuns?.value.map(x => ({
          value: x.id,
          display: x.name,
          hint: 'Re-run the previous run with the start time ' + formatDate(x.startTime),
        }))];
        setRunOptions(runOptions);
        setRun({ ...run, value: 'new', valid: true });
      } finally {
        setSpinner(false);
      }
    }
    // Make sure we always reset the run options.
    setRunOptions([]);
    if (pipeline) {
      load();
    }
  }, [pipeline]);

  return (
    <ConfirmRunContext.Provider value={show}>
      {children}
      <Confirm
        visible={visible}
        title="Run Pipeline"
        yesButtonOptions={{ text: 'Run', disabled: !run.valid }}
        noButtonOptions={{ text: 'Cancel' }}
        body={
          <>
            {spinner && <Spinner visible={true} />}
            <Form>
              <FormField
                label="Run"
                hint="Choose a previous run time or start a new run."
                errors={run.errors}
                dirty={run.dirty}
                input={
                  <Select
                    value={run.value}
                    placeholder="Choose a previous run time or start a new run"
                    options={runOptions}
                    onChange={value =>
                      validate({
                        ...run,
                        value,
                      }, setRun)
                    }
                    onBlur={() => validate(run, setRun)}
                    hasError={run.errors.length > 0}
                  />
                }
              />
            </Form>
          </>
        }
        onClose={handleClose}
      />
    </ConfirmRunContext.Provider>
  );
};

export const useConfirmRun = (): ConfirmRunFunction => {
  return useContext(ConfirmRunContext);
};
