import { Breadcrumb, BreadcrumbList } from '@premcloud/ui';
import { useEffect } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Setup } from './setup';
import { usePipeline } from './PipelineContext';

export const PipelineEdit = () => {
  const { setupId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { pipeline, loadPipeline } = usePipeline();

  useEffect(() => {
    (async () => {
      if (!pipeline) {
        await loadPipeline();
      }
    })();
  }, [pipeline]);

  return (
    <div className="page pipeline-setup">
      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <NavLink to="/pipelines">Pipelines</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <NavLink to={`/pipelines/${pipeline?.value.id}`}>{pipeline?.value.name}</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <span>Setup</span>
        </Breadcrumb>
      </BreadcrumbList>

      <h1>{pipeline?.value.name} Setup</h1>

      <Setup setupId={setupId} onExit={() => navigate(state ?? '../')} />
    </div>
  );
}
