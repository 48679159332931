import { SpinnerProvider } from "components";
import { Outlet, Route } from "react-router-dom";
import { PipelineDetails } from "./PipelineDetails";
import { PipelineRunDetails } from "./PipelineRunDetails";
import { PipelineRunLogList } from "./PipelineRunLogList";
import { PipelineRunOutlet } from "./PipelineRunOutlet";
import { PipelineRunList } from "./PipelineRunList";
import { PipelineOutlet } from "./PipelineOutlet";
import { PipelineList } from "./PipelineList";
import { PipelineProvider } from "./PipelineContext";
import { PipelineNew } from "./PipelineNew";
import { ConfirmRunProvider } from "./ConfirmRunContext";
import "./Pipelines.css";
import { PipelineEdit } from "./PipelineEdit";

export const routes = (
  <Route
    path="/pipelines"
    element={
      <SpinnerProvider>
        <ConfirmRunProvider>
          <Outlet />
        </ConfirmRunProvider>
      </SpinnerProvider>
    }
  >
    <Route path="" element={<PipelineList />} />
    <Route path="setup/:setupId" element={<PipelineNew />} />
    <Route path=":pipelineId" element={
      <PipelineProvider>
        <Outlet />
      </PipelineProvider>
    }>
      <Route path="" element={<PipelineOutlet />}>
        <Route path="" element={<PipelineDetails />} />
        <Route path="runs" element={<PipelineRunList />} />
      </Route>
      <Route path="setup/:setupId" element={<PipelineEdit />} />
      <Route path="runs/:runId" element={<PipelineRunOutlet />}>
        <Route path="" element={<PipelineRunDetails />} />
        <Route path="logs" element={<PipelineRunLogList />} />
      </Route>
    </Route>
  </Route>
);
