import dayjs, { Dayjs, isDayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import durationPlugin from "dayjs/plugin/duration";
import localeDataPlugin from "dayjs/plugin/localeData";
import localizedFormatPlugin from "dayjs/plugin/localizedFormat";
import relativeTimePlugin from "dayjs/plugin/relativeTime";

const customDurationPlugin = (option: any, c: typeof Dayjs, d: typeof dayjs) => {
  durationPlugin(option, c, d);
  const old = d.duration;
  (d as any).duration = (input: any, unit: any) => {
    const d = old(input, unit) as any;
    d.$d.seconds = Math.round(d.$d.seconds);
    return d;
  };
};

dayjs.extend(utc);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(customDurationPlugin);
dayjs.extend(localeDataPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(relativeTimePlugin);

const formatDuration = (value: string | plugin.Duration) => {
  if (value) {
    if (typeof value === "string") {
      value = dayjs.duration(value);
    }

    if (value.days()) return value.format("D[d] H[h] m[m] s[s]");
    if (value.hours()) return value.format("H[h] m[m] s[s]");
    if (value.minutes()) return value.format("m[m] s[s]");
    return value.format("s[s]");
  }
};

const formatDate = (value: string | Dayjs) => {
  if (value) {
    if (!isDayjs(value)) {
      value = dayjs.utc(value);
    }
    return value.local().format(DATETIME_FORMAT);
  }
};

const DATETIME_FORMAT = "L LTS";

export { dayjs, Dayjs, isDayjs, formatDuration, formatDate, DATETIME_FORMAT };
