import { useEffect, useState } from 'react';
import {
  Edit as EditIcon,
  Lock as LockIcon,
  MoreVertical as MoreVerticalIcon,
  Trash2 as TrashIcon
} from 'react-feather'
import { toast } from 'react-toastify';
import { Breadcrumb, BreadcrumbList, Menu, Row } from '@premcloud/ui';
import { AddTool, PageNav, Pager, RefreshTool, RemoveTool, SelectTable, Toolbar, useConfirm, useSpinner } from 'components';
import { isProblem } from 'data';
import { UserFormModal, UserModalType } from './UserFormModal';
import { UserPage, UserService } from './UserService';
import { UserCollectionItem } from './Models';

export const UserList = () => {
  const [users, setUsers] = useState<UserPage>();
  const [userModalType, setUserModalType] = useState<UserModalType>();
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserCollectionItem>();
  const [rows, setRows] = useState<Row[]>([]);
  const [selection, setSelection] = useState<Row[]>([]);
  const showSpinner = useSpinner();
  const confirm = useConfirm();

  const handleMenuAction = (action: string, user: UserCollectionItem) => {
    setSelectedUser(user);
    switch (action) {
      case 'edit':
        setUserModalType('update');
        setUserModalVisible(true);
        break;
      case 'resetPassword':
        setUserModalType('reset');
        setUserModalVisible(true);
        break;
      case 'remove':
        return removeSingle(user);
    }
  };

  const handleToolClick = (tool: string) => {
    switch (tool) {
      case 'add':
        setSelectedUser(undefined);
        setUserModalType('create');
        setUserModalVisible(true);
        break;
      case 'refresh':
        return load();
      case 'remove':
        return removeBulk();
    }
  };

  const removeSingle = async (user: UserCollectionItem) => {
    if (user) {
      const result = await confirm(
        "Remove User",
        "Are you sure you want to remove this user?");

      if (result === 'yes') {
        try {
          await UserService.remove(user);
          setSelection(current => current.filter(row => row.self !== user.self.href));
          setRows(current => current.filter(row => row.self !== user.self.href));
        } catch (err) {
          if (isProblem(err)) {
            toast.error(err.detail || err.title);
          }
        }
      }
    }
  };

  const removeBulk = async () => {
    const result = await confirm(
      `Remove User${selection.length > 1 ? 's' : ''}`,
      `Are you sure you want to remove the selected user${selection.length > 1 ? 's' : ''}`);

    if (result === 'yes') {
      const errors = [];
      try {
        showSpinner(true);
        const deleted = [];
        for (const row of selection) {
          try {
            await UserService.remove({ href: row.self as string });
            deleted.push(row);
          } catch (err) {
            errors.push(err);
          }
        }
        setRows(current => current.filter(row => !deleted.includes(row)));
        setSelection([]);
      } finally {
        showSpinner(false);
        if (errors.length) {
          const message = (() => {
            if (isProblem(errors[0])) {
              return errors[0].detail || errors[0].title;
            }
            return 'One or more users were not removed.';
          })();
          toast.error(message);
        }
      }
    }
  };


  const buildRow = (user: UserCollectionItem): Row => {
    return {
      self: user.self.href,
      username: user.username,
      email: user.email,
      roles: user.roles.join(', '),
      actions: {
        value: '',
        display: <Menu
          trigger={<MoreVerticalIcon size={20} />}
          openPosition="bottomLeft"
          onSelect={value => handleMenuAction(value, user)}
          menuItems={[
            {
              value: 'edit',
              display: (
                <>
                  <EditIcon size={20} />
                  <span>Edit</span>
                </>
              )
            },
            {
              value: 'resetPassword',
              display: (
                <>
                  <LockIcon size={20} />
                  <span>Reset Password</span>
                </>
              )
            },
            {
              value: 'remove',
              display: (
                <>
                  <TrashIcon size={20} />
                  <span>Remove</span>
                </>
              )
            }
          ]}
        />
      }
    };
  };

  const load = async () => {
    try {
      showSpinner(true);
      const r = await UserService.list();
      setUsers(r);
      setRows(r.value.map(buildRow));
    } finally {
      showSpinner(false);
    }
  };

  const handlePageSelect = async (page: PageNav) => {
    try {
      showSpinner(true);
      const r = await UserService.listPage(page.link);
      setUsers(r);
      setRows(r.value.map(buildRow));
    } finally {
      showSpinner(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="page">
      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <span>Users</span>
        </Breadcrumb>
      </BreadcrumbList>

      <h1>Users</h1>

      <Toolbar
        tools={[RefreshTool(), RemoveTool({ disabled: !selection.length }), AddTool()]}
        onToolClick={handleToolClick} />

      {users &&
        <Pager
          page={users}
          onSelect={handlePageSelect}
          style={{ 'flex': '1 1' }}>
          <SelectTable
            defaultSortColumn="username"
            defaultSortOrder="asc"
            columns={[
              { id: 'username' },
              { id: 'email', size: 'large' },
              { id: 'roles' },
              { id: 'actions', size: 'kebab-menu', sort: false, display: ' ' }
            ]}
            rows={rows}
            selection={selection}
            onSelectionChange={setSelection}
          />
        </Pager>
      }

      <UserFormModal
        type={userModalType}
        visible={userModalVisible}
        setVisible={setUserModalVisible}
        user={selectedUser}
        onSuccess={load} />
    </div>
  )
}
