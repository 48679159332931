import { Button } from '@premcloud/ui';
import {
  Edit as EditIcon,
  Monitor as MonitorIcon,
  Plus as PlusIcon,
  Play as PlayIcon,
  RefreshCw as RefreshIcon,
  Settings as SettingsIcon,
  Trash2 as TrashIcon,
  ToggleLeft as ToggleLeftIcon,
  ToggleRight as ToggleRightIcon,
  Filter as FilterIcon,
  Download as DownloadIcon,
} from 'react-feather';

export type ToolProps = {
  onClick: (value: string) => void;
};

type IconProps = {
  size: string | number;
};

export type ButtonToolPropsOverrides = {
  name?: string;
  disabled?: boolean;
};

export type ButtonToolProps = {
  value: string;
  icon: React.ComponentType<IconProps>;
} & ButtonToolPropsOverrides &
  ToolProps;

export const ButtonTool = (props: ButtonToolProps) => {
  return (
    <Button
      size="small"
      color="transparent"
      textColor="var(--primary)"
      disabled={props.disabled}
      onClick={() => props.onClick(props.value)}
    >
      <props.icon size={20} />
      {props.name ?? props.value}
    </Button>
  );
};

export const Tool =
  (name: string, value: string, icon: React.ComponentType<IconProps>) => (inner: ButtonToolProps) =>
    <ButtonTool name={name} value={value} icon={icon} {...inner} />;
export const RefreshTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Refresh" value="refresh" icon={RefreshIcon} {...inner} {...props} />;
export const RemoveTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Remove" value="remove" icon={TrashIcon} {...inner} {...props} />;
export const AddTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Add" value="add" icon={PlusIcon} {...inner} {...props} />;
export const EnableTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Enable" value="enable" icon={ToggleRightIcon} {...inner} {...props} />;
export const DisableTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Disable" value="disable" icon={ToggleLeftIcon} {...inner} {...props} />;
export const RunTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Run" value="run" icon={PlayIcon} {...inner} {...props} />;
export const FullScreenTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Full Screen" value="fullscreen" icon={MonitorIcon} {...inner} {...props} />;
export const EditTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Edit" value="edit" icon={EditIcon} {...inner} {...props} />;
export const SetupTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Setup" value="setup" icon={SettingsIcon} {...inner} {...props} />;
export const FilterTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Filter" value="filter" icon={FilterIcon} {...inner} {...props} />;
export const DownloadTool = (props?: ButtonToolPropsOverrides) => (inner: ButtonToolProps) =>
  <ButtonTool name="Download" value="download" icon={DownloadIcon} {...inner} {...props} />;
