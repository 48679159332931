import { http, data, Link } from 'data';
import { SetupStepSubmitResultValue, SetupOverviewValue, SetupStepValue } from './Models';

export const SetupService = {
  getOverview: (setupId: string) => http.get<SetupOverviewValue>(`/api/channels/setup/${setupId}`).then(data),
  getStep: (link: Link) => http.get<SetupStepValue>(link.href).then(data),
  submitStep: (link: Link, value: any) => http.post<SetupStepSubmitResultValue>(link.href, value).then(data),
  finish: (link: Link) => http.post<void>(link.href).then(data),
  cancel: (link: Link) => http.delete<void>(link.href).then(data),
};
