export const GoogleIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.85 7.41H21.85L24.7 4.56L24.84 3.35C23.2073 1.90895 21.2343 0.907909 19.1072 0.441429C16.98 -0.0250506 14.7691 0.0584334 12.6833 0.683995C10.5974 1.30956 8.70542 2.45654 7.18608 4.01661C5.66674 5.57667 4.57018 7.49831 4 9.6C4.3175 9.46986 4.66921 9.44876 5 9.54L10.7 8.6C10.7 8.6 10.99 8.12 11.14 8.15C12.3613 6.80866 14.0492 5.9849 15.858 5.84733C17.6669 5.70976 19.4599 6.2688 20.87 7.41H20.85Z" fill="#EA4335"/>
            <path d="M28.76 9.6C28.1049 7.18763 26.7599 5.01894 24.89 3.36L20.89 7.36C21.7236 8.04116 22.3917 8.90274 22.8438 9.87974C23.296 10.8567 23.5203 11.9237 23.5 13V13.71C23.9675 13.71 24.4304 13.8021 24.8624 13.981C25.2943 14.1599 25.6867 14.4221 26.0173 14.7527C26.3479 15.0833 26.6101 15.4757 26.789 15.9076C26.9679 16.3396 27.06 16.8025 27.06 17.27C27.06 17.7375 26.9679 18.2004 26.789 18.6324C26.6101 19.0643 26.3479 19.4567 26.0173 19.7873C25.6867 20.1179 25.2943 20.3801 24.8624 20.559C24.4304 20.7379 23.9675 20.83 23.5 20.83H16.38L15.67 21.55V25.82L16.38 26.53H23.5C25.4882 26.5455 27.4287 25.9206 29.0342 24.7478C30.6398 23.575 31.8252 21.9165 32.4151 20.0177C33.005 18.119 32.9681 16.0807 32.3099 14.2046C31.6516 12.3284 30.407 10.7139 28.76 9.6V9.6Z" fill="#4285F4"/>
            <path d="M9.25 26.49H16.37V20.79H9.25C8.74274 20.7899 8.24142 20.6808 7.78 20.47L6.78 20.78L3.91 23.63L3.66 24.63C5.26944 25.8453 7.23328 26.4988 9.25 26.49V26.49Z" fill="#34A853"/>
            <path d="M9.25 8C7.32082 8.01152 5.44341 8.6253 3.87999 9.7556C2.31657 10.8859 1.14522 12.4763 0.529532 14.3046C-0.0861526 16.1329 -0.115422 18.1079 0.445813 19.9537C1.00705 21.7995 2.13076 23.4239 3.66 24.6L7.79 20.47C7.26516 20.2329 6.80569 19.872 6.451 19.4182C6.09631 18.9645 5.857 18.4315 5.75359 17.8649C5.65019 17.2984 5.68578 16.7152 5.85732 16.1654C6.02885 15.6156 6.33121 15.1157 6.73844 14.7084C7.14568 14.3012 7.64563 13.9989 8.19541 13.8273C8.74518 13.6558 9.32837 13.6202 9.89493 13.7236C10.4615 13.827 10.9945 14.0663 11.4482 14.421C11.902 14.7757 12.2629 15.2352 12.5 15.76L16.63 11.63C15.7631 10.4968 14.6459 9.5794 13.3656 8.94967C12.0853 8.31994 10.6768 7.99491 9.25 8V8Z" fill="#FBBC05"/>
        </svg>
    )
}


