import { createContext } from 'react';
import { SetupOverviewStep, SetupOverviewValue, SetupStepValue } from './Models';
import { Problem } from 'data';

type SetupContextValue = {
  overview: SetupOverviewValue;
  interactiveSteps: SetupOverviewStep[];
  currentStep: SetupStepValue;
  loading: boolean;
  problem: Problem;
  valid: boolean;
};
export const SetupContext = createContext<SetupContextValue>({} as any);
