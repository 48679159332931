import { useState } from "react";
import { Card, Table } from "@premcloud/ui";
import { CopyText, DescriptionDetails, DescriptionList, DescriptionListGroup } from 'components';
import { LicenseValue } from "./Models";
import { createValidityPeriodCell, formatDate } from './Utils';
import { LicenseStatusChip } from "./LicenseStatus";
import { GetIcon } from "app/svgLogos/GetIcon";
import './LicenseViewer.css';

type LicenseViewerProps = {
  license: LicenseValue;
};

export const LicenseViewer = (props: LicenseViewerProps) => {
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const rows = (props.license.value.entitlements || []).map(e => ({
    name: {
      value: e.name,
      display: <><GetIcon icon={e.code} /> {e.name}</>
    },
    validity: createValidityPeriodCell(e),
    status: {
      value: e.status,
      display: <LicenseStatusChip status={e.status} />
    }
  } as any));

  return (
    <div className="license-viewer">
      <Card header="Status" content={
        <div className="pui-card-content-insert">
          <LicenseStatusChip status={props.license.value.status} />
        </div>
      } />

      <Card header="Properties" content={
        <div className="pui-card-content-insert">
          <DescriptionListGroup>
            <DescriptionList title="Product">
              <DescriptionDetails term="Name" value={<CopyText text={props.license.value.product.name} />} />
              <DescriptionDetails term="Product ID" value={<CopyText text={props.license.value.product.id} />} />
            </DescriptionList>
            <DescriptionList title="Issued To">
              <DescriptionDetails term="Name" value={<CopyText text={props.license.value.licensee.name} />} />
              <DescriptionDetails term="Licensee ID" value={<CopyText text={props.license.value.licensee.id} />} />
            </DescriptionList>
            <DescriptionList title="Issued By">
              <DescriptionDetails term="Name" value={<CopyText text={props.license.value.licensor.name} />} />
              <DescriptionDetails term="Licensor ID" value={<CopyText text={props.license.value.licensor.id} />} />
              <DescriptionDetails term="Issued On" value={<CopyText text={formatDate(props.license.value.issued)} />} />
            </DescriptionList>
            {props.license.value.machine &&
              <DescriptionList title="Machine">
                <DescriptionDetails term="Fingerprint" value={<CopyText text={props.license.value.machine.fingerprint} />} />
                <DescriptionDetails term="Machine ID" value={<CopyText text={props.license.value.machine.id} />} />
              </DescriptionList>}
            <DescriptionList title="Validity Period">
              {!props.license.value.validFrom && !props.license.value.validTo && <i>Indefinite</i>}
              {props.license.value.validFrom &&
                <DescriptionDetails term="Valid from" value={<CopyText text={formatDate(props.license.value.validFrom)} />} />}
              {props.license.value.validTo &&
                <DescriptionDetails term="Valid until" value={<CopyText text={formatDate(props.license.value.validTo)} />} />}
            </DescriptionList>
          </DescriptionListGroup>
        </div>
      } />

      <Card header="Entitlements" content={
        <Table
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          columns={[
            { id: 'name', size: 'large' },
            { id: 'validityPeriod', display: 'Validity Period' },
            { id: 'status' }
          ]}
          rows={rows}
        />
      } />
    </div>
  );
};
