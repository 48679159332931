import dayjs, { isDayjs, Dayjs, utc } from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc';
import localeDataPlugin from 'dayjs/plugin/localeData';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import { ValidityPeriod } from "./Models";
import { Cell } from '@premcloud/ui';

dayjs.extend(utcPlugin);
dayjs.extend(localeDataPlugin);
dayjs.extend(localizedFormatPlugin);

export const formatDate = (time: string | Dayjs, format = 'L') => {
  if (time) {
    return (isDayjs(time) ? time : utc(time)).local().format(format);
  }
}

export const createValidityPeriodCell = (model?: ValidityPeriod): Cell => {
  const fromTime = model?.validFrom;
  const untilTime = model?.validTo;

  if (fromTime && !untilTime) {
    return {
      value: model.validFrom,
      display: <>From {formatDate(fromTime)}</>
    };
  } else if (!fromTime && untilTime) {
    return {
      value: model.validTo,
      display: <>Until {formatDate(untilTime)}</>
    };
  } else if (fromTime && untilTime) {
    return {
      value: model.validTo,
      display: <>{formatDate(fromTime)} - {formatDate(untilTime)}</>
    };
  }

  return {
    value: '-1',
    display: <i>Indefinite</i>
  };
}
