import { Fragment, useContext } from 'react';
import { Check } from 'react-feather';
import { SetupContext } from './SetupContext';

export const Stepper = () => {
  const { interactiveSteps, currentStep } = useContext(SetupContext);
  return (
    <div className="stepper">
      {interactiveSteps.map((step, i) => {
        return (
          <Fragment key={i}>
            <div className="step">
              {(() => {
                if (step.number < currentStep.number) {
                  return <div className="active"><Check /></div>;
                } else if (step.number === currentStep.number) {
                  return <div className="active">{i + 1}</div>;
                } else {
                  return <div>{i + 1}</div>;
                }
              })()}
              <label>{step.label}</label>
            </div>
            {i !== interactiveSteps.length - 1 && <div className="step-separator"></div>}
          </Fragment>
        )
      })}
    </div>
  );
};
