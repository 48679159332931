
export const DatalakeIcon = () => {
  return (
      <svg aria-hidden="true" role="presentation" data-slug-id="data-lake" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" width="32" height="32">
        <path d="M73.8667 115.799L9.13333 86.666C6.76152 85.5891 4.91179 83.618 3.9876 81.1826C3.06341 78.7473 3.13976 76.0453 4.19999 73.666L33.3333 9.13265C33.8648 7.91637 34.6363 6.81983 35.6015 5.90868C36.5667 4.99753 37.7058 4.29049 38.9506 3.82989C40.1955 3.3693 41.5205 3.16461 42.8463 3.22809C44.1721 3.29156 45.4715 3.6219 46.6667 4.19931L110.867 33.3326C112.083 33.8641 113.179 34.6356 114.091 35.6008C115.002 36.566 115.709 37.7051 116.169 38.95C116.63 40.1948 116.835 41.5198 116.771 42.8456C116.708 44.1714 116.377 45.4708 115.8 46.666L86.6667 110.866C85.5897 113.188 83.6526 115.001 81.2643 115.921C78.8759 116.842 76.2234 116.798 73.8667 115.799Z" fill="#50E6FF"></path>
        <path d="M95.3335 105.4H24.3335C21.7221 105.382 19.2226 104.337 17.376 102.49C15.5294 100.644 14.4843 98.1443 14.4668 95.5329V24.4663C14.4843 21.8549 15.5294 19.3554 17.376 17.5088C19.2226 15.6622 21.7221 14.6171 24.3335 14.5996H95.3335C97.9503 14.5996 100.46 15.6391 102.31 17.4895C104.161 19.3398 105.2 21.8495 105.2 24.4663V95.5329C105.2 98.1497 104.161 100.659 102.31 102.51C100.46 104.36 97.9503 105.4 95.3335 105.4Z" fill="url(#data-lake:a116d8d6-6f2d-4091-b0bf-e3b4f053bb39-d78e95a3)"></path>
        <path d="M58.1997 66.0003H47.4664C47.3673 66.0371 47.2603 66.0472 47.1561 66.0294C47.0519 66.0115 46.9543 65.9665 46.8731 65.8989C46.7919 65.8312 46.73 65.7433 46.6937 65.6441C46.6574 65.5448 46.648 65.4377 46.6664 65.3336L59.9997 36.6003C60.0941 36.558 60.1963 36.5361 60.2997 36.5361C60.4031 36.5361 60.5053 36.558 60.5997 36.6003H73.1997C73.3588 36.6003 73.5114 36.6635 73.624 36.776C73.7365 36.8886 73.7997 37.0412 73.7997 37.2003L58.3997 59.5336H73.333C73.4922 59.5336 73.6448 59.5969 73.7573 59.7094C73.8698 59.8219 73.933 59.9745 73.933 60.1336C73.9619 60.2654 73.9619 60.4019 73.933 60.5336L49.133 89.067C49.133 89.067 47.2664 90.7336 48.0664 88.4003L58.1997 66.0003Z" fill="#F2F2F2"></path>
        <defs>
          <linearGradient id="data-lake:a116d8d6-6f2d-4091-b0bf-e3b4f053bb39-d78e95a3" x1="59.8668" y1="105.4" x2="59.8668" y2="14.5996" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1988D9"></stop>
            <stop offset="0.9" stopColor="#54AEF0"></stop>
          </linearGradient>
        </defs>
      </svg>
  )
}

