export const BoxIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 48 48" 
            width="30px" 
            height="30px">
            <path fill="#0288D1" d="M25.639,17.927c-3.074,0-5.822,1.846-7.278,4.537c-1.293-2.691-4.042-4.537-7.278-4.537c-1.773,0-3.554,0.663-4.847,1.678v-7.567l0,0c0-0.845-0.813-1.69-1.618-1.69C3.649,10.347,3,11.021,3,12.037l0,0v14.47l0,0c0,4.538,3.716,8.415,8.083,8.415c3.074,0,5.822-1.861,7.278-4.553c1.456,2.691,4.042,4.553,7.278,4.553c4.529,0,8.083-3.877,8.083-8.415C33.722,21.787,30.168,17.927,25.639,17.927 M11.083,31.553c-2.749,0-4.847-2.353-4.847-5.046c0-2.858,2.261-5.058,4.847-5.058s4.854,2.367,4.854,5.058C16.1,29.2,13.832,31.553,11.083,31.553z M25.639,31.553c-2.749,0-4.854-2.353-4.854-5.046c0-2.858,2.269-5.058,4.854-5.058c2.749,0,4.847,2.367,4.847,5.058C30.485,29.2,28.225,31.553,25.639,31.553z"/><path fill="#0288D1" d="M44.785,32.353l-4.251-5.892l4.251-5.89l0,0c0.494-0.677,0.33-1.859-0.328-2.354c-0.649-0.505-1.793-0.337-2.287,0.324l0,0l-3.594,5.061l-3.602-5.061l0,0c-0.486-0.661-1.637-0.829-2.286-0.324c-0.658,0.495-0.822,1.678-0.329,2.354l0,0l4.252,5.89l-4.252,5.892l0,0c-0.493,0.66-0.329,1.845,0.329,2.352c0.649,0.508,1.8,0.34,2.286-0.338l0,0l3.602-5.044l3.594,5.044l0,0c0.494,0.678,1.638,0.846,2.287,0.338C45.115,34.197,45.279,33.013,44.785,32.353L44.785,32.353"/></svg>
    )
}

