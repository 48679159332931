import { TabOutlet } from "components";
import { Breadcrumb, BreadcrumbList } from "@premcloud/ui";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

export const LicenseOutlet = () => {
  const overviewMatch = useMatch(useResolvedPath('./overview').pathname);
  const licensesMatch = useMatch(useResolvedPath('./licenses').pathname);

  return (
    <div className="page license">
      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <NavLink to="/license/overview">License</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <>
            {overviewMatch && <span>Overview</span>}
            {licensesMatch && <span>Licenses</span>}
          </>
        </Breadcrumb>
      </BreadcrumbList>

      <h1>License</h1>

      <TabOutlet>
        <NavLink to="overview">Overview</NavLink>
        <NavLink to="licenses">Licenses</NavLink>
      </TabOutlet>
    </div>
  );
}
