import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@premcloud/ui';
import './Confirm.css';

export type ConfirmResult = 'yes' | 'no';

type ConfirmButtonOptions = {
  text?: string;
  disabled?: boolean;
}

type ConfirmProps = {
  title: string;
  body: string | React.ReactElement;
  visible: boolean;
  onClose: (result: ConfirmResult) => void;
  noButtonOptions?: ConfirmButtonOptions;
  yesButtonOptions?: ConfirmButtonOptions;
};

export const Confirm = (props: ConfirmProps) => {
  const { title, body, visible, noButtonOptions, yesButtonOptions, onClose } = props;
  const [show, setShow] = useState(visible);

  const handleResult = (result: 'yes' | 'no') => {
    setShow(false);
    onClose(result);
  };

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  return show && (
    <Modal header={title} onClose={() => handleResult('no')}>
      <div className="confirm-model-content">
        {body}
        <div className="confirm-modal-buttons">
          <Button
            color="var(--primary)"
            outline
            disabled={noButtonOptions?.disabled ?? false}
            onClick={() => handleResult('no')}>
            {noButtonOptions?.text ?? 'No'}
          </Button>
          <Button
            color="var(--primary)"
            disabled={yesButtonOptions?.disabled ?? false}
            onClick={() => handleResult('yes')}>
            {yesButtonOptions?.text ?? 'Yes'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
