import { useSpinner } from 'components';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PipelineRunValue, PipelineValue } from './Models';
import { PipelineService } from './Service';

type PipelineContextValue = {
  pipeline?: PipelineValue;
  pipelineRun?: PipelineRunValue;
  loadPipeline: (force?: boolean) => Promise<void>;
  loadPipelineRun: (force?: boolean) => Promise<void>;
};

const PipelineContext = createContext<PipelineContextValue>(null);

export const PipelineProvider = ({ children }) => {
  const [pipeline, setPipeline] = useState<PipelineValue>();
  const [pipelineRun, setPipelineRun] = useState<PipelineRunValue>();
  const { pipelineId, runId } = useParams();
  const showSpinner = useSpinner();

  const loadPipeline = async (force?: boolean) => {
    if (pipelineId && (force || !pipeline)) {
      try {
        showSpinner(true);
        setPipeline(await PipelineService.getPipeline(pipelineId));
      } finally {
        showSpinner(false);
      }
    }
  };

  const loadPipelineRun = async (force?: boolean) => {
    if (pipelineId && runId && (force || !pipelineRun)) {
      try {
        showSpinner(true);
        setPipelineRun(await PipelineService.getRun(pipelineId, runId));
      } finally {
        showSpinner(false);
      }
    }
  };

  return (
    <PipelineContext.Provider value={{ pipeline, pipelineRun, loadPipeline, loadPipelineRun }}>
      {children}
    </PipelineContext.Provider>
  )
};

export const usePipeline = (): PipelineContextValue => {
  return useContext(PipelineContext);
};
