import { NavigateFunction } from 'react-router-dom';
import { http, isAxiosError } from 'data';
import { Service } from './Service';
import { LOGIN_ROUTE_PATH } from './Consts';

export const addInterceptors = (navigate: NavigateFunction) => {
  return [
      http.interceptors.request.use(
        config => {
          const token = Service.getToken();
          if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
          }
          return config
        },
        error => Promise.reject(error)
      ),
      http.interceptors.response.use(
        response => response,
        error => {
          if (isAxiosError(error)) {
            const { status } = error.response;
            if (status === 401) {
              navigate(LOGIN_ROUTE_PATH);
            }
          }
          return Promise.reject(error);
        }
      )];
};

export const removeInterceptors = (interceptors: number[]) => {
  if (interceptors) {
    http.interceptors.request.eject(interceptors[0]);
    http.interceptors.response.eject(interceptors[1]);
  }
}
