import { useMemo } from 'react';
import { Row } from '@premcloud/ui';
import { SelectTable } from 'components';
import { License, LicensePage } from './Models';
import { NavLink } from 'react-router-dom';
import { createValidityPeriodCell, formatDate } from './Utils';
import { LicenseStatusIcon } from './LicenseStatus';

type LicenseListTableProps = {
  licenses: LicensePage;
  selection: License[];
  onSelectionChange: (licenses: License[]) => void;
}

export const LicenseListTable = (props: LicenseListTableProps) => {
  const { licenses, selection, onSelectionChange } = props;
  const rows = useMemo(() => (licenses && licenses.value.map(buildRow)) || [], [licenses]);
  const rowSelection = useMemo(() => rows.filter(r => selection.find(l => l.id === r._id)), [rows, selection]);

  const handleSelection = (selection: Row[]) => {
    // TODO: this may be slow!
    onSelectionChange(selection.map(r => licenses.value.find(l => l.id === r._id)));
  };

  return (
    licenses &&
    <SelectTable
      defaultSortColumn="issued"
      defaultSortOrder="asc"
      columns={[
        { id: 'id', size: 'large' },
        { id: 'issued' },
        { id: 'entitlementCount', display: 'No. of Entitlements' },
        { id: 'validityPeriod', display: 'Validity Period' },
        { id: 'status' }
      ]}
      rows={rows}
      selection={rowSelection}
      onSelectionChange={handleSelection}
    />
  );
};

const buildRow = (license: License): Row => {
  return {
    _id: license.id,
    id: {
      value: license.id,
      display:
        <NavLink to={`../licenses/${license.id}`}>
          {license.id}
        </NavLink>
    },
    issued: {
      value: license.issued,
      display: <>{formatDate(license.issued, 'L LT')}</>
    },
    entitlementCount: license.entitlements.length,
    validityPeriod: createValidityPeriodCell(license),
    status: {
      value: license.status.code,
      display: <LicenseStatusIcon status={license.status} />
    }
  };
};
