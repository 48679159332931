import { Input } from '@premcloud/ui';
import { ChangeEvent, useContext, useMemo } from 'react';
import { FieldContext } from '../FieldContext';
import { dayjs } from 'utils';

export const Time = () => {
  const { name, label, value, errors, onChange } = useContext(FieldContext);
  const time = useMemo(() => value && dayjs.duration(value), [value]);

  return (
    <Input
      type="time"
      step="1"
      name={name}
      value={time?.format('HH:mm:ss') ?? ''}
      placeholder={label || name}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const [hours, minutes, seconds] = event.target.value.split(':');
        const formatted = event.target.value && dayjs.duration({ hours: +hours, minutes: +minutes, seconds: +seconds }).toISOString();
        onChange(formatted || null);
      }}
      onBlur={() => onChange(value)}
      hasError={errors.length > 0}
    />
  );
};
