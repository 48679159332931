import { Column, Row, Table as PuiTable } from '@premcloud/ui';
import { ComponentType, CSSProperties, forwardRef, MutableRefObject, useState } from 'react';
import './Table.css';

export declare type TableColumnsProps = {
  columns: Column[];
  sortField?: string;
  sortOrder?: string;
  selectedStyle: CSSProperties;
  onColumnClick: (column: string) => void;
};

export type TableRowProps = {
  row: Row;
  columns: Column[];
};

export type TableProps = {
  color?: string;
  columns?: Column[];
  rows?: Row[];
  defaultSortColumn?: string;
  defaultSortOrder?: string;
  columnsTemplate?: ComponentType<TableColumnsProps>;
  rowTemplate?: ComponentType<TableRowProps>;
  firstRowRef?: MutableRefObject<HTMLTableRowElement>;
};

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>((props, ref) => {
  const { row, columns } = props;

  const getClass = (id: string): string => {
    const column = columns.find(c => c.id === id);
    return column?.size || '';
  };

  return (
    <tr ref={ref}>
      {columns.map((column, i) => (
        <td key={i} className={getClass(column.id)}>
          {typeof row[column.id] === 'object'
            ? row[column.id]['display'] || row[column.id]['value']
            : row[column.id]}
        </td>
      ))}
    </tr>
  );
});

export const Table = (props: TableProps) => {
  const {
    color,
    columns,
    rows,
    defaultSortColumn,
    defaultSortOrder = 'asc',
    columnsTemplate,
    rowTemplate: RowTemplate = TableRow,
    firstRowRef
  } = props;
  const [sortField, setSortField] = useState(defaultSortColumn);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  return (
    <PuiTable
      color={color}
      sortField={sortField}
      sortOrder={sortOrder}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      columns={columns}
      rows={rows}
      columnsTemplate={columnsTemplate}
      rowTemplate={props => <RowTemplate {...props} ref={props.row === rows[0] ? firstRowRef : undefined} />}
    />
  );
};
