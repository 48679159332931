export const IManageIcon = () => {
    return (
        <svg 
            width="32" 
            height="32" 
            viewBox="0 0 136 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M75.6148 2.1139C68.6858 1.01108 61.6045 1.33435 54.8048 3.0639C88.5948 11.5939 124.125 36.6339 129.565 60.9439C131.572 58.2822 133.251 55.3879 134.565 52.3239C142.625 31.4139 95.2948 5.1139 75.6148 2.1139Z" fill="#6778F2"/>
            <path d="M15.4148 40.5939C23.0048 22.0039 36.9448 7.67389 54.8148 3.06389C39.7548 -0.746105 25.0048 -1.26611 13.4848 3.00389C-10.1352 12.7039 1.84485 65.6539 14.8448 84.2439C35.1848 113.334 74.1548 112.244 103.935 98.4839C126.065 88.3039 132.665 74.7239 129.575 60.9239C98.6649 102.274 -12.1052 108.244 15.4148 40.5939Z" fill="#92DEFF"/>
            <path d="M54.8148 3.06389C36.9448 7.67389 23.0048 22.0039 15.4148 40.5939C-12.1052 108.244 98.6648 102.284 129.575 60.9439C124.135 36.6239 88.5948 11.5939 54.8148 3.06389Z" fill="#3B69F2"/>
            <path d="M82.4348 35.1139C80.4157 35.1129 78.4186 35.5331 76.5711 36.3475C74.7236 37.162 73.0662 38.3528 71.7048 39.8439C69.7271 37.6767 67.14 36.1586 64.2836 35.4889C61.4271 34.8193 58.4348 35.0294 55.7 36.0917C52.9652 37.1541 50.6157 39.0189 48.9603 41.4412C47.3049 43.8635 46.421 46.73 46.4248 49.6639V67.5139H54.0748V49.6639C54.0748 47.8339 54.8018 46.0789 56.0958 44.7849C57.3898 43.4909 59.1448 42.7639 60.9748 42.7639C62.8048 42.7639 64.5599 43.4909 65.8539 44.7849C67.1479 46.0789 67.8748 47.8339 67.8748 49.6639V67.5139H75.5248V49.6639C75.5248 47.8339 76.2518 46.0789 77.5458 44.7849C78.8398 43.4909 80.5948 42.7639 82.4248 42.7639C84.2548 42.7639 86.0099 43.4909 87.3039 44.7849C88.5979 46.0789 89.3248 47.8339 89.3248 49.6639V67.5139H97.0048V49.6639C96.9995 45.8032 95.4621 42.1024 92.7303 39.3743C89.9985 36.6462 86.2956 35.1139 82.4348 35.1139Z" fill="white"/>
            <path d="M43.0048 24.2139C44.0902 24.208 45.153 24.5239 46.0588 25.1218C46.9647 25.7197 47.673 26.5727 48.0943 27.573C48.5156 28.5733 48.6309 29.676 48.4257 30.7418C48.2204 31.8076 47.7039 32.7887 46.9413 33.561C46.1788 34.3334 45.2044 34.8624 44.1412 35.0811C43.0781 35.2999 41.974 35.1986 40.9685 34.7901C39.9629 34.3817 39.1009 33.6843 38.4916 32.7861C37.8822 31.8879 37.5527 30.8292 37.5448 29.7439C37.5368 28.2869 38.1073 26.8863 39.131 25.8495C40.1546 24.8127 41.5479 24.2245 43.0048 24.2139Z" fill="white"/>
        </svg>
    )
}

