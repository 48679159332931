import { http, data, formDataHeaders, nothing } from 'data';
import { LicensePage, LicenseValue, SystemInfoValue } from './Models';

export const LicenseService = {
  getOverview: () => http.get<LicenseValue>('/api/licenses/overview').then(data),
  get: (id: string) => http.get<LicenseValue>(`/api/licenses/${id}`).then(data),
  getAll: () => http.get<LicensePage>('/api/licenses').then(data),
  getAllPage: (url: string) => http.get<LicensePage>(url).then(data),
  add: (data: FormData) => http.post('/api/licenses', data, formDataHeaders).then(nothing),
  remove: (id: string) => http.delete(`/api/licenses/${id}`).then(nothing)
};

export const SystemInfoService = {
  get: () => http.get<SystemInfoValue>('/api/system').then(data)
};
