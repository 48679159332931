import { Outlet, Route } from 'react-router-dom';
import { SpinnerProvider } from 'components';
import { LicenseDetails } from './LicenseDetails';
import { LicenseOverview } from './LicenseOverview';
import { LicenseList } from './LicenseList';
import { LicenseOutlet } from './LicenseOutlet';
import './License.css';

export const routes =
  <Route path="/license" element={
      <SpinnerProvider>
        <Outlet />
      </SpinnerProvider>
    }>
    <Route element={<LicenseOutlet />}>
      <Route path="overview" element={<LicenseOverview />} />
      <Route path="licenses" element={<LicenseList />} />
    </Route>
    <Route path="licenses/:licenseId" element={<LicenseDetails />} />
  </Route>;
