import { AxiosRequestConfig } from 'axios';
import { http, data, Link, isLink, nothing } from 'data';
import {
  Pipeline,
  PipelineCollection,
  PipelineRunLogPage,
  PipelineRunPage,
  PipelineRunValue,
  PipelineValue,
} from './Models';
import { SetupOverviewValue } from './setup/Models';

const getLink = (pipeline: Pipeline | Link, key: string): Link => {
  return (isLink(pipeline) && pipeline) || (pipeline[key] && pipeline[key]);
};

const getConfig = (link: Link): AxiosRequestConfig => {
  return {
    url: link.href,
    method: link.method || 'GET',
  };
};

export const PipelineService = {
  list: () => http.get<PipelineCollection>('/api/channels').then(data),
  getPipeline: (id: string) => http.get<PipelineValue>(`/api/channels/${id}`).then(data),
  editPipeline: (pipeline: Pipeline | Link) =>
    http.post<SetupOverviewValue>(getLink(pipeline, 'setup').href).then(data),
  removePipeline: (pipeline: Pipeline | Link) =>
    http(getConfig(getLink(pipeline, 'delete'))).then(nothing),
  enablePipeline: (pipeline: Pipeline | Link) =>
    http(getConfig(getLink(pipeline, 'enable'))).then(nothing),
  disablePipeline: (pipeline: Pipeline | Link) =>
    http(getConfig(getLink(pipeline, 'disable'))).then(nothing),
  getRuns: (pipeline: Pipeline | Link) =>
    http.request<PipelineRunPage>(getConfig(getLink(pipeline, 'runs'))).then(data),
  getRun: (pipelineId: string, runId: string) =>
    http.get<PipelineRunValue>(`/api/channels/${pipelineId}/runs/${runId}`).then(data),
  createRun: (pipeline: Pipeline, runId: string = '') =>
    http.post(pipeline.runs.href + `/${runId}`).then(nothing),
  getLogs: (pipelineId: string, runId: string, filteredLevels: string[]) => {
    if (filteredLevels.length === 0) {
      return http
        .get<PipelineRunLogPage>(`/api/channels/${pipelineId}/runs/${runId}/logs`)
        .then(data);
    }
    let query = `/api/channels/${pipelineId}/runs/${runId}/logs?`;
    let queryString = filteredLevels.map((x) => `LogLevels=${x}`).join('&');
    query = query + queryString;
    return http.get<PipelineRunLogPage>(query).then(data);
  },
  downloadLogs: (pipelineId: string, runId: string) =>
    http
      .get(`/api/channels/${pipelineId}/runs/${runId}/download/logs`, { responseType: 'blob' })
      .then((response) => {
        const filename = `${runId}.log`;
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error(error);
      }),
  getLogPage: (link: Link) => http.get<PipelineRunLogPage>(link.href).then(data),
};
