import { Option, Select } from '@premcloud/ui';
import { useContext, useMemo } from 'react';
import { FieldContext } from '../FieldContext';

export const Array = () => {
  const { name, label, value, options, errors, onChange } = useContext(FieldContext);
  const selections = useMemo(() => options?.map(option => ({
    value: option.value,
    display: option.label,
    hint: option.description,
    disabled: option.enabled === false
  } as Option)), [options]);

  return (
    <Select
      value={value || ''}
      multi
      options={selections}
      placeholder={label || name}
      onChange={onChange}
      onBlur={() => onChange(value)}
      hasError={errors.length > 0}
    />
  );
};
