import { useEffect, useRef } from 'react';

type ValidationErrorsProps = {
  errors: string[];
};

export const ValidationErrors = (props: ValidationErrorsProps) => {
  const { errors } = props;
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [errors]);

  return errors && errors.length > 0 &&
    <ul ref={ref} className="validation-errors">
      {errors.map((error, i) => (<li key={i}>{error}</li>))}
    </ul>
};
