import { ChangeEvent, useMemo, useState } from 'react';
import { LogIn as LogInIcon } from 'react-feather';
import { toast } from 'react-toastify';
import { Button, Form, Field, FormField, Input, required, validate } from '@premcloud/ui';
import { feedbackProblem, isProblem } from 'data';
import { useAuth } from './Hook';

export const LoginForm = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState<Field<string>>({
    name: 'username',
    value: '',
    validators: [required()],
    errors: [],
    dirty: false,
    valid: false
  });
  const [password, setPassword] = useState<Field<string>>({
    name: 'password',
    value: '',
    validators: [required()],
    errors: [],
    dirty: false,
    valid: false
  });
  const valid = useMemo(() => username.valid && password.valid, [username, password]);

  const handleClick = async (event) => {
    event.preventDefault();
    if (valid) {
      try {
        await login({
          username: username.value,
          password: password.value
        });
      } catch (err) {
        if (isProblem(err)) {
          toast.error(feedbackProblem({
            username: setUsername,
            password: setPassword
          }, err).join(' '));
        }
      }
    }
  }

  return (
    <>
      <Form>
        <FormField
          label="username"
          errors={username.errors}
          dirty={username.dirty}
          input={
            <Input
              name="username"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                validate({
                  ...username,
                  value: event.target.value,
                }, setUsername)
              }
              onBlur={() => validate(username, setUsername)}
              hasError={username.errors.length > 0}
            />
          }
        />
        <FormField
          label="password"
          errors={password.errors}
          dirty={password.dirty}
          input={
            <Input
              name="password"
              type="password"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                validate({
                  ...password,
                  value: event.target.value,
                }, setPassword)
              }
              onBlur={() => validate(password, setPassword)}
              hasError={password.errors.length > 0}
            />
          }
        />
        <Button
          onClick={handleClick}
          type="submit"
          disabled={!valid}
          size="large"
          color="var(--primary)"
          textColor="var(--primary-fg)">
          <LogInIcon />
          <span>Login</span>
        </Button>
      </Form>
    </>
  );
};
