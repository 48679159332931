import { useEffect, useState } from 'react';
import { AddTool, RefreshTool, RemoveTool, Toolbar, useConfirm, useSpinner } from 'components';
import { LicenseListTable } from './LicenseListTable';
import { License, LicensePage } from './Models';
import { NewLicenseModal } from './NewLicenseModal';
import { LicenseService } from './Services';
import { useSearchParams } from 'react-router-dom';

export const LicenseList = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<LicensePage>();
  const [selection, setSelection] = useState<License[]>([]);
  const [openAddLicenseModel, setOpenAddLicenseModel] = useState(searchParams.has('add'));
  const showSpinner = useSpinner();
  const confirm = useConfirm();

  const load = async () => {
    try {
      showSpinner(true);
      setPage(await LicenseService.getAll());
    } catch (err) {
      // TODO
    } finally {
      showSpinner(false);
    }
  };

  const remove = async () => {
    const result = await confirm(
      `Remove License${selection.length > 1 ? 's' : ''}`,
      `Are you sure you want to remove the selected license${selection.length > 1 ? 's' : ''}`);

    if (result === 'yes') {
      try {
        await Promise.all(selection.map(license => LicenseService.remove(license.id)));
      } catch (err) {
        // TODO:
      }
      await load();
      setSelection([]);
    }
  }

  const handleToolClick = (value: string) => {
    switch (value) {
      case 'add': setOpenAddLicenseModel(true); break;
      case 'refresh': return load();
      case 'remove': return remove();
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Toolbar tools={[RefreshTool(), RemoveTool({ disabled: !selection.length }), AddTool()]} onToolClick={handleToolClick} />
      <LicenseListTable
        licenses={page}
        selection={selection}
        onSelectionChange={setSelection}
      />
      <NewLicenseModal
        visible={openAddLicenseModel}
        setVisible={setOpenAddLicenseModel}
        onAddClick={load}
      />
    </>
  );
}
