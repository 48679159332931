import { useEffect, useState } from "react";
import { GetIcon } from "../svgLogos/GetIcon";
import "./Dashboard.css";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Modal } from "@premcloud/ui";
import { PendingModal } from "./PendingModal";
import { CompleteModal } from "./CompleteModal";
import { RunningModal } from "./RunningModal";
import { ChannelService } from "../Services/ChannelService";
import { DisableTool, EnableTool, FullScreenTool, RemoveTool, RunTool, SetupTool } from "components";
import { getSelf } from "data";
import { ConnectorCombination } from "features/pipelines/ConnectorIcon";
import { formatDate } from "utils";
import { describeCronExpression } from "features/pipelines/Utils";
import { ConfirmRunProvider } from "features/pipelines/ConfirmRunContext";
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement);

export const Dashboard = () => {
  const [running, setRunning] = useState<any[]>();
  const [pending, setPending] = useState<any[]>();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [presentPendingDialog, setPresentPendingDialog] = useState(false);
  const [presentRunningDialog, setPresentRunningDialog] = useState(false);
  const [presentCompleteDialog, setPresentCompleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  function getIcon(id: string) {
    return id.replace("inputs.", "").replace("outputs.", "");
  }

  const onModalClick = (item) => {
    // async function load() {
    //   const options = [];
    //   // var parser = require('cron-parser');
    //   var r = await ChannelService.getDetails(getSelf(item).href);
    //   var channel = r.value;
    //   channel.href = r.self.href;
    //   channel.inputIcon = getIcon(channel.input.id);
    //   channel.outputIcon = getIcon(channel.output.id);
    //   // var schedule = channel.schedule;
    //   // schedule = schedule.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');
    //   // var interval = parser.parseExpression(schedule);
    //   channel.runSchedule = describeCronExpression(channel.schedule.expression);
    //   if (channel.delete) options.push(RemoveTool());
    //   if (channel.enable) options.push(EnableTool());
    //   if (channel.disable) options.push(DisableTool());
    //   if (channel.run) options.push(RunTool());
    //   if (channel.setup) options.push(SetupTool());
    //   if (channel.runs) {
    //     //                options.push({name: 'Runs', href: channel.runs.href, method: channel.runs.method, icon: 'Runs', type: 'Icon' });
    //     channel.runshref = channel.runs.href;
    //   }

    //   // if (channel.setup) options.push({ name: 'Configuration', href: channel.setup.href, method: channel.setup.method, icon: 'Configuration', type: 'Icon' });

    //   options.push(FullScreenTool());
    //   channel.options = options;

    //   setSelectedItem(channel);
    //   setPresentPendingDialog(true);
    //   setPresentRunningDialog(false);
    //   setPresentCompleteDialog(false);
    //   setShowModal(true);
    // }
    // load();

    navigate(`/pipelines/${item.id}`);
  };

  useEffect(() => {
    async function load() {
      var pendingChannels = [];
      var runningChannels = [];
      var channels = await ChannelService.getAll();
      // console.log('Channels :- ', channels);
      // var parser = require('cron-parser');
      channels.value.forEach((channel) => {
        channel.inputIcon = getIcon(channel.input.id);
        channel.outputIcon = getIcon(channel.output.id);
        // var schedule = channel.schedule;
        // schedule = schedule.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');
        // var interval = parser.parseExpression(schedule);
        if (channel.schedule.nextRunTime) {
          channel.runSchedule = formatDate(channel.schedule.nextRunTime);
        }

        if (!channel.enabled) {
          pendingChannels.push(channel);
        } else {
          runningChannels.push(channel);
        }
      });
      setPending(pendingChannels);
      setRunning(runningChannels);
    }
    load();
  }, [showModal]);

  return (
    <div id="new-channel" className="page">
      <h1 className="title">Dashboard</h1>
      <div className="dashboardContainer">
        <div className="SwimLanes">
          <div className="Lane1Title">Disabled Pipelines</div>
          <div className="Lane2Title">Enabled Pipelines</div>
          <div className="Lane1">
            {pending ? (
              <div>
                {pending.map((item, i) => {
                  return (
                    <div
                      className="ErrorFree"
                      key={i}
                      onClick={() => {
                        onModalClick(item);
                      }}
                    >
                      <div className="dashCol1">
                        <label>{item.name}</label>
                      </div>
                      <div className="dashCol1">
                        <ConnectorCombination
                          input={item.input}
                          output={item.output}
                          size="large"
                        />
                      </div>
                      <div className="dashColSpan">
                        <GetIcon icon="Calendar" /> Next Scheduled Run :- {item.runSchedule}
                      </div>
                      {item.metadata.map((details, i) => {
                        return (
                          <div className="metaData" key={i + 1000}>
                            <div className="dashCol1">
                              <label>{details.label}</label>
                            </div>
                            <div className="dashCol2">
                              <input readOnly disabled type="text" value={details.value} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="Lane2">
            {running ? (
              <div>
                {running.map((item, i) => {
                  return (
                    <div
                      className="ErrorFree"
                      key={i}
                      onClick={() => {
                        onModalClick(item);
                      }}
                    >
                      <div className="dashCol1">
                        <label>{item.name}</label>
                      </div>
                      <div className="dashCol1">
                        <ConnectorCombination
                          input={item.input}
                          output={item.output}
                          size="large"
                        />
                      </div>
                      <div className="dashColSpan">
                        <GetIcon icon="Calendar" /> Next Scheduled Run :- {item.runSchedule}
                      </div>
                      {item.metadata.map((details, i) => {
                        return (
                          <div className="metaData" key={i + 1000}>
                            <div className="dashCol1">
                              <label>{details.label}</label>
                            </div>
                            <div className="dashCol2">
                              <input readOnly disabled type="text" value={details.value} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {showModal && (
          <div className="modal">
            <Modal
              header="Pipeline Details"
              onClose={() => {
                setShowModal(false);
                setPresentCompleteDialog(false);
                setPresentPendingDialog(false);
                setPresentRunningDialog(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  height: "100%",
                }}
              >
                {presentPendingDialog && (
                  <ConfirmRunProvider>
                    <PendingModal
                      props={selectedItem}
                      setPresentPendingDialog={setPresentPendingDialog}
                      setShowModal={setShowModal}
                    />
                  </ConfirmRunProvider>
                )}
                {presentRunningDialog && <RunningModal props={selectedItem} />}
                {presentCompleteDialog && <CompleteModal props={selectedItem} />}
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};
