export const SharepointIcon = (props: { viewBox?: string, size?: number }) => {
  const { viewBox = '0 0 48 48', size = 32 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size + 'px'}
      height={size + 'px'}>
      <path fill="#13504f" d="M23 4A12 12 0 1 0 23 28A12 12 0 1 0 23 4Z" />
      <path fill="#13a3a9" d="M33.5 16A10.5 10.5 0 1 0 33.5 37A10.5 10.5 0 1 0 33.5 16Z" />
      <path fill="#1ddaea" d="M24 28A8 8 0 1 0 24 44A8 8 0 1 0 24 28Z" />
      <path fill="#027178" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z" /><path fill="#fff" d="M10.432,28.75v-2.298c0.426,0.349,2.391,1.048,2.9,1.048c0.299,0,2.138,0.088,2.138-1.041 c0-1.633-5.082-1.494-5.082-4.725c0-0.536,0.066-3.059,4.133-3.059c1.041,0,2.271,0.261,2.628,0.395v2.147 c-0.176-0.12-0.89-0.718-2.496-0.718c-1.877,0-2.04,0.883-2.04,1.041c0,1.359,4.998,1.544,4.998,4.711 c0,3.172-3.614,3.074-4.177,3.074C12.857,29.325,10.814,28.942,10.432,28.75z" />
    </svg>
  )
};
