import { CheckCircle as Check, XCircle as Cross } from 'react-feather';
import { Chip } from "@premcloud/ui";
import { VerificationStatus } from "./Models";

type LicenseStatusIndicatorProps = {
  status: VerificationStatus;
}

export const LicenseStatusChip = (props: LicenseStatusIndicatorProps) => {
  const { status } = props;
  switch (status.code) {
    case 'valid':
    case 'enabled':
      return <Chip color="var(--success)">{status.detail}</Chip>;
    default:
      return <Chip color="var(--danger)">{status.detail}</Chip>;
  }
};

export const LicenseStatusIcon = (props: LicenseStatusIndicatorProps) => {
  const { status } = props;
  const Icon = (() => {
    switch (status.code) {
      case 'valid':
      case 'enabled':
        return <Check color="var(--success)" size={20} />;
      default:
        return <Cross color="var(--danger)" size={20} />;
    }
  })();
  return <span title={status.detail}>{Icon}</span>
};
