import { DisableTool, EnableTool, RefreshTool, RemoveTool, RunTool, SetupTool } from 'components';
import cronstrue from 'cronstrue';
import { CronExpression, PipelineValue } from './Models';

export const getPipelineTools = (pipeline: PipelineValue, addRefresh: boolean = true) => {
  const tools = [];
  if (pipeline) {
    if (addRefresh) {
      tools.push(RefreshTool());
    }
    tools.push(RemoveTool());
    if (pipeline.value.enable) tools.push(EnableTool());
    if (pipeline.value.disable) tools.push(DisableTool());
    if (pipeline.value.run) tools.push(RunTool());
    if (pipeline.value.setup) tools.push(SetupTool());
  }
  return tools;
};

export const describeCronExpression = (expression: CronExpression) => {
  return expression.isValid ?
    cronstrue.toString(expression.value, { throwExceptionOnParseError: false }) :
    'Invalid expression';
};
