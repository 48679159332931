export const DigitalsafeIcon = () => {
    return (
    <svg width="32" height="32" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M189.8 0V152.8H152.9V36.8H36.8V0H189.8Z" fill="#0073E7"/>
        <path d="M152.9 152.8V189.7H0V36.8H36.8V152.8H152.9Z" fill="#0073E7"/>
    </svg>
    )
}


