import { CheckCircle as CheckIcon, XCircle as CrossIcon, Slash as SlashIcon } from 'react-feather';
import { titleCase } from 'utils';
import './PipelineRunOutcomeIcon.css';

type PipelineRunOutcomeIconProps = {
  outcome: string;
  size?: number;
  showDescription?: boolean;
};

export const PipelineRunOutcomeIcon = (props: PipelineRunOutcomeIconProps) => {
  const { outcome, size, showDescription } = props;
  const description = titleCase(outcome);
  return (
    <span className={`pipeline-run-outcome-icon ${outcome}`} title={!showDescription ? description : ''}>
      {(() => {
        switch (outcome) {
          case 'succeeded':
            return <CheckIcon color="var(--success)" size={size} />;
          case 'failed':
            return <CrossIcon color="var(--danger)" size={size} />;
          case 'cancelled':
            return <SlashIcon color="var(--warning)" size={size} />;
        }
      })()}
      {showDescription && description}
    </span>
  );
};
