import { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { GetIcon } from '../svgLogos/GetIcon';
import { Breadcrumb, BreadcrumbList, Button, Card } from '@premcloud/ui';
import './NewChannel.css';
import { ChannelSetUpService, Connector } from '../Services/ChannelSetupService';
import { Confirm, ConfirmResult } from 'components';
import { ConnectorCombination, ConnectorIcon } from 'features/pipelines/ConnectorIcon';

export const NewChannel = () => {
  const [connectors, setConnectors] = useState<Connector[]>();
  const [selectedInputChannel, setSelectedInputChannel] = useState<Connector>();
  const [selectedOutputChannel, setSelectedOutputChannel] = useState<Connector>();
  const [pipeline, setPipeline] = useState('');
  const [displaySetupModal, setDisplaySetupModal] = useState(false);

  const modalToParent = modalData => {

  }

  const navigate = useNavigate();

  const onInputSelected = (input: Connector) => {
    setSelectedInputChannel(input);
  }

  const onOutputSelected = (output: Connector) => {
    setSelectedOutputChannel(output);
  }

  const onSaveClick = () => {
    setDisplaySetupModal(true);
  }

  async function onSetupModalClose(result: ConfirmResult) {
    if (result === 'yes') {
      const id = await CreateSetUp();
      navigate(`/pipelines/setup/${id}`);
    }
    setDisplaySetupModal(false);
  }

  async function CreateSetUp() {
    var body = {input: selectedInputChannel.id, output: selectedOutputChannel.id};
    var response = await ChannelSetUpService.createSetup(body);
    localStorage.setItem('pipelineId', response.value.id);
    setPipeline(response.value.id);
    return response.value.id;
  }

  useEffect(() => {
    async function load() {
      const { value } = await ChannelSetUpService.getConnectors();
      setConnectors(value);
    }
    load();
  }, []);

  return (
    <div id="new.channel" className="page">

      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <NavLink to="/pipelines">Pipelines</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <span>Add</span>
        </Breadcrumb>
      </BreadcrumbList>

      <h1>Add Pipeline</h1>

      {connectors?.length === 0 &&
        <div className="license-message">
          <Card content={
            <>
              <h1>This product is unlicensed</h1>
              <p>Click <Link to="/license/licenses?add">here</Link> to add a license.</p>
            </>
          } />
        </div>
      }

      <div className='channelContainer'>
        <div className='pipeline'>
          {selectedInputChannel &&
            <ConnectorCombination input={selectedInputChannel} output={selectedOutputChannel} size="large" showName />
          }
        </div>

        {selectedInputChannel && selectedOutputChannel &&
          <div className='saveButton'>
            <Button
              color="var(--primary)"
              onClick={() => onSaveClick()}>
              Begin Setup
            </Button>
          </div>
        }

        <Confirm
          title="Confirm Pipeline I/O"
          body={selectedInputChannel &&
            <>
              Selection configuration:
              <ConnectorCombination input={selectedInputChannel} output={selectedOutputChannel} size="large" showName />
              Do you want to proceed with this configuration?
            </>
          }
          visible={displaySetupModal}
          onClose={onSetupModalClose}
        />

        {connectors?.length > 0 &&
          <>
            <div className='inputChannel'>
              <div className="parentForm">
                <div className='icon'><GetIcon icon="Input" /></div>
                <div className='name'>Input Connector</div>
              </div>
              <div className='labels'>Where is the data coming from?</div>
              {connectors.filter(c => c.type === 'input').map((input, i) => {
                return (
                  <div key={i} className='childForm' onClick={() => onInputSelected(input)}>
                    <ConnectorIcon connector={input} size="large" showName />
                  </div>
                )
              })}
            </div>

            <div className='outputChannel'>
              <div className="parentForm">
                <div className='icon'><GetIcon icon="Output" /></div>
                <div className='name'>Output Connector</div>
              </div>
              <div className='labels'>Where should the data be sent?</div>
              {connectors.filter(c => c.type === 'output').map((output, i) => {
                return (
                  <div key={i} className='childForm' onClick={() => onOutputSelected(output)}>
                    <ConnectorIcon connector={output} size="large" showName />
                  </div>
                )
              })}
            </div>
          </>
        }
      </div>
    </div>
  )
}
