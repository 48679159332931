import { Link } from 'data';
import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { PipelineService } from './Service';

ChartJS.register(ArcElement, Tooltip, Title);

type RunsPieChartProps = {
  runs: Link;
};

export const RunsPieChart = (props: RunsPieChartProps) => {
  const [data, setData] = useState<any>();

  const getData = async (link: Link) => {
    let completed = 0;
    let failed = 0;
    let indeterminate = 0;
    while (link) {
      const runs = (await PipelineService.getRuns(link));
      link = runs.next;
      for (const run of runs.value) {
        if (run.outcome === 'failed') {
          failed++;
        } else if (run.outcome === 'succeeded') {
          completed++;
        } else {
          indeterminate++;
        }
      }
    }
    return {
      labels: ['Completed', 'Failed', 'Indeterminate'],
      datasets: [
        {
          label: 'Graph',
          data: [completed, failed, indeterminate],
          backgroundColor: [
            'rgba(54, 235, 162, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 204, 0, 0.2)'
          ],
          borderColor: [
            'rgba(54, 235, 162, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 204, 0)'
          ],
          borderWidth: 1
        }
      ]
    };
  };

  useEffect(() => {
    (async () => setData(await getData(props.runs)))();
  }, [props.runs]);

  return data &&
    <div className="runs-pie-chart">
      {data.datasets[0].data.every((x: number) => x === 0) && <span className="no-chart-data">No data available</span>}
      <Pie data={data} width={200} height={200} options={{
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Pipeline Runs'
          }
        }
      }} />
    </div>
};
