export type Problem = {
  status?: number;
  title?: string;
  detail?: string;
  instance?: string;
  type?: string;
  errors?: Map<string, string[]>;
};

export const isProblem = (payload: any): payload is Problem => {
  return payload && (payload['status'] || payload['title'] || payload['detail'] || payload['error']);
};

export type Link = {
  href: string;
  rel?: string[];
  method?: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';
};

export type SelfLink = {
  self: Link;
};

export type SelfLinkLike = SelfLink | Link;

export const isLink = (payload: any): payload is Link => {
  return payload && payload['href'];
};

export type NamedLink = {
  name: string;
  title: string;
} & Link;

export const getLinks = (payload: any): NamedLink[] => {
  const links: NamedLink[] = [];
  if (payload) {
    for (const key in payload) {
      const link = payload[key];
      if (isLink(link)) {
        links.push({ ...link, name: key, title: key[0].toUpperCase() + key.substring(1) });
      }
    }
  }
  return links;
}

export const getSelf = (payload: any): Link => {
  if (isLink(payload)) {
    return payload;
  } else if (payload && isLink(payload.self)) {
    return payload.self;
  }
};

export type ValueObject<T> = {
  value: T;
} & Link & SelfLink;

export type Page<T> = {
  count: number;
  page: number;
  pageCount: number;
  next?: Link;
  previous?: Link;
} & ValueObject<T[]>;

export type PagingOptions = {
  page?: number;
  size?: number;
}

export const formatPagingOptions = (options: PagingOptions): string[] => {
  return [
    options?.page && `$page=${options?.page}`,
    options?.size && `$page.size=${options?.size}`
  ];
};

export type FormFieldMap = {
  [key: string]: React.Dispatch<React.SetStateAction<any>>;
};

export const feedbackProblem = (fields: FormFieldMap, problem: Problem): string[] => {
  const general = [];

  if (problem) {
    if (problem.detail || problem.title) {
      general.push(problem.detail || problem.title);
    }

    if (problem.errors) {
      for (let key in problem.errors) {
        const lower = key.toLowerCase();
        if (lower in fields) {
          const setter = fields[lower];
          setter(value => ({
            ...value,
            valid: false,
            errors: problem.errors[key]
          }));
        } else {
          general.push(...problem.errors[key]);
        }
      }
    }
  }

  return general;
};
