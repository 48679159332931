import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { ThemeSwitcher } from './ThemeSwitcher';
import { Logo } from './Logo';

import { ShoppingCart, User as UserIcon, LogOut as LogOutIcon } from 'react-feather';

import { useEffect, useState } from 'react';
import { DetectNavBar } from './Detect/DetectNavBar';
import { ConnectNavBar } from './ConnectorFramework/ConnectorNavBar';
import { Menu } from '@premcloud/ui';
import { useAuth } from 'features/auth';

export const Navbar = ({appName}) => {
  const { user, logout } = useAuth();
  const [app, setApp] = useState('');

  const handleMenuAction = (value: string) => {
    if (value === 'logout') {
      logout();
    }
  }

  useEffect(() => {
    setApp(appName);
  }, [appName]);

  return (
    <div id="navbar">
      <div id="logo-container">
        <NavLink to="/">
          <Logo height={3}/>
        </NavLink>
      </div>

      <div id="navbar-main">
          {app === "Connect" ? <ConnectNavBar /> : <></>}
          {app === "Detect" ? <DetectNavBar /> : <></>}
        </div>

      <div id="utils" className="link-group">
          {user &&
            <Menu
              trigger={<UserIcon />}
              openPosition="topRight"
              onSelect={handleMenuAction}
              menuItems={[
                {
                  value: 'username',
                  display: (
                    <>
                      <UserIcon />
                      <span>{user.name}</span>
                    </>
                  ),
                  sticky: true,
                  disabled: true
                },
                {
                  value: 'logout',
                  display: (
                    <>
                      <LogOutIcon />
                      <span>Logout</span>
                    </>
                  )
                }
              ]}
            />
          }
          <ThemeSwitcher />
        </div>
      </div>

  );
};
