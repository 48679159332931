import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import './Spinner.css';

type SpinnerProps = {
  visible: boolean;
  debounce?: number;
}

export const Spinner = (props: SpinnerProps) => {
  const { visible, debounce: delay = 0 } = props;
  const [show, setShow] = useState(false);
  const debounceHandler = debounce((vis: boolean) => setShow(vis), delay);

  useEffect(() => {
    if (visible && delay > 0) {
      debounceHandler(visible);
    } else {
      setShow(visible);
    }
    return () => debounceHandler.cancel();
  }, [visible, delay, debounceHandler]);

  return (
    <div className={`pui-spinner-backdrop ${show ? 'visible': ''}`}>
      <div className="pui-spinner-indicator">
        <div className="pui-spinner-bar"></div>
        <div className="pui-spinner-bar"></div>
        <div className="pui-spinner-bar"></div>
        <div className="pui-spinner-bar"></div>
      </div>
    </div>
  );
}
