import { Field, FormField, Input, Option, Select } from '@premcloud/ui';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { CheckCircle as CheckCircleIcon, Database as DatabaseIcon } from 'react-feather';
import { createField } from 'utils';
import { CredentialValue } from './Credential';
import { FieldContext } from '../FieldContext';
import { http } from 'data';
import { makeTrackable, validate } from '../../validate';

const providersOptions = [
  { value: 'sqlServer', display: 'Microsoft SQL Server', port: '1433' },
  { value: 'mariaDb', display: 'MariaDB', port: '3306' },
  { value: 'mySql', display: 'MySQL', port: '3306' },
  { value: 'oracle', display: 'Oracle', port: '6100' }
];

type DatabaseValue = {
  ServerProvider?: string;
  ServerName?: string;
  ServerPort?: number;
  Credential?: CredentialValue,
  DatabaseName?: string;
};

export const Database = () => {
  const { value, onChange }: { value: DatabaseValue, onChange: (value: DatabaseValue) => void } = useContext(FieldContext);
  const [serverProvider, setServerProvider] = useState(makeTrackable(createField('ServerProvider', value?.ServerProvider || '')));
  const [serverName, setServerName] = useState(makeTrackable(createField('ServerName', value?.ServerName || '')));
  const [serverPort, setServerPort] = useState(makeTrackable(createField('ServerPort', value?.ServerPort !== undefined ? value.ServerPort.toString() : '')));
  const [username, setUsername] = useState(makeTrackable(createField('Username', value?.Credential?.Username ?? '')));
  const [password, setPassword] = useState(makeTrackable({
    ...createField('Password', value?.Credential?.Password ?? ''),
    valid: false // set to false because we don't actually have the password.
  }));
  const [databaseName, setDatabaseName] = useState(makeTrackable(createField('DatabaseName', value?.DatabaseName || '')));
  const [databaseOptions, setDatabaseOptions] = useState<Option[]>([]);

  const handleDatabasesLoaded = (databases: string[]) => {
    setDatabaseOptions(databases.map(db => ({
      value: db
    })));
  };

  // Trigger onChange when form fields change.
  useEffect(() => {
    const fields = [serverProvider, serverName, serverPort, username, password, databaseName];
    let dirty = false;

    for (const field of fields) {
      if (field.dirty) {
        dirty = true;
        // If fields other than password have changed, invalidate the password.
        if (field !== password) {
          if (!password.dirty && password.value) {
            validate({
              ...password,
              value: ''
            }, setPassword);
            dirty = true;
          }
        }
      }
    }

    if (dirty) {
      const valid = !fields.some(field => !field.valid);
      const value: DatabaseValue = valid
        ? fields.reduce((obj, field) => {
          const receiver = field === username || field === password
            ? obj['Credential'] ?? (obj['Credential'] = {})
            : obj;
          receiver[field.name] = field.value;
          return obj;
        }, {})
        : undefined;
      onChange(value);
    }
  }, [serverProvider, serverName, serverPort, username, password, databaseName]);

  return (
    <div className="pui-form compound-form-field database">
      <FormField
        label="Provider"
        errors={serverProvider.errors}
        dirty={serverProvider.dirty}
        input={
          <Select
            value={serverProvider.value}
            placeholder="Provider"
            options={providersOptions}
            onChange={value => {
              const currentProvider = serverProvider.value;
              const defaultPort = providersOptions.find(p => p.value === currentProvider)?.port;
              validate({
                ...serverProvider,
                value
              }, setServerProvider);
              if (!serverPort.value || !defaultPort || serverPort.value === defaultPort) {
                validate({
                  ...serverPort,
                  value: providersOptions.find(p => p.value === value).port
                }, setServerPort);
              }
            }}
            onBlur={() => validate(serverProvider, setServerProvider)}
            hasError={serverProvider.errors.length > 0}
          />
        }
      />
      <FormField
        label="Server"
        errors={serverName.errors}
        dirty={serverName.dirty}
        hint="Enter the name of the database server"
        input={
          <Input
            value={serverName.value}
            placeholder="Server"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              validate({
                ...serverName,
                value: event.target.value,
              }, setServerName)
            }
            onBlur={() => validate(serverName, setServerName)}
            hasError={serverName.errors.length > 0}
          />
        }
      />
      <FormField
        label="Port"
        errors={serverPort.errors}
        dirty={serverPort.dirty}
        hint="What port is the server exposed on?"
        input={
          <Input
            type="number"
            value={serverPort.value}
            placeholder="Port"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              validate({
                ...serverPort,
                value: event.target.value,
              }, setServerPort)
            }
            onBlur={() => validate(serverPort, setServerPort)}
            hasError={serverPort.errors.length > 0}
          />
        }
      />
      <FormField
        label="Username"
        errors={username.errors}
        dirty={username.dirty}
        input={
          <Input
            value={username.value}
            placeholder="Username"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              validate({
                ...username,
                value: event.target.value,
              }, setUsername)
            }
            onBlur={() => validate(username, setUsername)}
            hasError={username.errors.length > 0}
          />
        }
      />
      <FormField
        label="Password"
        errors={password.errors}
        dirty={password.dirty}
        input={
          <Input
            value={password.value}
            type="password"
            placeholder="Password"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              validate({
                ...password,
                value: event.target.value,
              }, setPassword)
            }
            onBlur={() => {
              if (password.initialValue !== password.value) {
                validate(password, setPassword)
              }
            }}
            hasError={password.errors.length > 0}
          />
        }
      />
      <DatabaseLoader
        serverProvider={serverProvider}
        serverName={serverName}
        serverPort={serverPort}
        username={username}
        password={password}
        onDatabasesLoaded={handleDatabasesLoaded}
      />
      <FormField
        label="Database"
        errors={databaseName.errors}
        dirty={databaseName.dirty}
        hint={databaseOptions.length === 0 ? 'Connect to the server to select a database or enter it manually.' : 'Select a database.'}
        input={
          databaseOptions.length == 0
            ? <Input
              value={databaseName.value}
              placeholder="Database"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                validate({
                  ...databaseName,
                  value: event.target.value,
                }, setDatabaseName)
              }
              onBlur={() => validate(databaseName, setDatabaseName)}
              hasError={databaseName.errors.length > 0}
            /> :
            <Select
              placeholder="Database"
              value={databaseName.value}
              options={databaseOptions}
              onChange={value =>
                validate({
                  ...databaseName,
                  value
                }, setDatabaseName)
              }
              onBlur={() => validate(databaseName, setDatabaseName)}
              hasError={databaseName.errors.length > 0}
            />
        }
      />
    </div>
  );
};

type DatabaseLoaderProps = {
  serverProvider: Field<string>;
  serverName: Field<string>;
  serverPort: Field<string>;
  username: Field<string>;
  password: Field<string>;
  onDatabasesLoaded: (databases: string[]) => void;
};

const DatabaseLoader = (props: DatabaseLoaderProps) => {
  const { serverProvider, serverName, serverPort, username, password, onDatabasesLoaded } = props;
  const [state, setState] = useState<'disconnected' | 'connecting' | 'connected'>('disconnected');
  const enabled = useMemo(() => serverProvider.valid && serverName.valid && serverPort.valid && username.valid && password.valid && state !== 'connecting',
    [serverProvider, serverName, serverPort, username, password, state]);

  const handleClick = async () => {
    switch (state) {
      case 'disconnected':
      case 'connected':
        return connect();
    }
  }

  const connect = async () => {
    try {
      setState('connecting');
      const data = {
        serverProvider: serverProvider.value,
        serverName: serverName.value,
        serverPort: serverPort.value,
        username: username.value,
        password: password.value
      };
      const response = await http.post<string[]>('/api/databases', data);
      await sleep();
      onDatabasesLoaded(response.data);
      setState('connected');
    } catch {
      onDatabasesLoaded([]);
      setState('disconnected');
    }
  };

  const sleep = () => new Promise(r => setTimeout(r, 1000));

  useEffect(() => {
    onDatabasesLoaded([]);
    setState('disconnected');
  }, [serverProvider, serverName, serverPort, username, password]);

  return (
    <button
      type="button"
      className={state}
      style={{
        backgroundColor: 'var(--primary)',
        color: 'var(--primary-fg)'
      }}
      disabled={!enabled}
      onClick={handleClick}>
      {(() => {
        switch (state) {
          case 'disconnected':
            return <><DatabaseIcon size={18} /> Connect to {serverName?.value || 'Database Server'}</>
          case 'connecting':
            return <>Connecting <span><span>.</span><span>.</span><span>.</span></span></>;
          case 'connected':
            return <>Connected <CheckCircleIcon size={18} /></>;
        }
      })()}
    </button>
  );
}
