import { ReactElement, forwardRef, useState } from "react";
import { ChevronDown as ChevronDownIcon, ChevronUp as ChevronUpIcon } from "react-feather";
import { Button, Column, Row } from "@premcloud/ui";
import { Spinner } from "components";
import "./ExpandableTableRow.css";

type ExpandableTableRowProps = {
  row: Row;
  columns: Column[];
  element: (row: Row) => Promise<ReactElement>;
  onClick?: (row: Row) => void;
};

export const ExpandableTableRow = forwardRef<HTMLTableRowElement, ExpandableTableRowProps>((props, ref) => {
  const { row, columns, element, onClick } = props;
  const [expanded, setExpanded] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [expandedElement, setExpandedElement] = useState<ReactElement>();
  const keys = columns.map((column) => column.id);

  const handleToggle = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const expand = !expanded;
    setExpandedElement(null);
    setExpanded(expand);
    if (expand) {
      try {
        setSpinnerVisible(true);
        const el = await element(row);
        setSpinnerVisible(false);
        setExpandedElement(el);
      } finally {
        setSpinnerVisible(false);
      }
    }
  };

  const getClass = (id: string): string => {
    const column = columns.find((c) => c.id === id);
    return column?.size || "";
  };

  const classNames = [];
  if (expanded) classNames.push("expanded");
  if (onClick) classNames.push("clickable");

  return (
    <>
      <tr ref={ref} className={classNames.join(" ")} onClick={() => onClick && onClick(row)}>
        {keys.map((key, j) => (
          <td key={j} className={getClass(key)}>
            {typeof row[key] === "object" ? row[key]["display"] || row[key]["value"] : row[key]}
          </td>
        ))}
        <td className="expandable-toggle">
          <Button
            size="small"
            color="transparent"
            textColor="var(--primary)"
            onClick={handleToggle}
          >
            {expanded ? <ChevronUpIcon size={20} /> : <ChevronDownIcon size={20} />}
          </Button>
        </td>
      </tr>
      {expanded && (
        <tr className="expandable">
          <td colSpan={columns.length}>
            <Spinner visible={spinnerVisible} />
            {expandedElement}
          </td>
        </tr>
      )}
    </>
  );
});
