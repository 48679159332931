import { CSSProperties, MutableRefObject, ReactElement } from 'react';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from 'react-feather'
import { getLinks, Link, NamedLink, Page } from 'data';
import './Pager.css';

export type PageNav = {
  element: string | ReactElement;
  title?: string;
  link: Link;
}

type PagerProps = {
  page: Page<any>;
  onSelect?: (page: PageNav) => void | Promise<void>;
  children: any;
  style?: CSSProperties;
  scrollTarget?: MutableRefObject<HTMLElement>;
}

const pageSorter = (a: NamedLink, b: NamedLink) => b.name.localeCompare(a.name);

export const Pager = (props: PagerProps) => {
  const { page, onSelect, children, style, scrollTarget } = props;
  const pages = getLinks(page).filter(l => l.rel && l.rel.includes('paging')).sort(pageSorter).map(x => {
    return {
      element: (() => {
        switch (x.name) {
          case 'next': return <ChevronRightIcon size={20} />;
          case 'previous': return <ChevronLeftIcon size={20} />;
          default: return x.name;
        }
      })(),
      title: x.title,
      link: x
    };
  });

  const handleSelect = (page: PageNav) => {
    if (onSelect) {
      const promise = onSelect(page);
      if (typeof promise === 'object' && typeof promise.then === 'function') {
        promise.then(scrollToTarget);
      } else {
        scrollToTarget();
      }
    }
  };

  const scrollToTarget = () => {
    if (scrollTarget) {
      scrollTarget.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="pui-pager-container" style={style}>
      <div className="pui-pager-target">
        {children}
      </div>
      <div className="pui-pager">
        <span className="page-info">{page.page} of {page.pageCount}</span>
        <span className="pager">
          {pages.map((page, i) => (<button key={i} title={page.title} onClick={() => handleSelect(page)}>{page.element}</button>))}
        </span>
      </div>
    </div>
  )
}
