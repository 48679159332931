import { Button } from '@premcloud/ui';
import { useContext } from 'react';
import { SetupContext } from './SetupContext';

export type SetupNavType = 'cancel' | 'back' | 'next';

type SetupNavProps = {
  onNavigate: (type: SetupNavType) => void;
};

export const SetupNav = (props: SetupNavProps) => {
  const { onNavigate } = props;
  const { overview, currentStep, valid } = useContext(SetupContext)

  return currentStep?.interactive &&
    <div className="setup-nav">
      {overview?.cancel &&
        <Button outline color="var(--primary)" onClick={() => onNavigate('cancel')}>Cancel</Button>
      }
      {currentStep?.back &&
        <Button color="var(--primary)" onClick={() => onNavigate('back')}>Previous</Button>
      }
      {currentStep &&
        <Button color="var(--primary)" disabled={!valid} onClick={() => onNavigate('next')}>
          {(() => {
            return overview.value.steps.at(-1).number === currentStep.number
              ? "Finish"
              : "Next";
          })()}
        </Button>
      }
    </div>
  };
