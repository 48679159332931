import { Field, validate as puiValidate } from '@premcloud/ui'

type Trackable<T> = {
  dirty: boolean;
  value?: T;
  initialValue?: T;
};

export const validate = <T, U extends Trackable<T> & Field<T>>(field: U, setField: React.Dispatch<React.SetStateAction<U>>): Promise<void> => {
  return puiValidate(field, (updated: U) => {
    setField(detectChanges(updated));
  });
};

export const detectChanges = <T, U extends Trackable<T>>(field: U): U => {
  field.dirty = field.initialValue !== field.value;
  return field
};

export const makeTrackable = <T, U extends Field<T>>(field: U): U & Trackable<T> => {
  return field.value
    ? {
      ...field,
      initialValue: field.value
    }
    : field;
};
