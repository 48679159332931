import { createContext, useContext, useState } from 'react'
import { Spinner } from './Spinner'

type SpinnerContextType = {
  showSpinner: (visible: boolean) => void;
}

const SpinnerContext = createContext<SpinnerContextType>({
  showSpinner: () => { }
});

type SpinnerProviderProps = {
  children: any;
  debounce?: number;
}

export const SpinnerProvider = (props: SpinnerProviderProps) => {
  const { children, debounce } = props;
  const [visible, setVisible] = useState(false);
  const [, setCount] = useState(0);

  const showSpinner = (visible: boolean) => {
    setCount(current => {
      if (visible) {
        current++;
        setVisible(true);
      } else if (--current === 0) {
        setVisible(false);
      }
      return current;
    });
  };

  return (
    <SpinnerContext.Provider value={{ showSpinner }}>
      <Spinner visible={visible} debounce={debounce} />
      {children}
    </SpinnerContext.Provider>
  )
};

type SpinnerFunction = {
  (visible: boolean): void;
}

export const useSpinner = (): SpinnerFunction => {
  const context = useContext(SpinnerContext);
  return (visible: boolean) => {
    context.showSpinner(visible);
  };
};
