import jwt_decode from "jwt-decode";
import { http } from 'data';
import { dayjs } from 'utils';
import { Credential, LoginResult, UserInfo } from "./Models";

export const Service = {
  login: (cred: Credential) => http.post<LoginResult>('/api/accounts/login', cred).then(response => {
    const { token, expires } = response.data;
    const { unique_name } = jwt_decode(token) as any;
    const user: UserInfo = { name: unique_name };
    localStorage.setItem('token', token);
    localStorage.setItem('expires', expires);
    localStorage.setItem('user', JSON.stringify(user));
  }),
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    localStorage.removeItem('user');
  },
  getToken: () => localStorage.getItem('token'),
  getExpiration: () => dayjs.utc(localStorage.getItem('expires')),
  getUserInfo: () => JSON.parse(localStorage.getItem('user')) as UserInfo,
  isLoggedIn: () => localStorage.getItem('token') && localStorage.getItem('expires') && localStorage.getItem('user')
};
