import { Tab, TabGroup } from "@premcloud/ui"
import { useEffect, useState } from "react";
import './Modal.css';

export const RunningModal = (props) => {

    const [item, setItem] = useState(props);

    useEffect(() => {
        setItem(props);
    }, []);

    return(
        <div id='pending-modal' className="modal">
            Running
            <TabGroup>
                <Tab label="Details">
                    ID # {props.id}<br/>
                    Pipeline {props.inputIcon} <br/>
                    Title {props.title} <br/>
                </Tab>
                <Tab label="User Mapping">
                </Tab>
            </TabGroup>
        </div>
    )
}
