import './LargeLogo.css';
import { Logo } from './Logo';

export const LargeLogo = () => {
  return (
    <div id="large-logo">
      <Logo height={12} />
    </div>
  );
};
