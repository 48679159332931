import {
  http,
  formatPagingOptions,
  getSelf,
  Link,
  Page,
  PagingOptions,
  SelfLinkLike,
  ValueObject,
  data,
  nothing
} from 'data';
import { UpdateUser, User, UserCollectionItem } from './Models';

export type UserPage = Page<UserCollectionItem>;
export type UserValue = ValueObject<User>;

export const UserService = {
  list: (pagingOptions?: PagingOptions) => {
    const query = formatPagingOptions(pagingOptions).filter(x => !!x).join('&');
    const url = `/api/accounts${query && '?' + query}`;
    return http.get<UserPage>(url).then(data)
  },
  listPage: (link: Link) => http.get<UserPage>(link.href).then(data),
  get: (user: SelfLinkLike) => http.get<UserValue>(getSelf(user).href).then(data),
  add: (user: User) => http.post('/api/accounts', user).then(nothing),
  update: (user: UpdateUser) => http.patch(getSelf(user).href, user).then(nothing),
  remove: (user: SelfLinkLike) => http.delete(getSelf(user).href).then(nothing)
}
