import { Loader as LoaderIcon, StopCircle as StopIcon, Clock as ClockIcon } from 'react-feather'
import { titleCase } from "utils";
import { PipelineRunState } from './Models';
import './PipelineRunStateIcon.css';

type PipelineRunStateIconProps = {
  state: PipelineRunState;
  size?: number;
  showDescription?: boolean;
};

export const PipelineRunStateIcon = (props: PipelineRunStateIconProps) => {
  const { state, size, showDescription } = props;
  const description = titleCase(state);
  return (
    <span className={`pipeline-run-state-icon ${state}`} title={!showDescription ? description : ''}>
      {(() => {
        switch (state) {
          case 'ready':
          case 'pending':
            return <ClockIcon size={size} />;
          case 'running':
            return <LoaderIcon color='var(--info)' size={size} />;
          case 'stopped':
            return <StopIcon color='var(--success)' size={size} />;
        }
      })()}
      {showDescription && description}
    </span>
  );
};
