import { ChangeEvent, useEffect, useState } from 'react';
import { Field, Form, FormField, Input, required, validate } from '@premcloud/ui';
import { feedbackProblem, Problem } from 'data';
import { GeneralProblem } from 'components/Problem';

type NewLicenseFormData = {
  file: File;
};

type NewLicenseFormProps = {
  problem: Problem;
  onChange: (data: NewLicenseFormData) => void;
}

export const NewLicenseForm = (props: NewLicenseFormProps) => {
  const { problem, onChange } = props;
  const [errors, setErrors] = useState<string[]>([]);
  const [file, setFile] = useState({
    name: 'file',
    value: null,
    validators: [required()],
    errors: [],
    dirty: false,
    valid: false
  } as Field<FileList>);

  useEffect(() => {
    onChange(file.valid && {
      file: file.value[0]
    });
  }, [file, onChange]);

  // Feedback problems.
  useEffect(() => {
    setErrors(feedbackProblem({
      file: setFile,
    }, problem));
  }, [problem]);

  return (
    <Form>
      <GeneralProblem errors={errors} />
      <FormField
        label="License File"
        errors={file.errors}
        dirty={file.dirty}
        hint="Select a license file."
        input={
          <Input
            type="file"
            placeholder="License file"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              validate({
                ...file,
                value: event.target.files,
              }, setFile)
            }
            onBlur={() => validate(file, setFile)}
            hasError={file.errors.length > 0}
          />
        }
      />
    </Form>
  );
}
