import { useEffect, useState } from 'react';
import { dayjs, Dayjs, formatDuration } from 'utils';

type TimerProps = {
  start?: Dayjs
}

export const Timer = (props: TimerProps) => {
  const { start = dayjs() } = props;
  const [time, setTime] = useState(dayjs.duration(dayjs().diff(start)));

  useEffect(() => {
    const interval = setInterval(
      () => setTime(dayjs.duration(dayjs().diff(start))),
      1000,
    );
    return () => clearInterval(interval);
  }, [start]);

  return (<div className="timer">{formatDuration(time)}</div>);
};
