import { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from '@premcloud/ui';
import { Spinner } from 'components';
import { getSelf, isProblem, Problem, SelfLinkLike } from 'data';
import { UserForm, UserFormData } from './UserForm';
import { UserService } from './UserService';
import { User } from './Models';
import { RoleService } from './RoleService';

export type UserModalType = 'create' | 'update' | 'reset';

type UserFormModalProps = {
  type: UserModalType;
  user?: SelfLinkLike;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess?: () => void;
};

export const UserFormModal = (props: UserFormModalProps) => {
  const { type, user, visible, setVisible, onSuccess } = props;
  const [roles, setRoles] = useState<string[]>();
  const [initialUserData, setInitialUserData] = useState<UserFormData>();
  const [userData, setUserData] = useState<UserFormData>();
  const [userProblem, setUserProblem] = useState<Problem>();
  const [showSpinner, setShowSpinner] = useState(true);
  const title = useMemo(() => {
    switch (type) {
      case 'create': return 'New User';
      case 'update': return 'Edit User';
      case 'reset': return 'Reset Password';
    }
  }, [type]);

  const handleClick = async () => {
    try {
      setShowSpinner(true);
      await (type === 'create' ?
        UserService.add((userData || {}) as User) :
        UserService.update({ self: getSelf(user), ...userData }));
      setVisible(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (isProblem(err)) {
        setUserProblem(err);
      }
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    setUserProblem(undefined);
    setInitialUserData(undefined);

    async function load() {
      try {
        setShowSpinner(true);
        setRoles((await RoleService.list()).value);
        if (user) {
          setInitialUserData((await UserService.get(user)).value);
        }
      } catch (err) {
        if (isProblem(err)) {
          // TODO
        }
      } finally {
        setShowSpinner(false);
      }
    }

    if (visible && type !== 'reset') {
      load();
    }

  }, [visible, type, user]);

  return visible &&
    <Modal header={title} onClose={() => setVisible(false)}>
      <>
        <Spinner visible={showSpinner} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            position: 'relative'
          }}>
          <UserForm
            type={type}
            availableRoles={roles}
            problem={userProblem}
            data={initialUserData}
            onChange={setUserData} />
          <div style={{ marginLeft: 'auto' }}>
            <Button
              color="var(--primary)"
              disabled={!userData}
              onClick={handleClick}>
              {(() => {
                switch (type) {
                  case 'create': return 'Create';
                  case 'update': return 'Update';
                  case 'reset': return 'Reset';
                }
              })()}
            </Button>
          </div>
        </div>
      </>
    </Modal>
}
