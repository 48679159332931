import { useEffect, useState } from 'react';
import { RefreshTool, Toolbar, useSpinner } from 'components';
import { isProblem } from 'data';
import { LicenseService, SystemInfoService } from './Services';
import { SystemInfoValue, LicenseValue } from './Models';
import { LicenseMessage } from './LicenseMessage';
import { LicenseViewer } from './LicenseViewer';
import { SystemInfoViewer } from './SystemInfoViewer';
import { Link } from 'react-router-dom';

export const LicenseOverview = () => {
  const [license, setLicense] = useState<LicenseValue>();
  const [unlicensed, setUnlicensed] = useState(false);
  const [systemInfo, setSystemInfo] = useState<SystemInfoValue>();
  const showSpinner = useSpinner();

  const handleToolClick = (value: string) => {
    switch (value) {
      case 'refresh': return load();
    }
  }

  const load = async () => {
    try {
      showSpinner(true);
      setUnlicensed(false);
      setSystemInfo(await SystemInfoService.get());
      setLicense(await LicenseService.getOverview());
    } catch (err) {
      setLicense(null);
      if (isProblem(err)) {
        // Cannot find a license.
        if (err.status < 500) {
          setUnlicensed(true);
        }
      }
    } finally {
      showSpinner(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Toolbar tools={[RefreshTool()]} onToolClick={handleToolClick} />
      {unlicensed &&
        <>
          <LicenseMessage>
            <h1>This product is unlicensed</h1>
            <p>Click <Link to="../licenses?add">here</Link> to add a license.</p>
          </LicenseMessage>
          <SystemInfoViewer systemInfo={systemInfo} />
        </>
      }
      {license &&
        <>
          <SystemInfoViewer systemInfo={systemInfo} />
          <LicenseViewer license={license} />
        </>
      }
    </>
  );
}
