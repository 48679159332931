import { Toolbar as PuiToolbar } from '@premcloud/ui';
import { ToolProps } from './Tool';
import './Toolbar.css';

type ToolbarProps = {
  tools: React.ComponentType<ToolProps>[];
  onToolClick: (value: string) => void;
};

export const Toolbar = (props: ToolbarProps) => {
  const { tools, onToolClick } = props;
  return (
    <PuiToolbar>
      {tools.map((Tool, i) => (<Tool key={i} onClick={onToolClick} />))}
    </PuiToolbar>
  );
};
