import { http }  from 'data';
import configData from '../appSettings.json'

const API_Url = configData.API_SERVER_URL;

export const ChannelService = {
    getAll: async () => {
        const url = API_Url + '/api/channels';
        const response = await (await http.get<any>(url)).data;
        return response;
    },
    getDetails: async(payloadUrl: string) => {
        const url = API_Url + payloadUrl;
        const response = (await http.get(url)).data;
        return response;
    },
    delete: async(payloadUrl: string) => {
        const url = API_Url + payloadUrl;
        const response = (await http.delete(url)).data;
        return response;
    },
    post: async(payloadUrl: string, payload: string) => {
        const url = API_Url + payloadUrl;
        const response = (await http.post(url, payload)).data;
        return response;
    },
    get: async(payloadUrl: string) => {
        const url = API_Url + payloadUrl;
        const response = (await http.get(url)).data;
        return response;
    }
}
