import { createContext, useContext, useState } from 'react';
import { Confirm, ConfirmResult } from './Confirm';

type ConfirmFunction = {
  (title: string, body: string | React.ReactElement): Promise<ConfirmResult>;
};

const ConfirmContext = createContext<ConfirmFunction>(null);

type ResolveFunction = {
  (value: ConfirmResult): void;
}

export const ConfirmProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState<string | React.ReactElement>('');
  const [resolve, setResolve] = useState<ResolveFunction>();

  const show = (title: string, body: string | React.ReactElement): Promise<ConfirmResult> => {
    setTitle(title);
    setBody(body);
    setVisible(true);
    return new Promise((resolve) => {
      setResolve(() => resolve);
    });
  };

  const handleClose = (result: ConfirmResult) => {
    setVisible(false);
    resolve(result);
  };

  return (
    <ConfirmContext.Provider value={show}>
      {children}
      <Confirm
        visible={visible}
        title={title}
        body={body}
        onClose={handleClose} />
    </ConfirmContext.Provider>
  )
};

export const useConfirm = (): ConfirmFunction => {
  const context = useContext(ConfirmContext);
  return (title: string, body: string | React.ReactElement) => context(title, body);
};
