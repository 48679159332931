import { Menu, Row } from "@premcloud/ui";
import { DescriptionDetails, DescriptionList, Pager, RefreshTool, RunTool, Table, Timer, Toolbar, useConfirm, useSpinner } from 'components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MoreVertical as MoreVerticalIcon, FileText as FileTextIcon } from 'react-feather';
import { NavLink, useNavigate } from 'react-router-dom';
import { dayjs, formatDate, formatDuration, titleCase } from 'utils';
import { PipelineRun, PipelineRunPage } from './Models';
import { usePipeline } from './PipelineContext';
import { PipelineRunOutcomeIcon } from './PipelineRunOutcomeIcon';
import { PipelineRunStateIcon } from './PipelineRunStateIcon';
import { PipelineService } from './Service';
import { historyRunInputConnectors, useConfirmRun } from './ConfirmRunContext';

export const PipelineRunList = () => {
  const { pipeline, loadPipeline } = usePipeline();
  const [runs, setRuns] = useState<PipelineRunPage>();
  const tools = useMemo(() => {
    const tools = [];
    if (pipeline) {
      tools.push(RefreshTool());
      if (pipeline.value.run) tools.push(RunTool());
    }
    return tools;
  }, [pipeline]);
  const showSpinner = useSpinner();
  const confirm = useConfirm();
  const confirmRun = useConfirmRun();
  const navigate = useNavigate();
  const scrollElement = useRef<any>();

  const load = async () => {
    try {
      showSpinner(true);
      if (pipeline) {
        setRuns(await PipelineService.getRuns(pipeline.value));
      }
    } finally {
      showSpinner(false);
    }
  };

  const handleToolClick = async (tool: string) => {
    if (tool === 'refresh') {
      return await load();
    } else if (tool === 'run') {
      if (historyRunInputConnectors.includes(pipeline.value.input.id)) {
        const { result, runId } = await confirmRun(pipeline.value);
        if (result === 'yes') {
          await PipelineService.createRun(pipeline.value, runId);
        }
      } else {
        const result = await confirm(titleCase(tool) + ' Pipeline', <>Are you sure you want to {tool} this pipeline?</>);
        if (result === 'yes') {
          await PipelineService.createRun(pipeline.value);
        }
      }
    } else {
      throw new Error(`${tool} not handled`);
    }
  };

  const handleMenuAction = (action: string, run: PipelineRun) => {
    if (action === 'logs') {
      navigate(`./${run.id}/logs`);
    }
  };

  const rows = useMemo(() => runs?.value.map((r) => buildRunRow(r, handleMenuAction)), [runs]);

  useEffect(() => {
    (async () => {
      await loadPipeline();
      await load();
    })();
  }, [pipeline]);

  return (
    runs && (
      <>
        <Toolbar tools={tools} onToolClick={handleToolClick} />

        <Pager
          page={runs}
          onSelect={async (nav) => setRuns(await PipelineService.getRuns(nav.link))}
          style={{ flex: '1 1' }}
          scrollTarget={scrollElement}
        >
          <Table
            defaultSortColumn="startTime"
            defaultSortOrder="desc"
            columns={[
              { id: 'name' },
              { id: 'state', size: 'large' },
              { id: 'startTime', sort: false, display: ' ', size: 'size-16' },
              { id: 'runTime', sort: false, display: ' ', size: 'size-8' },
              { id: 'actions', sort: false, display: ' ', size: 'kebab-menu' },
            ]}
            rows={rows}
            firstRowRef={scrollElement}
          />
        </Pager>
      </>
    )
  );
};

const buildRunRow = (
  run: PipelineRun,
  onMenuAction: (action: string, log: PipelineRun) => void
): Row => {
  return {
    name: {
      value: run.name,
      display: <NavLink to={`./${run.id}`}>{run.name}</NavLink>,
    },
    state: {
      value: run.state,
      display:
        run.state === 'stopped' && run.outcome ? (
          <PipelineRunOutcomeIcon outcome={run.outcome} />
        ) : (
          <PipelineRunStateIcon state={run.state} />
        ),
    },
    startTime: (() => {
      const dateCreated = dayjs.utc(run.dateCreated);
      const startTime = run.startTime && dayjs.utc(run.startTime);
      const stopTime = run.stopTime && dayjs.utc(run.stopTime);
      switch (run.state) {
        case 'ready':
        case 'pending':
          return {
            value: '0',
            display: (
              <DescriptionList>
                <DescriptionDetails
                  term="Queued Time"
                  value={<>{formatDate(dateCreated)} <span className="info">({dateCreated.local().fromNow()})</span></>} />
              </DescriptionList>
            )
          };
        case 'running':
          return {
            value: '0',
            display: (
              <DescriptionList>
                <DescriptionDetails
                  term="Start Time"
                  value={<>{formatDate(startTime)} <span className="info">({startTime.local().fromNow()})</span></>} />
              </DescriptionList>
            )
          };
        case 'stopped':
          return {
            value: '0',
            display: (
              <DescriptionList>
                <DescriptionDetails
                  term="Stop Time"
                  value={<>{formatDate(stopTime)} <span className="info">({stopTime.local().fromNow()})</span></>} />
              </DescriptionList>
            )
          };
      }
    })(),
    runTime: (() => {
      const runTime = run.runTime && dayjs.duration(run.runTime);
      switch (run.state) {
        case 'ready':
        case 'pending':
          return {
            value: '0',
            display: (
              <DescriptionList>
                <DescriptionDetails
                  term="Wait Time"
                  value={<Timer start={dayjs.utc(run.dateCreated)} />} />
              </DescriptionList>
            )
          };
        case 'running':
          return {
            value: '0',
            display: (
              <DescriptionList>
                <DescriptionDetails
                  term="Run Time"
                  value={<Timer start={dayjs.utc(run.startTime)} />} />
              </DescriptionList>
            )
          };
        case 'stopped':
          return {
            value: '0',
            display: (
              <DescriptionList>
                <DescriptionDetails
                  term="Run Time"
                  value={formatDuration(runTime)} />
              </DescriptionList>
            )
          };
      }
    })(),
    actions: {
      value: '',
      display: (
        <Menu
          trigger={<MoreVerticalIcon size={20} />}
          openPosition="bottomLeft"
          onSelect={(value) => onMenuAction(value, run)}
          menuItems={[
            {
              value: 'logs',
              display: (
                <>
                  <FileTextIcon size={20} />
                  <span>Logs</span>
                </>
              ),
            },
          ]}
        />
      ),
    },
  };
};
