import { Home } from 'react-feather';
import { NavLink } from "react-router-dom"
import { GetIcon } from '../svgLogos/GetIcon';

export const DetectNavBar = () => {
    const activeStyle = {
        color: 'var(--primary)'
      };
    return(
        <div id="links">
          <div className="link-group">
          <NavLink
              to="/d_home"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Dashboard">
              <Home />
            </NavLink>
            <NavLink
              to="/duplicates"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Duplicates">
              <GetIcon icon='Duplicates' />
            </NavLink>
            <NavLink
              to="/scans"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Scans">
              <GetIcon icon='Scans' />
            </NavLink>
            <NavLink
              to="/analyses"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Analyses">
              <GetIcon icon='Analyses' />
            </NavLink>
            <NavLink
              to="/actions"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Actions">
              <GetIcon icon='Actions' />
            </NavLink>
            <NavLink
              to="/collections"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Collections">
              <GetIcon icon='Collections' />
            </NavLink>
            <NavLink
              to="/tags"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Tags">
              <GetIcon icon='Tags' />
            </NavLink>
            <NavLink
              to="/connectors"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Connectors">
              <GetIcon icon='Connectors' />
            </NavLink>
            <NavLink
              to="/rolodex"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Rolodex">
              <GetIcon icon='Rolodex' />
            </NavLink>
            <NavLink
              to="/detectadmin"
              style={({ isActive }) =>
                isActive ? activeStyle : {}
              }
              title="Admin">
              <GetIcon icon='Admin' />
            </NavLink>

          </div>
        </div>
    )
}