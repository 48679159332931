import { Copy as CopyIcon } from 'react-feather';
import { toast } from 'react-toastify';
import './CopyText.css';

type CopyTextProps = {
  text: string;
}

export const CopyText = (props: CopyTextProps) => {
  const handleClick = async () => {
    try {
      navigator.clipboard.writeText(props.text);
      toast.info('Copied.', { autoClose: 1000 });
    } catch {
      toast.error('Unable to copy to clipboard.');
    }
  };

  return (
    <span className="copy-text">
      <span>{props.text}</span>
      <button type="button" title="Copy to clipboard" onClick={handleClick}><CopyIcon size={16} /></button>
    </span>
  );
}
