import { TabOutlet } from "components"
import { Breadcrumb, BreadcrumbList } from "@premcloud/ui";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom"
import { usePipeline } from "./PipelineContext";

export const PipelineRunOutlet = () => {
  const logsMatch = useMatch(useResolvedPath('./logs').pathname);
  const { pipeline, pipelineRun } = usePipeline();

  return (
    <div className="page pipelines">

      <BreadcrumbList color="var(--breadcrumb-color)">
        <Breadcrumb>
          <NavLink to="/pipelines">Pipelines</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          <NavLink to={`/pipelines/${pipeline?.value.id}`}>{pipeline?.value.name}</NavLink>
        </Breadcrumb>
        <Breadcrumb>
        <NavLink to={`/pipelines/${pipeline?.value.id}/runs`}>Runs</NavLink>
        </Breadcrumb>
        <Breadcrumb>
          {logsMatch
            ? <NavLink to={`/pipelines/${pipeline?.value.id}/runs/${pipelineRun?.value.id}`}>{pipelineRun?.value.name}</NavLink>
            : <span>{pipelineRun?.value.name}</span>}
        </Breadcrumb>
        {logsMatch && <Breadcrumb><span>Logs</span></Breadcrumb>}
      </BreadcrumbList>

      <h1>{pipelineRun?.value.name}</h1>

      <TabOutlet>
        <NavLink to="" end>Details</NavLink>
        <NavLink to="./logs">Logs</NavLink>
      </TabOutlet>
    </div>
  )
};
