import { Option, Select, required } from '@premcloud/ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';
import { FieldContext } from '../FieldContext';
import { Field } from '../Field';
import { SetupField } from '../../Models';
import { detectChanges, validate } from '../../validate';

const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
}

export const Object = () => {
  const { form } = useContext(FieldContext);
  return form
    ? <ObjectForm />
    : <ObjectSelect />
}

const ObjectForm = () => {
  const { form, value: formValue, initialValue, onChange } = useContext(FieldContext);
  const [fields, setFields] = useState<SetupField[]>();

  const createField = (field: SetupField): SetupField => {
    let fieldValue = formValue?.[field.name] ?? null;
    let dirty = false;

    if (isNullOrUndefined(fieldValue) && !isNullOrUndefined(field.defaultValue)) {
      fieldValue = field.defaultValue;
      dirty = true;
    }

    return {
      ...field,
      initialValue: fieldValue,
      validators: [],
      errors: [],
      dirty,
      value: fieldValue,
      valid: true
    };
  };

  useEffect(() => {
    if (form) {
      setFields(form.value.map(createField));
    }
  }, [form]);

  useEffect(() => {
    if (fields && fields.some(x => x.dirty)) {
      const valid = fields.every(x => (!x.required || (x.required && x.validators.length > 0)) && x.valid);
      const value = valid
        ? fields.reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
          }, {})
        : null;
        onChange(value);
    }
  }, [fields]);

  const createFieldSetter = (index: number) => {
    return async (value: any) => {
      const target = fields[index];
      target.value = value;
      detectChanges(target);

      if (target.dirty) {
        const enableValidation = !isNullOrUndefined(value) || fields.some(x => !isNullOrUndefined(x.value));
        for (let j = 0; j < fields.length; j++) {
          const f = fields[j];
          f.validators = enableValidation && f.required
            ? [required()]
            : [];
          // User has to enter secret again.
          if (j !== index && f.secret && !isNullOrUndefined(f.initialValue)) {
            f.value = null;
          }
          await validate(f, (x: any) => fields[j] = x);
        }
      }
      setFields([...fields]);
    };
  };

  return (
    <div className="pui-form compound-form-field">
      {fields?.filter(field => field.visible ?? true).map((field, i) => (
        <Field
          { ...field }
          key={i}
          onChange={createFieldSetter(i)}
        />))}
    </div>
  );
}

const ObjectSelect = () => {
  const { name, label, value, options, errors, onChange } = useContext(FieldContext);
  const selections = useMemo(() => options?.map(option => ({
    value: option.value,
    display: option.label,
    hint: option.description,
    disabled: option.enabled === false
  } as Option)), [options]);
  const refValue = useMemo(() => value && options.find(opt => opt.value === value || isEqual(value, opt.value))?.value, [value]);

  return (
    <Select
      value={refValue || ''}
      options={selections}
      placeholder={label || name}
      onChange={onChange}
      onBlur={() => onChange(value)}
      hasError={errors.length > 0}
    />
  );
}
