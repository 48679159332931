import { ValFn } from '@premcloud/ui';

export function mustMatch<T>(value: T): ValFn {
  return (val: T) => {
    return {
      valid: value === val,
      name: 'mismatch'
    };
  }
}
