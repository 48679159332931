import './SelectTable.css';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Checkbox, Row, Column } from '@premcloud/ui';
import { Table, TableProps } from './Table';

type SelectTableColumnsProps = {
  columns: Column[];
  sortField?: string;
  sortOrder?: string; // 'asc' | 'desc'
  selectedStyle: CSSProperties;
  onColumnClick: (column: string) => void;
  selected: boolean;
  indeterminate: boolean;
  onSelectAll: (selected: boolean) => void;
}

const SelectTableColumns = (props: SelectTableColumnsProps) => {
  const { columns, sortField, sortOrder, selectedStyle, onColumnClick, selected, indeterminate, onSelectAll } = props;
  return (
    <tr>
      <th className="x-small">
        <Checkbox checked={selected} indeterminate={indeterminate} onChange={onSelectAll} />
      </th>
      {columns.map((col, i) => (
        <th key={i} className={col.size ? col.size : ''}>
          {!sortField || col.sort === false ? (
            <div className="pui-column-header">
              {col.display || col.id}
            </div>
          ) : (
            <div
              className="pui-column-header sort"
              style={
                col.id === sortField
                  ? selectedStyle
                  : {}
              }
              onClick={() => onColumnClick(col.id)}>
              <span>{col.display || col.id}</span>

              {col.id === sortField ? (
                sortOrder === 'asc' ? (
                  <svg
                    width="1rem"
                    height="1rem"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-up">
                    <polyline points="18 15 12 9 6 15" />
                  </svg>
                ) : (
                  <svg
                    width="1rem"
                    height="1rem"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down">
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                )
              ) : null}
            </div>
          )}
        </th>
      ))}
    </tr>
  );
}

const SelectTableRow = ({ row, columns, selection, onSelect }) => {
  const [checked, setChecked] = useState(false);
  const getClass = (id: string): string => {
    const column = columns.find(c => c.id === id);
    return column?.size || '';
  };

  const handledChecked = (checked: boolean) => {
    setChecked(checked);
    onSelect(row, checked);
  }

  useEffect(() => {
    setChecked(selection.includes(row));
  }, [selection]);

  return (
    <tr>
      <td className="x-small">
        <Checkbox checked={checked} onChange={handledChecked} />
      </td>
      {columns.map((column, i) => (
        <td key={i} className={getClass(column.id)}>
          {typeof row[column.id] === 'object'
            ? row[column.id]['display'] || row[column.id]['value']
            : row[column.id]}
        </td>
      ))}
    </tr>
  );
}

type SelectTableProps = {
  selection?: Row[];
  onSelectionChange: (selection: Row[]) => void;
} & TableProps

export const SelectTable = (props: SelectTableProps) => {
  const { rows, selection = [], onSelectionChange } = props;
  const allSelected = useMemo(() => rows.length > 0 && selection.length === rows.length, [rows, selection]);
  const indeterminateSelected = useMemo(() => selection.length > 0 && selection.length !== rows.length, [rows, selection]);

  const handleRowSelect = (row: any, selected: boolean) => {
    onSelectionChange(selected ? [...selection, row] : selection.filter(x => x !== row));
  };

  const handleSelectAll = (checked: boolean) => {
    checked ? onSelectionChange(rows) : onSelectionChange([]);
  }

  return (
    <Table
      defaultSortColumn={props.defaultSortColumn}
      defaultSortOrder={props.defaultSortOrder}
      columns={props.columns}
      rows={props.rows}
      columnsTemplate={props =>
        <SelectTableColumns {...props}
          selected={allSelected}
          indeterminate={indeterminateSelected}
          onSelectAll={handleSelectAll} />}
      rowTemplate={props =>
        <SelectTableRow {...props}
          selection={selection}
          onSelect={handleRowSelect} />}
    />
  )
}
