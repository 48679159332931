import { ReactElement } from "react";
import { DescriptionDetailsProps } from "./DescriptionDetails";
import './DescriptionList.css';

export type DescriptionListProps = {
  title?: string;
  children: ReactElement<DescriptionDetailsProps> | ReactElement<DescriptionDetailsProps>[];
};

export const DescriptionList = (props: DescriptionListProps) => {
  const { title, children } = props;
  return (
    <div className="pui-description-list">
      {title && <label>{title}</label>}
      <dl>{children}</dl>
    </div>
  );
};
