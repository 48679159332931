import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Service } from './Service';
import { Credential, UserInfo } from './Models';
import { LOGIN_ROUTE_PATH } from './Consts';
import { addInterceptors, removeInterceptors } from './Interceptors';

export type AuthContextValue = {
  login: (cred: Credential) => Promise<void>;
  logout: () => void;
  user: UserInfo;
};

export const AuthContext = createContext<AuthContextValue>(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(Service.getUserInfo());
  const navigate = useNavigate();

  const login = async (cred: Credential) => {
    await Service.login(cred);
    setUser(Service.getUserInfo());
    navigate('/');
  };

  const logout = () => {
    setUser(null);
    Service.logout();
    navigate(LOGIN_ROUTE_PATH);
  };

  const interceptors = addInterceptors(navigate);

  useEffect(() => {
    return () => removeInterceptors(interceptors);
  }, []);

  return (
    <AuthContext.Provider value={{ login, logout, user }}>
      {children}
    </AuthContext.Provider>
  )
};
