import { FormField } from '@premcloud/ui';
import { Array, Boolean, Database, DateTimeRange, Integer, Object, Schedule, String, Time, Tree } from './types';
import { SetupField } from '../Models';
import { FieldContext } from './FieldContext';
import './Field.css';

type FieldProps = SetupField & {
  onChange: (value: any) => void;
};

export const Field = (props: FieldProps) => {
  const { type, name, label, placeholder, errors, dirty } = props;

  return (
    <FieldContext.Provider value={props}>
      {(() => {
        if (type === 'boolean') {
          return (
            <div className="pui-form-field boolean">
              <div>
                {label || name}
                <Boolean />
              </div>
              <div className="hint-container">{placeholder}</div>
            </div>
          );
        } else {
          return (
            <FormField
              label={label || name}
              hint={placeholder}
              errors={errors}
              dirty={dirty}
              input={(() => {
                switch (type) {
                  case 'array': return <Array />;
                  case 'database': return <Database />;
                  case 'dateTimeRange': return <DateTimeRange />;
                  case 'integer': return <Integer />
                  case 'object': return <Object />
                  case 'schedule': return <Schedule />;
                  case 'string': return <String />;
                  case 'time': return <Time />
                  case 'tree': return <Tree />;
                }
              })()
              }
            />
          );
        }
      })()}
    </FieldContext.Provider>
  );
};
